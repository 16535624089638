import { useEffect, useState } from 'react';
import { useConnectionStatusContext } from '../../libs/connection-status-provider';
import { usePolling } from '../../libs/hooks/use-polling';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { useMultiCall } from '../../libs/pool-data-provider/hooks/use-multicall';
import LockdropABI from '../../contracts/LiquidityLockdrop.json';
import { useStaticPoolDataContext } from '../../libs/pool-data-provider';
import { formatEther } from 'ethers/lib/utils';
import { getKlapKlayPrice } from '../../helpers/get-klap-price';
import ProxyFarmerABI from '../../contracts/ProxyFarmer.json';
const POLLING_INTERVAL = 120000;

interface InfoData {
  deposit: {
    ogKlayAmount: string;
    lpAmount: string;
    harvestAmount: string;
    expiryAmount: number;
  };
  klapRewards: string;
  klayPrice: number;
  cap: string;
  expirationDate: number;
  totalDeposits: string;
}

interface ReturnData {
  loading: boolean;
  error: any;
  data: InfoData;
  refresh: () => void;
}

export const useGetLockdropInfo: () => ReturnData = () => {
  let { userId } = useStaticPoolDataContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<InfoData>({
    deposit: {
      ogKlayAmount: '0',
      lpAmount: '0',
      harvestAmount: '0',
      expiryAmount: 0,
    },
    klapRewards: '0',
    expirationDate: 0,
    cap: '0',
    totalDeposits: '0',
    klayPrice: 0,
  });
  const { currentMarketData } = useProtocolDataContext();
  const { isRPCActive } = useConnectionStatusContext();
  const multicall = useMultiCall([LockdropABI, ProxyFarmerABI]);

  const fetchData = async () => {
    if (!userId) return;
    try {
      setLoading(true);
      const [[[userBalances, totalDeposits, claimAmounts, cap], [harvestableAmount]], { klay }] =
        await Promise.all([
          multicall(
            [
              currentMarketData.addresses.LIQUIDITY_LOCKDROP!,
              currentMarketData.addresses.PROXY_FARMER!,
            ],
            [['userBalances', 'totalDeposits', 'claimAmounts', 'cap'], ['getClaimable']],
            [[[userId], [], [userId], []], [[userId]]]
          ),
          getKlapKlayPrice(currentMarketData.addresses.LP_TOKEN!),
        ]);

      const data: InfoData = {
        deposit: {
          ogKlayAmount: formatEther(userBalances[0]),
          lpAmount: formatEther(claimAmounts[0]),
          harvestAmount: formatEther(harvestableAmount[0]),
          expiryAmount: 1666270800,
        },
        klapRewards: '5M',
        expirationDate: 1658365200,
        cap: formatEther(cap[0]),
        totalDeposits: formatEther(totalDeposits[0]),
        klayPrice: klay,
      };
      setData(data);
      setError(false);
    } catch (e) {
      console.error('e', e);
      setError(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (userId && data.expirationDate === 0) {
      fetchData();
    }
  }, [userId]);

  usePolling(fetchData, POLLING_INTERVAL, !isRPCActive, []);

  return {
    loading,
    error,
    data: data,
    refresh: fetchData,
  };
};

import { MessageDescriptor } from 'react-intl';
import { moreMenuExtraItems, moreMenuItems } from '../../../ui-config';
import { MarketDataType } from '../../../helpers/config/types';

import messages from './messages';

export interface Navigation {
  link: string;
  title: MessageDescriptor;
  hiddenWithoutWallet?: boolean;
  absolute?: boolean;
  onClick?: () => void;
  isVisible?: (data: MarketDataType) => boolean | undefined;
}

const navigation: Navigation[] = [
  {
    link: '/markets',
    title: messages.markets,
  },
  {
    link: '/dashboard',
    title: messages.dashboard,
  },
  {
    link: '/deposit',
    title: messages.deposit,
  },
  {
    link: '/borrow',
    title: messages.borrow,
  },
  {
    link: '/manage',
    title: messages.manage,
  },
  {
    link: '/ve',
    title: messages.ve,
  },
  {
    link: '/vote',
    title: messages.vote,
  },
  {
    link: '/staking',
    title: messages.stake,
  },
  {
    link: '/lockdrop',
    title: messages.lockdrop,
  },
  {
    link: '/liquidity-drop',
    title: messages.liquidityDrop,
  },
  {
    link: 'https://synapseprotocol.com/?inputCurrency=USDC++&outputCurrency=USDC++&outputChain=8217',
    title: messages.bridge,
    absolute: true,
  },
];

export const moreNavigation: Navigation[] = [...moreMenuItems, ...moreMenuExtraItems];

export const mobileNavigation: Navigation[] = [...navigation];

export default navigation;

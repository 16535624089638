import React, { useState } from 'react';
import { BasicModal } from '@aave/aave-ui-kit';
import { CardInnerContainer, StyledAmountField } from '../../manage/components/styles';
import { useGetManageInfo } from '../../manage/hooks';
import { useServices } from '../functions';
import Preloader from '../../../components/basic/Preloader';
import DefaultButton from '../../../components/basic/DefaultButton';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import MonthButtons from '../../manage/components/MonthButtons';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import messages from '../messages';

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

const CardInnerContainerStyled = styled(CardInnerContainer)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
`;

const OverviewContainer = styled.div`
  margin: 25px 0px;
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  align-items: flex-start;
  width: 100%;
`;

const OverviewText = styled.p`
  color: #76808f;
  font-size: 13px;
  display: flex;
  grid-gap: 5px;

  p {
    color: #000;
  }
`;

interface VeModalProps {
  isModalOpen: boolean;
  toggle: () => void;
  tokenInfo: {
    tokenId: string;
    amount: string;
    expiry: string;
  };
}

const VeModal = (props: VeModalProps) => {
  const [amount, setAmount] = useState('');
  const [time, setTime] = useState(3);
  const [isAmountLoading, setIsAmountLoading] = useState(false);
  const [isTimeLoading, setIsTimeLoading] = useState(false);
  const { increaseAmount, increaseTime, approve } = useServices();
  const { data, refresh } = useGetManageInfo();
  const { currentMarketData } = useProtocolDataContext();
  const intl = useIntl();
  return (
    <BasicModal className="VeModal" isVisible={props.isModalOpen} onBackdropPress={props.toggle}>
      <h1>
        <b>{intl.formatMessage(messages.manageVeKlap)}</b>
      </h1>
      <OverviewContainer>
        <OverviewText>{intl.formatMessage(messages.selectedLockInfo)}</OverviewText>
        <OverviewText>
          Amount: <p>{props.tokenInfo.amount}</p> KLAP
        </OverviewText>
        <OverviewText>
          Expiry:<p>{props.tokenInfo.expiry}</p>{' '}
        </OverviewText>
      </OverviewContainer>
      <OuterContainer>
        <CardInnerContainerStyled>
          <p style={{ marginBottom: 8 }}>{intl.formatMessage(messages.increaseLockedAmount)}</p>
          <StyledAmountField
            title={'Available to Stake:'}
            maxAmount={data.klapBalance}
            symbol={'KLAP'}
            maxDecimals={4}
            value={'' + amount}
            onChange={(amount) => setAmount(amount)}
            onMaxButtonClick={() => setAmount('' + data.klapBalance)}
            style={{ margin: 0, marginBottom: 25 }}
          />
          {isAmountLoading ? (
            <div style={{ maxWidth: 80 }}>
              <Preloader smallSize />
            </div>
          ) : (
            <DefaultButton
              disabled={
                parseFloat(amount) > data.klapBalance ||
                amount.length === 0 ||
                parseFloat(amount) === 0
              }
              onClick={async () => {
                setIsAmountLoading(true);
                if (data.klapVeApproved) {
                  await increaseAmount(props.tokenInfo.tokenId, Number(amount));
                } else {
                  await approve(currentMarketData.addresses.VE!);
                }
                await refresh();
                setIsAmountLoading(false);
              }}
              title={
                !data.klapVeApproved ? 'Approve' : intl.formatMessage(messages.increaseAmountButton)
              }
              color="green"
            />
          )}
        </CardInnerContainerStyled>
        <CardInnerContainerStyled>
          <p style={{ marginBottom: 8 }}>{intl.formatMessage(messages.increaseLockPeriodTo)}</p>
          <div style={{ maxWidth: '50%', marginBottom: 25 }}>
            <MonthButtons selectedButton={time} setSelectedButton={setTime} />
          </div>
          {isTimeLoading ? (
            <div style={{ maxWidth: 80 }}>
              <Preloader smallSize />
            </div>
          ) : (
            <DefaultButton
              onClick={async () => {
                setIsTimeLoading(true);
                await increaseTime(props.tokenInfo.tokenId, Number(time));
                await refresh();
                setIsTimeLoading(false);
              }}
              title={intl.formatMessage(messages.increaseLockPeriodButton)}
              color="green"
            />
          )}
        </CardInnerContainerStyled>
      </OuterContainer>
      <style jsx={true} global={true}>{`
        .VeModal {
          min-width: 560px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      `}</style>
    </BasicModal>
  );
};

export default VeModal;

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .FaucetAssetTable {
    margin-top: 50px !important;
    .TableColumn {
      &:last-of-type {
        align-items: flex-end;
      }
    }
  }

  .ColumnMiniFaucet {
    width: auto;
    align-items: flex-end !important;
  }

  .MintButton {
    max-height: 35px;
    .ConnectButton__inner {
      min-height: 32px !important;
      max-height: 32px;
    }
  }
`;

export default staticStyles;

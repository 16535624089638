import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';

import { useThemeContext } from '@aave/aave-ui-kit';
import ScreenWrapper from '../ScreenWrapper';
import ContentWrapper from '../ContentWrapper';
import TopInfoPanel from './TopInfoPanel';
import CurrencyOverview from './CurrencyOverview';

import staticStyles from './style';

import { ValidationWrapperComponentProps } from '../../RouteParamsValidationWrapper';
import { GraphLegendDot } from '../../graphs/GraphLegend';
import { InterestRateSeries } from '../../graphs/types';
import defaultMessages from '../../../defaultMessages';

import messages from './messages';
import Button from '../../Button';
import { useHistory } from 'react-router-dom';

interface CurrencyScreenWrapperProps
  extends Pick<
    ValidationWrapperComponentProps,
    'userReserve' | 'poolReserve' | 'user' | 'currencySymbol'
  > {
  title: string;
  isCollapseLocalStorageName: string;
  walletBalance?: string;
  type: 'deposit' | 'borrow';
  showGraphCondition: boolean;
  dots?: GraphLegendDot[];
  series: InterestRateSeries[];
  goBack?: () => void;
  children: ReactNode;
}

export default function CurrencyScreenWrapper({
  title,
  currencySymbol,
  poolReserve,
  user,
  userReserve,
  walletBalance,
  isCollapseLocalStorageName,
  type,
  showGraphCondition,
  dots,
  series,
  goBack,
  children,
}: CurrencyScreenWrapperProps) {
  const intl = useIntl();
  const { sm } = useThemeContext();
  const history = useHistory();

  const [isCollapse] = useState(localStorage.getItem(isCollapseLocalStorageName) === 'true');
  return (
    <ScreenWrapper
      pageTitle={intl.formatMessage(type === 'deposit' ? messages.deposit : messages.borrow)}
      className="CurrencyScreenWrapper"
    >
      {/*!sm && (
        <div className="CurrencyScreenWrapper__top-info">
          <TopInfoPanel
            poolReserve={poolReserve}
            currencySymbol={currencySymbol}
            walletBalance={walletBalance}
            userReserve={userReserve}
            user={user}
            type={type}
          />
        </div>
      )*/}
      {!sm && (
        <div>
          <Button
            className="Backbutton"
            onClick={history.goBack}
            name={`< ${intl.formatMessage(defaultMessages.back)}`}
            size="small"
          />
        </div>
      )}

      <CurrencyOverview
        title={title}
        poolReserve={poolReserve}
        currencySymbol={currencySymbol}
        type={type}
        showGraphCondition={showGraphCondition}
        dots={dots}
        series={series}
        isCollapse={isCollapse}
      />

      <div className="CurrencyScreenWrapper__mobileInner">
        <TopInfoPanel
          poolReserve={poolReserve}
          currencySymbol={currencySymbol}
          walletBalance={walletBalance}
          userReserve={userReserve}
          user={user}
          type={type}
        />
      </div>

      <ContentWrapper
        className="CurrencyScreenWrapper__content"
        withBackButton={!sm}
        goBack={goBack}
      >
        {children}
      </ContentWrapper>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .CurrencyScreenWrapper {
        }
      `}</style>
    </ScreenWrapper>
  );
}

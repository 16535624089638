import React, { useState } from 'react';
import styled from 'styled-components';
import DefaultButton from '../../../components/basic/DefaultButton';
import Preloader from '../../../components/basic/Preloader';
import { DescriptionTitle, ExitContainer, StyledAmountField, TitleCard } from './styles';
import { useIntl } from 'react-intl';
import messages from '../messages';

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const EarlyExitPanel = ({
  amount,
  amountUsd,
  onClaim,
  isLoading,
}: {
  amount: string;
  amountUsd: string;
  onClaim: (amount: string) => void;
  isLoading: boolean;
}) => {
  const [inputAmount, setAmount] = useState('');
  const intl = useIntl();
  return (
    <ExitContainer>
      <InnerContainer style={{ paddingRight: '15%' }}>
        <TitleCard>{intl.formatMessage(messages.earlyExitTitle)}</TitleCard>
        <p style={{ fontWeight: 500, color: '#FF3366', marginTop: 16 }}>
          {intl.formatMessage(messages.warning)}
        </p>
        <DescriptionTitle style={{ fontWeight: 'normal', width: 'fit-content' }}>
          {intl.formatMessage(messages.warningMessage, {
            klapAmount: (Number(amount) / 2).toLocaleString('en-us', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 5,
            }),
            usdAmount: (Number(amountUsd) / 2).toLocaleString('en-us', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 5,
            }),
          })}
        </DescriptionTitle>
      </InnerContainer>
      <InnerContainer style={{ flexDirection: 'row', gridGap: 16 }}>
        <StyledAmountField
          title={intl.formatMessage(messages.available)}
          maxAmount={amount}
          symbol={'KLAP'}
          maxDecimals={4}
          value={'' + inputAmount}
          onChange={(amount) => setAmount(amount)}
          onMaxButtonClick={() => setAmount(amount)}
          style={{ margin: 0 }}
        />
        {isLoading ? (
          <div style={{ maxWidth: 140 }}>
            <Preloader smallSize />
          </div>
        ) : (
          <DefaultButton
            disabled={inputAmount === '' || Number(inputAmount) > Number(amount)}
            className="ClaimButton"
            color="red"
            title={intl.formatMessage(messages.claimAnyway)}
            onClick={() => onClaim(inputAmount)}
          />
        )}
      </InnerContainer>
      <style jsx={true} global={true}>{`
        .ClaimButton {
          height: 50px;
        }
        .ClaimButton .ConnectButton__inner {
          height: 46px;
          min-width: 140px !important;
        }
      `}</style>
    </ExitContainer>
  );
};

export default EarlyExitPanel;

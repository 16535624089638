import { defineMessages } from 'react-intl';

export default defineMessages({
  klapEarned: 'KLAP Earned',
  klapRewards: 'KLAP rewards, vested over 6 weeks',
  seeVesting: 'See Vesting',
  avoidMessage:
    'To avoid paying a 50% early claim penalty on your vesting KLAP, please choose one of the two options below:',
  getVeKlap: 'Get veKLAP',
  earlyExit: 'more than early exit with penalty',
  benefits: 'Benefits',
  benefitsVeKlap1: 'Bond KLAP and waive the 50% penalty while earning more KLAP on top.',
  benefitsVeKlap2: 'Get farming boosters for Lend/Borrow as well as LP rewards.',
  benefitsVeKlap3: 'VeKLAP holders also get voting rights on emissions for different pools.',
  benefitsVeKlap4: 'Longer locks give you more Ve.',
  lockQuestion: 'How much do you want to lock?',
  lengthQuestion: 'And for how long? (months)',
  lock: 'Lock',
  provideLiquidity: 'Provide Liquidity for veLP',
  pairKlap: 'Pair KLAP with equivalent $ value in KLAY.',
  benefitsProvideLiquidity1: 'Waive early exit penalty.',
  benefitsProvideLiquidity2: 'Get 2x more Ve per dollar of liquidity provided.',
  benefitsProvideLiquidity3: 'Boosters on deposit/borrow and LP rewards.',
  benefitsProvideLiquidity4: 'Voting rights.',
  lengthQuestionMain: 'How long do you want to lock? (months)',
  earlyExitTitle: 'Early Exit with Penalty',
  warning: 'WARNING',
  warningMessage:
    'By claiming your vested KLAP now, you will lose 50% of the unvested amount ({ klapAmount } KLAP / { usdAmount } USD).',
  claimKlap: 'Claim KLAP',
  claimAnyway: 'Claim Anyway!',
  veInfo: 'Ve Info',
  amountLocked: 'Amount Locked',
  expiry: 'Expiry',
  earned: 'Earned',
  canOnlyBeClaimedAtExpiry: 'Can only be claimed at expiry',
  klapVestingSchedule: 'KLAP Vesting Schedule',
  vesting: 'Vesting',
  vestedKlap: 'Vested KLAP',
  klapThatHasFullyVested: 'KLAP that has fully vested',
  claimableFees: 'Claimable Fees',
  availableToLock: 'Available to Lock',
  currency: 'Currency',
  amount: 'Amount',
  available: 'Available',
  provideLiquidityButton: 'Provide Liquidity',
  totalKlapVesting: 'Total KLAP Vesting',
  totalUsdValue: 'Total USD Value',
  totalKlapKlayVesting: 'Total KLAP-KLAY Vesting',
  approve: 'Approve',
  claimReward: 'Claim',
  veStaking: 'Ve staking rewards accrue once a week, and you can claim weekly',
  lpNotes:
    'Note: All veLP rewards are moved into one aggregate sum. When you claim rewards for one LP token, you claim for all of them at once',
});

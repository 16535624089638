import { useState } from 'react';

import {
  UiPoolDataProvider,
  ReservesDataHumanized,
  UserReserveDataHumanized,
  ChainId,
  ReserveDataHumanized,
} from '@aave/contract-helpers';
import { usePolling } from '../../hooks/use-polling';
import { getProvider } from '../../../helpers/config/markets-and-network-config';
import { BigNumber, Contract } from 'ethers';
import { useProtocolDataContext } from '../../protocol-data-provider';
import ChefIncentivesController from '../../../contracts/ChefIncentivesController.json';
import MultiRewarder from '../../../contracts/MultiRewarder.json';
import VeABI from '../../../contracts/Ve.json';
import _ from 'lodash';
import { formatEther, formatUnits, parseEther } from 'ethers/lib/utils';
import { useMultiCall } from './use-multicall';
import { getKlapKlayPrice } from '../../../helpers/get-klap-price';
import { ONE_MONTH } from '../../../modules/manage/functions';

// interval in which the rpc data is refreshed
const POLLING_INTERVAL = 120 * 1000;

export interface PoolDataResponse {
  loading: boolean;
  error: boolean;
  data: {
    reserves?: ModifiedReserves;
    userReserves?: UserReserveDataHumanized[];
  };
  refresh: () => Promise<any>;
}

export interface ModifiedReserve extends ReserveDataHumanized {
  canVest: boolean;
  depositFarmAPY: number;
  depositVeFarmAPY: number;
  borrowFarmAPY: number;
  borrowVeFarmAPY: number;
  borrowKlayAPY: number;
  depositKlayAPY: number;
  vestedAmount: string;
}

export interface ModifiedReserves extends ReservesDataHumanized {
  reservesData: ModifiedReserve[];
}

// Fetch reserve and user incentive data from UiIncentiveDataProvider
export function usePoolData(
  lendingPoolAddressProvider: string,
  chainId: ChainId,
  poolDataProviderAddress: string,
  skip: boolean,
  userAddress?: string
): PoolDataResponse {
  const { currentMarketData } = useProtocolDataContext();
  const currentAccount: string | undefined = userAddress ? userAddress.toLowerCase() : undefined;
  const [loadingReserves, setLoadingReserves] = useState<boolean>(false);
  const [errorReserves, setErrorReserves] = useState<boolean>(false);
  const [loadingUserReserves, setLoadingUserReserves] = useState<boolean>(false);
  const [errorUserReserves, setErrorUserReserves] = useState<boolean>(false);
  const [reserves, setReserves] = useState<ModifiedReserves | undefined>(undefined);
  const [userReserves, setUserReserves] = useState<UserReserveDataHumanized[] | undefined>(
    undefined
  );
  const multicall = useMultiCall([ChefIncentivesController, MultiRewarder]);
  const multicall_ve = useMultiCall([VeABI, ChefIncentivesController]);

  const getEmissionData = async (reservesResponse: ModifiedReserves) => {
    const sortedData = reservesResponse.reservesData;
    const totalLength = sortedData.length * 2;
    const methodsAlloc = _.map(_.range(0, totalLength), () => 'allocPoints');
    const methodsPoolInfo = _.map(_.range(0, totalLength), () => 'poolInfo');
    const methodsTokenPerBlock = _.map(_.range(0, totalLength), () => 'retrieveTokenPerBlock');
    let pools: any = _.map(sortedData, (data) => [
      data.aTokenAddress,
      data.variableDebtTokenAddress,
    ]);
    pools = _.flatten(pools).map((item) => [item]);
    const poolsPerBlock = _.map(pools, (pool) => [pool[0], 0]);
    const [[chefResponse, multirewarderResponse], { klap, klay }] = await Promise.all([
      multicall(
        [
          currentMarketData.addresses.CHEFS_INCENTIVE_CONTROLLER!,
          currentMarketData.addresses.MULTIREWARDER!,
        ],
        [
          [
            'totalAllocPoints',
            'rewardsPerSecond',
            'dialutingRepartition',
            ...methodsPoolInfo,
            ...methodsAlloc,
          ],
          [...methodsTokenPerBlock],
        ],
        [[[], [], [], ...pools, ...pools], [...poolsPerBlock]]
      ),
      getKlapKlayPrice(currentMarketData.addresses.LP_TOKEN!),
    ]);
    const [_totalAllocPoints, rewardsPerSecond, dialutingRepartition] = chefResponse;
    const poolInfo = chefResponse.slice(3, methodsPoolInfo.length + 3);
    const allocInfo = chefResponse.slice(
      methodsPoolInfo.length + 3,
      methodsPoolInfo.length + methodsAlloc.length + 3
    );

    let userPoolInfo: any[][] = [];
    let userInfo: any[][] = [];
    let claimableReward: any[] = _.map(pools, () => BigNumber.from(0));

    if (userAddress !== null && userAddress !== undefined && userAddress !== '') {
      const userInputs = _.map(pools, () => [userAddress]);
      const userInputsPool = _.map(pools, (pool) => [pool[0], userAddress]);
      const methodsUserInfoChef = _.map(_.range(0, totalLength), () => 'userInfo');
      const methodsUserInfo = _.map(_.range(0, totalLength), () => 'getUserFactor');
      console.log('user inputs', userInputsPool);

      let res;
      [userInfo, res] = await multicall_ve(
        [currentMarketData.addresses.VE!, currentMarketData.addresses.CHEFS_INCENTIVE_CONTROLLER!],
        [methodsUserInfo, ['claimableReward', ...methodsUserInfoChef]],
        [userInputs, [[userAddress, _.map(pools, (pool) => pool[0])], ...userInputsPool]]
      );
      [claimableReward] = res.splice(0, 1);
      userPoolInfo = res;
      console.log('user info 2', userInfo);
      console.log('user info 3', userPoolInfo);
      console.log('claimable rewards', claimableReward);
      console.log('pool info', BigNumber.from(poolInfo[0][4]).toString());
    }

    const klapPrice = klap;
    const klayPrice = klay;

    const nonDialutingRepartition = BigNumber.from(1000).sub(
      BigNumber.from(dialutingRepartition[0])
    );
    console.log('rewards per second', BigNumber.from(rewardsPerSecond[0]).toString());
    const overallNonDialuting = BigNumber.from(rewardsPerSecond[0])
      .mul(BigNumber.from(86400 * 365))
      .mul(nonDialutingRepartition)
      .div(1000);
    const overallDialuting = BigNumber.from(rewardsPerSecond[0])
      .mul(BigNumber.from(86400 * 365))
      .mul(BigNumber.from(dialutingRepartition[0]))
      .div(BigNumber.from(1000));

    const totalAllocPoints = BigNumber.from(_totalAllocPoints[0]);

    let emissionAndAPRData = [];

    const calculateVe = ({
      fullyDialutedAmount,
      allocPoints,
      userFactor,
      sumOfFactors,
      userSupplyInPool,
      priceOfAsset,
      coin,
      isBorrow,
      assetDecimal,
    }: {
      fullyDialutedAmount: BigNumber;
      userFactor: BigNumber;
      sumOfFactors: BigNumber;
      userSupplyInPool: BigNumber;
      priceOfAsset: number;
      allocPoints: BigNumber;
      coin: string;
      isBorrow: boolean;
      assetDecimal: number;
    }) => {
      if (sumOfFactors.eq(BigNumber.from(0))) return 0;

      console.log(
        `${coin} ${isBorrow ? 'borrow' : 'lend'}: yearly emission for pool`,
        fullyDialutedAmount.mul(allocPoints).div(totalAllocPoints).toString()
      );
      console.log('user factor', userFactor.toString());
      console.log('sum of factor', sumOfFactors.toString());
      const yearlyEmission = fullyDialutedAmount.mul(allocPoints).div(totalAllocPoints);
      console.log('yearly emission formatted', formatEther(yearlyEmission));
      const userCut = yearlyEmission.mul(userFactor).div(sumOfFactors);
      console.log('users cut of yearly', userCut);
      console.log('users cut of yearly formatted', formatEther(userCut));
      console.log('asset decimal', assetDecimal);
      //console.log('sci notation asset decimal', 10 ^ (assetDecimal + 2));
      console.log('init user supply', userSupplyInPool.toString());
      const userSupplyDecimals =
        assetDecimal === 18
          ? Number(formatEther(userSupplyInPool))
          : Number(formatUnits(userSupplyInPool, assetDecimal));
      console.log('user supply', userSupplyDecimals);
      const userRevenue = Number(formatEther(userCut)) * klapPrice;
      console.log('user revenue per year', userRevenue);

      const apr = userRevenue / (userSupplyDecimals * priceOfAsset);
      console.log('apr', apr);
      return apr;
    };

    const calculateKlay = ({
      yearlyEmission,
      priceOfAsset,
      assetDecimal,
      totalSupply,
    }: {
      yearlyEmission: BigNumber;
      priceOfAsset: number;
      assetDecimal: number;
      totalSupply: BigNumber;
    }) => {
      if (yearlyEmission.eq(BigNumber.from(0))) return 0;
      if (totalSupply.eq(BigNumber.from(0))) return 'Infinity';

      const userSupplyDecimals = 1;
      console.log('user supply', userSupplyDecimals);
      console.log('yearly emission formatted', formatEther(yearlyEmission));
      console.log('total supply', formatEther(totalSupply));

      const userCut = yearlyEmission.mul(parseEther('' + userSupplyDecimals)).div(totalSupply);
      console.log('users cut of yearly', userCut);
      console.log('users cut of yearly formatted', formatEther(userCut));
      console.log('asset decimal', assetDecimal);
      //console.log('sci notation asset decimal', 10 ^ (assetDecimal + 2));
      //console.log('init user supply', userSupplyInPool.toString());
      const userRevenue = Number(formatEther(userCut)) * klayPrice;
      console.log('user revenue per year', userRevenue);

      const apr = userRevenue / (userSupplyDecimals * priceOfAsset);
      console.log('apr for klay', apr);
      return apr;
    };

    const calculateDepBorrowFunc = (
      overallAmount: BigNumber,
      allocPoints: BigNumber,
      totalSupplyDepBorrow: BigNumber,
      priceOfAsset: number,
      returnBigNumber?: boolean
    ) => {
      const result = overallAmount
        .mul(allocPoints)
        .div(totalAllocPoints)
        .mul(BigNumber.from((klapPrice * 100000).toFixed(0)))
        .div(totalSupplyDepBorrow.mul((priceOfAsset * 100000).toFixed(0)));
      return returnBigNumber ? result : formatEther(result);
    };
    for (let i = 0; i < totalLength; i += 2) {
      let totalSupplyDeposit = BigNumber.from(poolInfo[i][0]).div(
        BigNumber.from(10).pow(BigNumber.from(sortedData[i / 2].decimals))
      );
      totalSupplyDeposit = totalSupplyDeposit.eq(BigNumber.from(0))
        ? BigNumber.from(1)
        : totalSupplyDeposit;
      let totalSupplyBorrow = BigNumber.from(poolInfo[i + 1][0]).div(
        BigNumber.from(10).pow(BigNumber.from(sortedData[i / 2].decimals))
      );
      totalSupplyBorrow = totalSupplyBorrow.eq(BigNumber.from(0))
        ? BigNumber.from('1')
        : totalSupplyBorrow;
      const allocDeposit = BigNumber.from(allocInfo[i][0]);
      const allocBorrow = BigNumber.from(allocInfo[i + 1][0]);
      const price = Number(formatEther(sortedData[i / 2].priceInMarketReferenceCurrency));
      const dialutedDeposit: any = calculateDepBorrowFunc(
        overallDialuting,
        allocDeposit,
        totalSupplyDeposit,
        price,
        true
      );

      const yearlyDepositKlayRewards = BigNumber.from(multirewarderResponse[i][0]).mul(
        BigNumber.from(86400 * 365)
      );
      const yearlyBorrowKlayRewards = BigNumber.from(multirewarderResponse[i + 1][0]).mul(
        BigNumber.from(86400 * 365)
      );
      console.log('claimable reward deposit', claimableReward[i]);
      console.log('claimable reward borrow', claimableReward[i + 1]);
      const vestedAmount = formatEther(
        BigNumber.from(claimableReward[i]).add(claimableReward[i + 1])
      );
      console.log('dialuted deposit', BigNumber.from(dialutedDeposit).toString());
      console.log('poolinfo', poolInfo);
      console.log('poolinfo', userPoolInfo);
      console.log('vestable amount', vestedAmount);
      emissionAndAPRData.push({
        depositFarmAPY: calculateDepBorrowFunc(
          overallNonDialuting,
          allocDeposit,
          totalSupplyDeposit,
          price
        ),
        depositVeFarmAPY:
          userPoolInfo.length === 0
            ? '0'
            : BigNumber.from(userPoolInfo[i][0]).eq(0)
            ? '0'
            : calculateVe({
                fullyDialutedAmount: overallDialuting,
                userFactor: BigNumber.from(userPoolInfo[i][2]),
                sumOfFactors: BigNumber.from(poolInfo[i][4]),
                userSupplyInPool: BigNumber.from(userPoolInfo[i][0]),
                priceOfAsset: price,
                allocPoints: BigNumber.from(allocInfo[i][0]),
                coin: sortedData[i / 2].symbol,
                isBorrow: false,
                assetDecimal: sortedData[i / 2].decimals,
              }),
        borrowFarmAPY: calculateDepBorrowFunc(
          overallNonDialuting,
          allocBorrow,
          totalSupplyBorrow,
          price
        ),
        borrowVeFarmAPY:
          userPoolInfo.length === 0 || BigNumber.from(userPoolInfo[i + 1][0]).eq(0)
            ? '0'
            : calculateVe({
                fullyDialutedAmount: overallDialuting,
                userFactor: BigNumber.from(userPoolInfo[i + 1][2]),
                sumOfFactors: BigNumber.from(poolInfo[i + 1][4]),
                userSupplyInPool: BigNumber.from(userPoolInfo[i + 1][0]),
                priceOfAsset: price,
                allocPoints: BigNumber.from(allocInfo[i + 1][0]),
                coin: sortedData[i / 2].symbol,
                isBorrow: true,
                assetDecimal: sortedData[i / 2].decimals,
              }),
        depositKlayAPY: calculateKlay({
          yearlyEmission: yearlyDepositKlayRewards,
          priceOfAsset: price,
          assetDecimal: sortedData[i / 2].decimals,
          totalSupply: BigNumber.from(poolInfo[i][0]),
        }),
        borrowKlayAPY: calculateKlay({
          yearlyEmission: yearlyBorrowKlayRewards,
          priceOfAsset: price,
          assetDecimal: sortedData[i / 2].decimals,
          totalSupply: BigNumber.from(poolInfo[i + 1][0]),
        }),
        vestedAmount: vestedAmount,
      });
      console.log('heresies tho');
    }
    return emissionAndAPRData;
  };

  // Fetch and format reserve incentive data from UiIncentiveDataProvider contract
  const fetchReserves = async () => {
    // @ts-ignore
    const provider = getProvider(chainId === 250 ? 42 : chainId);
    console.log('provider address', poolDataProviderAddress);
    const poolDataProviderContract = new UiPoolDataProvider({
      uiPoolDataProviderAddress: poolDataProviderAddress,
      provider,
    });

    try {
      setLoadingReserves(true);
      let reservesResponse = await poolDataProviderContract.getReservesHumanized(
        lendingPoolAddressProvider
      );
      console.log('reserves response', reservesResponse);
      const chefIncentives = new Contract(
        currentMarketData.addresses.CHEFS_INCENTIVE_CONTROLLER!,
        ChefIncentivesController,
        provider
      );

      let vestAmounts: any[][];
      if (currentAccount === undefined) {
        vestAmounts = _.map(reservesResponse.reservesData, (reserveItem) => [
          parseEther('0'),
          parseEther('0'),
        ]);
      } else {
        vestAmounts = await Promise.all(
          _.map(reservesResponse.reservesData, (reserveItem) =>
            chefIncentives.claimableReward(currentAccount, [
              reserveItem.aTokenAddress,
              reserveItem.variableDebtTokenAddress,
            ])
          )
        );
      }

      // @ts-ignore
      const aprData = await getEmissionData(reservesResponse);
      console.log('apr data', aprData);
      reservesResponse.reservesData = _.map(
        _.range(0, reservesResponse.reservesData.length),
        (i) => {
          let name = reservesResponse.reservesData[i].name;
          if (currentMarketData.addresses.SYNAPSE_ASSETS) {
            const underlyingAsset = reservesResponse.reservesData[i].underlyingAsset.toLowerCase();
            if (
              _.find(
                currentMarketData.addresses.SYNAPSE_ASSETS,
                (syn) => syn.toLowerCase() === underlyingAsset
              )
            ) {
              name = name + ' (Synapse)';
            }
          }
          return {
            ...reservesResponse.reservesData[i],
            name,
            canVest:
              parseFloat(formatEther(vestAmounts[i][0])) > 0 ||
              parseFloat(formatEther(vestAmounts[i][1])) > 0,
            ...aprData![i],
          };
        }
      );
      console.log('here1234');
      // @ts-ignore
      setReserves(reservesResponse);
      setErrorReserves(false);
    } catch (e) {
      console.log('ay papi');
      console.log('e', e);
      setErrorReserves(e.message);
    }
    setLoadingReserves(false);
  };

  // Fetch and format user incentive data from UiIncentiveDataProvider
  const fetchUserReserves = async () => {
    if (!currentAccount) return;
    // @ts-ignore
    const provider = getProvider(chainId === 250 ? 42 : chainId);
    const poolDataProviderContract = new UiPoolDataProvider({
      uiPoolDataProviderAddress: poolDataProviderAddress,
      provider,
    });

    try {
      setLoadingUserReserves(true);
      const userReservesResponse: UserReserveDataHumanized[] =
        await poolDataProviderContract.getUserReservesHumanized(
          lendingPoolAddressProvider,
          currentAccount
        );
      setUserReserves(userReservesResponse);
      setErrorUserReserves(false);
    } catch (e) {
      console.log('e', e);
      setErrorUserReserves(e.message);
    }
    setLoadingUserReserves(false);
  };

  usePolling(fetchReserves, POLLING_INTERVAL, skip, [skip, poolDataProviderAddress, chainId]);
  usePolling(fetchUserReserves, POLLING_INTERVAL, skip, [
    skip,
    poolDataProviderAddress,
    chainId,
    currentAccount,
  ]);

  const loading = loadingReserves || loadingUserReserves;
  const error = errorReserves || errorUserReserves;
  return {
    loading,
    error,
    data: { reserves, userReserves },
    refresh: () => {
      return Promise.all([fetchUserReserves(), fetchReserves()]);
    },
  };
}

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .WalletCard__inner {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 10px;
    transition: $transition;
  }

  .WalletCard {
    position: relative;
    margin: 5px;
    &:disabled {
      box-shadow: none !important;
      &:after {
        display: none;
      }
      &:active {
        .WalletCard__image-inner {
          img {
            transform: scale(1);
          }
        }
      }
    }
    &:active {
      .WalletCard__image-inner {
        img {
          transform: scale(0.95);
        }
      }
    }

    &__error {
      font-size: $extraSmall;
      font-weight: 400;
      position: absolute;
      bottom: calc(100% + 5px);
      left: 0;
    }

    &__inner {
      width: 220px;
      height: 110px;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      grid-gap: 24px;
      border-radius: 24px;
      transition-property: opacity;
      transition-duration: 0.2s;
      transition-timing-function: ease;
      border: 1px solid transparent;
      padding: 17px 8px 8px;
      @include respond-to(lg) {
        height: 80px;
        padding: 8px 4px 4px;
        justify-content: space-around;
      }
      @include respond-to(sm) {
        height: 110px;
      }
    }

    &__image-inner {
      width: 75px;
      height: 75px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: auto;
      @include respond-to(lg) {
        margin: 0px;
      }
      @include respond-to(md) {
        width: 35px;
        margin: 0;
      }
      img {
        width: 75px;
        height: 75px;
        transform: scale(1);
        transition: $transition;
        @include respond-to(md) {
          width: 80px;
          height: 80px;
        }
      }
    }

    &__text-inner {
      text-align: left;
      p {
        font-size: $large;
        font-weight: 500;
        text-align: center;
        @include respond-to(lg) {
          font-size: $large;
        }
        @include respond-to(md) {
          font-size: $large;
        }
      }
      @include respond-to(md) {
        width: 100%;
      }
      span {
        display: inline-block;
        font-weight: 300;
        font-size: 8px;
        @include respond-to(md) {
          font-size: $extraSmall;
        }
      }
    }
  }
`;

// @ts-ignore
export default staticStyles;

import React from 'react';
import classNames from 'classnames';

import goToTop from '../../../helpers/goToTop';
import Link from '../../basic/Link';

import staticStyles from './style';

interface MenuLinkProps {
  to: string;
  title: string;
  isActive: boolean;
  isAbsolute?: boolean;
  hidden?: boolean;
}

export default function MenuLink({
  to,
  title,
  isActive,
  hidden,
  isAbsolute = false,
}: MenuLinkProps) {
  return (
    <Link
      to={to}
      inNewWindow={isAbsolute}
      absolute={isAbsolute}
      className={classNames('MenuLink ButtonLink', {
        MenuLink__active: isActive,
        MenuLink__hidden: hidden,
      })}
      onClick={() => goToTop()}
    >
      <div className="MenuLink__title">
        <p>
          <b>{title}</b> <strong>{title}</strong>
        </p>{' '}
        <i />
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .MenuLink {
          .MenuLink__title {
            color: #76808f !important;
            i {
              background: #ff5171 !important;
            }
          }
        }
      `}</style>
    </Link>
  );
}

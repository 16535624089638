import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Lockdrop {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    grid-gap: 40px;
    justify-content: flex-start;

    @include respond-to(lg) {
      flex-direction: column-reverse;
      justify-content: flex-end;
    }

    &__titlecontainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      grid-gap: 16px;
    }
  }
`;

export default staticStyles;

import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import FaucetMain from './screens/FaucetMain';

export default function Faucet() {
  return (
    <Switch>
      <Route exact={true} path="/faucet" component={FaucetMain} key="FaucetMain" />
      <Redirect to="/faucet" />
    </Switch>
  );
}

import { getNetworkConfig } from './config/markets-and-network-config';
import { HttpProvider } from 'caver-js';
import Web3 from 'web3';
import axios from 'axios';
import UNI_LP_ABI from '../contracts/UniLP.json';

const sleep = (time: number) => new Promise((res) => setTimeout(res, time, 'done sleeping'));

export const getKlapKlayPrice = async (LP_TOKEN: string) => {
  const config = getNetworkConfig(8217);
  let web3;
  const rpcLink =
    Number(localStorage.getItem('serverIndex')) === null
      ? 0
      : Number(localStorage.getItem('serverIndex'));
  if (config.privateJsonRPCUrl) {
    const newProvider = new HttpProvider(config.privateJsonRPCUrl.url, {
      headers: config.privateJsonRPCUrl.headers,
    });
    web3 = new Web3(newProvider);
  } else {
    web3 = new Web3(config.publicJsonRPCUrl[rpcLink]);
  }
  const lpPair = new web3.eth.Contract(UNI_LP_ABI as any, LP_TOKEN);

  let tries = 0;
  let reserves, totalSupply;
  let tokenPriceData = { data: { 'klay-token': { usd: 0.3 } } };
  try {
    tokenPriceData = await axios.get(
      'https://api.coingecko.com/api/v3/simple/price?ids=klay-token&vs_currencies=usd'
    );
  } catch (e) {}
  while (tries < 3) {
    try {
      [reserves, totalSupply] = await Promise.all([
        lpPair.methods.getReserves().call(),
        lpPair.methods.totalSupply().call(),
      ]);
      break;
    } catch (e) {
      await sleep(5000);
      tries++;
    }
  }
  if (reserves === undefined) {
    return { klap: 0, klay: Number(tokenPriceData.data['klay-token'].usd), lp: 0 };
  }
  const klapPrice =
    (Number(reserves._reserve1) / Number(reserves._reserve0)) *
    Number(tokenPriceData.data['klay-token'].usd);
  return {
    klap: klapPrice,
    klay: Number(tokenPriceData.data['klay-token'].usd),
    lp:
      (Number(reserves._reserve0) * tokenPriceData.data['klay-token'].usd * 2) /
      Number(totalSupply),
  };
};

import React from 'react';
import ReactTooltip from 'react-tooltip';
import classNames from 'classnames';

import ValuePercent from '../../basic/ValuePercent';
import staticStyles from '../LiquidityMiningCard/style';
import Klap from '../../../images/klapLogo.svg';
import Klay from '../../../images/klay.svg';
import { TokenIcon } from '../../../helpers/config/assets-config';
import styled from 'styled-components';
import Logo, { KlayLogo } from '../../basic/Logo';
import { useIntl } from 'react-intl';
import messages from './messages';

const APRSeparation = styled.div`
  grid-gap: 24px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const IndividualContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 8px;
  justify-content: flex-end;
`;

const ToolTipText = styled.div`
  color: white;
  font-size: 14px;
  text-align: right;
`;

const APRContainer = styled.div`
  display: flex;
  grid-gap: 4px;
  justify-content: flex-end;
`;

interface LiquidityMiningBreakdownProps {
  symbol?: string;
  baseValue?: number;
  farmValue: number;
  boostValue: any;
  klayValue: number;
  className?: string;
  currencySymbol?: string;
  type: string;
}

export default function LiquidityMiningBreakdown({
  symbol,
  baseValue,
  farmValue,
  boostValue,
  klayValue,
  className,
  currencySymbol,
  type,
}: LiquidityMiningBreakdownProps) {
  const intl = useIntl();
  const isBorrow = type === 'borrow';
  const base = baseValue ? (isBorrow ? -baseValue : baseValue) : 0;
  const total =
    boostValue == 'Infinity'
      ? Number(base) + Number(farmValue) + Number(klayValue)
      : Number(base) + Number(farmValue) + Number(boostValue) + Number(klayValue);
  return (
    <div className={classNames('LiquidityMiningCard', className)}>
      <div className={'LiquidityMiningCard__valueWithTooltip'}>
        {isNaN(total) ? (
          <p>
            <b>0</b>
          </p>
        ) : (
          <ValuePercent
            maximumDecimals={2}
            minimumDecimals={2}
            value={total}
            percentSymbol={!isNaN(total)}
          />
        )}
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          data-tip={!!symbol}
          data-for={`apr-tooltip-${currencySymbol}-${type}`}
        >
          <img style={{ height: 24, width: 24 }} src={Klap} alt={'Klap icon'} />
          <img src={Klay} alt={'Klay icon'} />
        </div>
      </div>

      {/* @ts-ignore */}
      <ReactTooltip
        className="LiquidityMiningBreakdown__tooltip"
        id={`apr-tooltip-${currencySymbol}-${type}`}
        effect="solid"
      >
        <APRSeparation>
          <IndividualContainer>
            {baseValue !== undefined && (
              <ToolTipText>{intl.formatMessage(messages.baseAPR)}:</ToolTipText>
            )}
            <ToolTipText>{intl.formatMessage(messages.farmAPR)}:</ToolTipText>
            <ToolTipText>{intl.formatMessage(messages.veBoosterAPR)}:</ToolTipText>
            <ToolTipText>{intl.formatMessage(messages.klayAPR)}:</ToolTipText>
          </IndividualContainer>
          <IndividualContainer>
            {baseValue !== undefined && currencySymbol && (
              <APRContainer>
                <TokenIcon
                  className="TokenIconTooltip"
                  tokenSymbol={currencySymbol}
                  height={16}
                  width={16}
                />
                <ToolTipText>{currencySymbol}</ToolTipText>
                <ToolTipText style={{ color: '#00CC88' }}>
                  {(base * 100).toLocaleString('en-us', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </ToolTipText>
                <ToolTipText style={{ color: '#B9C0CA' }}>%</ToolTipText>
              </APRContainer>
            )}
            <APRContainer>
              <Logo height={16} width={16} />
              <ToolTipText>KLAP</ToolTipText>
              <ToolTipText style={{ color: '#00CC88' }}>
                {isNaN(farmValue)
                  ? '0'
                  : (farmValue * 100).toLocaleString('en-us', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </ToolTipText>
              <ToolTipText style={{ color: '#B9C0CA' }}>%</ToolTipText>
            </APRContainer>
            <APRContainer>
              <Logo height={16} width={16} />
              <ToolTipText>KLAP</ToolTipText>
              <ToolTipText style={{ color: '#00CC88' }}>
                {/**@TODO: FIX -> SO WE DONT CHECK IF JUST 0 */}
                {isNaN(boostValue) || boostValue === 0
                  ? '0.00'
                  : (Number(boostValue) * 100).toLocaleString('en-us', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </ToolTipText>
              <ToolTipText style={{ color: '#B9C0CA' }}>%</ToolTipText>
            </APRContainer>
            <APRContainer>
              <KlayLogo height={16} width={16} />
              <ToolTipText>KLAY</ToolTipText>
              <ToolTipText style={{ color: '#00CC88' }}>
                {isNaN(klayValue)
                  ? '0'
                  : (Number(klayValue) * 100).toLocaleString('en-us', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
              </ToolTipText>
              <ToolTipText style={{ color: '#B9C0CA' }}>%</ToolTipText>
            </APRContainer>
          </IndividualContainer>
        </APRSeparation>
        <p style={{ textAlign: 'right', marginTop: 8 }}>
          <b>{intl.formatMessage(messages.boosterNote)}</b>
        </p>
      </ReactTooltip>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .LiquidityMiningBreakdown__tooltip {
          background-color: #000000 !important;
          border-radius: 16px;
          max-width: 330px;
          opacity: 1;
        }
        .TokenIconTooltip img {
          margin-right: 0px;
        }
        .LiquidityMiningCard__valueWithTooltip {
          display: flex;
          align-items: center;
          grid-gap: 5px;
          img {
            height: 15px;
            width: 15px;
            margin-bottom: 3px;
          }
        }
      `}</style>
    </div>
  );
}

import React from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';

import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import { getAtokenInfo } from '../../../../helpers/get-atoken-info';
import Row from '../../../../components/basic/Row';
import StakeTxConfirmationView from '../../components/StakeTxConfirmationView';
import Value from '../../../../components/basic/Value';

import messages from './messages';
import { parseEther } from 'ethers/lib/utils';
import { useWeb3React } from '@web3-react/core';
import { Contract, providers } from 'ethers';
import ERC20 from '../../../../contracts/ERC20.json';
import { MAX_UINT_AMOUNT } from '@aave/protocol-js';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';

export default function StakeWithApprovalConfirmation() {
  const intl = useIntl();
  const location = useLocation();
  const { userId } = useStaticPoolDataContext();
  const { selectedStake, selectedStakeData, stakingService, STAKING_REWARD_TOKEN } =
    useStakeDataContext();
  const { currentMarketData } = useProtocolDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();

  const aTokenData = getAtokenInfo({
    address: STAKING_REWARD_TOKEN,
    symbol: 'KLAP-KLAY LP',
    decimals: 18,
    prefix: 'stk',
  });

  const query = queryString.parse(location.search);
  let amount = new BigNumber(typeof query.amount === 'string' ? query.amount : 0);

  if (!amount || !userId) {
    return null;
  }

  const handleGetTransactions = async () => {
    console.log('allowed', selectedStakeData.isAllowed);
    if (selectedStakeData.isAllowed) {
      const txn = () =>
        stakingService.populateTransaction.deposit(
          currentMarketData.addresses.LP_TOKEN!,
          parseEther(amount.toString())
        );
      console.log(txn, 'txn');
      return { type: 'DEPOSIT', txn: txn };
    } else {
      const topHolderSigner = provider!.getSigner(userId);
      const stakingApproval = new Contract(
        currentMarketData.addresses.LP_TOKEN!,
        ERC20,
        topHolderSigner
      );
      const txn = () =>
        stakingApproval.populateTransaction.approve(
          currentMarketData.addresses.MASTERCHEF!,
          MAX_UINT_AMOUNT
        );
      return { type: 'APPROVE', txn: txn };
    }
  };

  let blockingError = '';
  if (amount.gt(selectedStakeData.underlyingTokenUserBalance)) {
    blockingError = intl.formatMessage(messages.notEnoughBalance, {
      asset: aTokenData.symbol,
    });
  }

  return (
    <StakeTxConfirmationView
      caption={intl.formatMessage(messages.title)}
      description={intl.formatMessage(messages.description, {
        asset: <strong>{'LP'}</strong>,
        module: <strong>{intl.formatMessage(messages.safetyModule)}</strong>,
      })}
      getTransactionsData={handleGetTransactions}
      boxTitle={intl.formatMessage(messages.stake, { asset: aTokenData.symbol })}
      boxDescription={intl.formatMessage(messages.boxDescription)}
      mainTxName={intl.formatMessage(messages.stake, { asset: aTokenData.symbol })}
      mainTxType="STAKE_ACTION"
      blockingError={blockingError}
      goToAfterSuccess="/staking"
      successButtonTitle={intl.formatMessage(messages.backToStaking)}
      buttonTitle={intl.formatMessage(messages.buttonTitle)}
      aTokenData={aTokenData}
    >
      <Row title={intl.formatMessage(messages.amount)}>
        <Value
          symbol={'LP'}
          value={amount.toString()}
          tokenIcon={true}
          tooltipId={selectedStake.toUpperCase()}
        />
      </Row>
    </StakeTxConfirmationView>
  );
}

import React from 'react';

import TableItem from '../../../../components/BasicAssetsTable/TableItem';
import TableColumn from '../../../../components/BasicTable/TableColumn';
import Value from '../../../../components/basic/Value';
import NoData from '../../../../components/basic/NoData';
import { isAssetStable } from '../../../../helpers/config/assets-config';

import { BorrowTableItem } from './types';
import LiquidityMiningBreakdown from '../../../../components/liquidityMining/LiquidityMiningBreakdown';

export default function BorrowItem({
  id,
  symbol,
  underlyingAsset,
  availableBorrows,
  availableBorrowsInUSD,
  stableBorrowRate,
  variableBorrowRate,
  avg30DaysVariableRate,
  stableBorrowRateEnabled,
  userId,
  isFreezed,
  sincentivesAPR,
  borrowFarmAPY,
  borrowVeFarmAPY,
  borrowKlayAPY,
}: BorrowTableItem) {
  const url = `/borrow/${underlyingAsset}-${id}`;

  return (
    <TableItem
      symbol={symbol}
      url={url}
      isFreezed={isFreezed}
      isBorrow={true}
      darkOnDarkMode={true}
    >
      <TableColumn>
        {!userId || Number(availableBorrows) <= 0 ? (
          <NoData color="dark" />
        ) : (
          <Value
            value={Number(availableBorrows)}
            subValue={availableBorrowsInUSD}
            subSymbol="USD"
            maximumSubValueDecimals={2}
            minimumValueDecimals={isAssetStable(symbol) ? 2 : 5}
            maximumValueDecimals={isAssetStable(symbol) ? 2 : 5}
          />
        )}
      </TableColumn>

      {!isFreezed && (
        <TableColumn>
          <LiquidityMiningBreakdown
            klayValue={borrowKlayAPY}
            currencySymbol={symbol}
            baseValue={Number(variableBorrowRate)}
            farmValue={borrowFarmAPY || 0}
            boostValue={borrowVeFarmAPY || 0}
            symbol={symbol}
            type={'borrow'}
          />
        </TableColumn>
      )}
    </TableItem>
  );
}

import waitForTxn from '../../../../helpers/wait-for-txn';
import { useWeb3React } from '@web3-react/core';
import { Contract, providers } from 'ethers';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';
import { useUserWalletDataContext } from '../../../../libs/web3-data-provider';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import ChefIncentivesControllerABI from '../../../../contracts/ChefIncentivesController.json';
import RewardClaimerABI from '../../../../contracts/RewardClaimer.json';
import Caver, { AbiItem } from 'caver-js';
// @ts-ignore
import { prepare } from 'klip-sdk';
import { useKlipContext } from '../../../../components/wrappers/ScreensWrapper';
import _ from 'lodash';

export const useServices = () => {
  const setShowQRCode = useKlipContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const { currentAccount: userId, currentProviderName } = useUserWalletDataContext();
  const { currentMarketData, chainId } = useProtocolDataContext();
  const sendObject = {
    from: (window as any).klaytn ? (window as any).klaytn?.selectedAddress : null,
    gas: 1800000,
  };
  const mmObject = { gasPrice: 250000000000 };

  const chefController = (transaction?: object) => {
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);

    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(
        ChefIncentivesControllerABI as AbiItem[],
        currentMarketData.addresses.CHEFS_INCENTIVE_CONTROLLER!
      ) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.CHEFS_INCENTIVE_CONTROLLER!,
        ...transaction,
      });
    }
    return new Contract(
      currentMarketData.addresses.CHEFS_INCENTIVE_CONTROLLER!,
      ChefIncentivesControllerABI,
      topHolderSigner
    );
  };

  const preminingContract = (transaction?: object) => {
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);

    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(
        RewardClaimerABI as AbiItem[],
        currentMarketData.addresses.PRE_MINING_REWARD_CLAIMER!
      ) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.PRE_MINING_REWARD_CLAIMER!,
        ...transaction,
      });
    }
    return new Contract(
      currentMarketData.addresses.PRE_MINING_REWARD_CLAIMER!,
      RewardClaimerABI,
      topHolderSigner
    );
  };

  const vest = async (claimTokens: string[]) => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await chefController()
          .methods.claim(userId, claimTokens)
          .send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await chefController({
          value: '0',
          abi: JSON.stringify(_.find(ChefIncentivesControllerABI, (func) => func.name === 'claim')),
          params: JSON.stringify([userId, claimTokens]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        console.log('HERESIES');
        const claim = await chefController().claim(userId, claimTokens, mmObject);
        await claim.wait();
      }
    } catch (e) {}
  };

  const claimKlap = async () => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await preminingContract().methods.claimKLAP().send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await preminingContract({
          value: '0',
          abi: JSON.stringify(_.find(RewardClaimerABI, (func) => func.name === 'claimKLAP')),
          params: JSON.stringify([]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        console.log('HERESIES');
        const claim = await preminingContract().claimKLAP(mmObject);
        await claim.wait();
      }
    } catch (e) {}
  };

  const claimKlay = async () => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await preminingContract().methods.claimKLAY().send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await preminingContract({
          value: '0',
          abi: JSON.stringify(_.find(RewardClaimerABI, (func) => func.name === 'claimKLAY')),
          params: JSON.stringify([]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        console.log('HERESIES');
        const claim = await preminingContract().claimKLAY(mmObject);
        await claim.wait();
      }
    } catch (e) {}
  };

  return { vest, claimKlap, claimKlay };
};

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .VoteTableItem {
    min-height: 70px;
    padding: 10px 30px 10px 10px !important;
    @include respond-to(xl) {
      min-height: 60px;
    }
    @include respond-to(lg) {
      min-height: 50px;
    }
    @include respond-to(md) {
      min-height: 60px;
    }
    cursor: default !important;
    &:hover {
      box-shadow: 0px 4px 24px rgba(88, 102, 126, 0.08), 0px 1px 2px rgba(88, 102, 126, 0.12) !important;
    }

    &:active {
      border-color: transparent !important;
    }

    &__column {
      &:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        min-width: 270px;
        @media only screen and (max-width: 850px) {
          min-width: 80px;
        }
        @include respond-to(sm) {
          max-width: 60px;
          min-width: 60px;
        }
        @include respond-to(xs) {
          max-width: 60px;
          min-width: 35px;
        }
        @media only screen and (max-width: 400px) {
          max-width: 40px;
        }
      }
      &:last-child {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        grid-gap: 20px;
      }
    }

    .VoteTableItem__value {
      &:first-of-type {
        margin-bottom: 4px;
      }
      .Value__value {
        @include respond-to(sm) {
          font-size: $small;
        }
      }
    }
    .VoteTableItem__percent {
      .ValuePercent__value {
        @include respond-to(sm) {
          font-size: $small;
        }
      }
    }

    &__isFreezed-inner {
      position: relative;
      flex: 3;
      text-align: center;
    }

    .TokenIcon__dollar {
      @include respond-to(sm) {
        font-size: $small;
      }
    }

    .VoteTableItem__button {
      width: 95px;
      min-height: 32px;
      font-size: $small;
      @include respond-to(xl) {
        width: 70px;
        min-height: 26px;
      }
      @include respond-to(lg) {
        width: 50px;
        font-size: $extraSmall;
      }
      @include respond-to(md) {
        width: 70px;
        font-size: $small;
      }
    }

    .VoteTableItem__percentage {
      background-color: #000;
      color: #fff;
      padding: 5px;
    }

    .VoteTableItem__slider {
      height: 26px;
      width: 100%;
      overflow: hidden;

      transition: 0.2s;

      .VoteTableItem__thumb:focus-visible {
        outline: none;
      }

      .VoteTableItem__thumb:active {
        background-color: #113188;
      }

      .VoteTableItem__thumb {
        height: 26px;
        width: 26px;
        border-radius: 50%;
        background-color: #1450f5;
      }

      .VoteTableItem__track {
        margin-top: 13px;
        height: 2px;
        width: 100%;
        background-color: #00cc88;
      }
    }
  }
`;

export default staticStyles;

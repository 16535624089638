import React from 'react';
import { RowContainer, Title, TitleContainer, Subtitle } from './styles';
import Preloader from '../../../components/basic/Preloader';
import DefaultButton from '../../../components/basic/DefaultButton';
import styled from 'styled-components';

interface RowProps {
  title: string;
  description: string;
  img: string;
  tokenName: string;
  isLoading?: boolean;
  amount?: number;
  button?: {
    title: string;
    onClick: () => void;
    showButton?: boolean;
  };
  dontShowDivider?: boolean;
}

const Container = styled(RowContainer)`
  align-items: flex-end;
`;

const EndContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Row = (props: RowProps) => {
  return (
    <>
      <Container hideborder={props.dontShowDivider || false}>
        <TitleContainer>
          <Title>{props.title}</Title>
          <Subtitle>{props.description}</Subtitle>
        </TitleContainer>
        {props.amount !== undefined ? (
          <Subtitle>
            <img style={{ marginRight: 5 }} src={props.img} alt={props.title} height={20} />
            <b style={{ marginRight: 5, color: '#000' }}>
              {props.amount.toLocaleString('en-us', {
                minimumFractionDigits: 1,
                maximumFractionDigits: 5,
              })}
            </b>
            {props.tokenName}
          </Subtitle>
        ) : (
          <div />
        )}
        <EndContainer>
          {props.isLoading ? (
            <div style={{ width: '100%' }}>
              <Preloader smallSize />
            </div>
          ) : props.button ? (
            <DefaultButton
              className="RowButton"
              color="blue"
              disabled={
                props.amount ? props.amount < 0.000001 : props.button.showButton === undefined
              }
              onClick={props.button.onClick}
              title={props.button.title}
            />
          ) : (
            <div />
          )}
        </EndContainer>
      </Container>
      <style jsx={true} global={true}>{`
        .RowButton,
        .RowButton .ConnectButton__inner,
        .RowButton .ConnectButton__inner span {
          height: 30px;
          max-width: 180px;
          font-weight: 400 !important;
          font-size: 12px !important;
          padding: 0px 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}</style>
    </>
  );
};

export default Row;

import { defineMessages } from 'react-intl';

export default defineMessages({
  title: 'Stake',
  pageTitle: 'Your Staking + Locking Info',

  staked: '{symbol} Staked',
  activateCooldown: 'Activate Cooldown',
  coolingDown: 'Cooling down',
  howToUnstake: 'How to unstake',
  unstake: 'Unstake',
  timeLeftToUnstake: 'Time left to unstake',
  days: 'Days',
  seconds: 'Seconds',

  stakedAmountTitle: 'LP Staked',
  yourIncentives: 'Incentives',
  incentivesToClaim: 'Claimable KLAP',
  claimableKlay: 'Claimable KLAY',
  incentivesPerMonth: 'KLAP per month',
  stakingAPY: 'Staking APR',
  currentMaxSlashing: 'Current max. slashing',
  cooldownPeriod: 'Cooldown period',
  claim: 'Claim',

  noWalletConnect: 'Please connect a wallet',
  noWalletConnectDescription: 'We couldn’t detect a wallet. Connect a wallet to stake.',

  baseAPR: 'Base APR',
  totalEmissions: 'Total emission per day',
  klapPrice: 'KLAP Price',
  totalAmountStaked: 'Total KLAP-KLAY LP Staked + Locked',
  yourCurrentStakes: 'Your Current Stakes',
});

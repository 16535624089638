import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .Vote {
    flex-direction: column;
    padding-top: 44px;
    grid-gap: 30px;
    justify-content: flex-start;

    .Vote__menu {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .Vote__search-inner {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      grid-gap: 12px;
    }

    .Vote__search-title {
      display: flex;
    }

    .Vote__cast-vote-btn {
      .ConnectButton__inner span {
        font-size: 16px !important;
      }
    }

    .Vote__asset-list {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      width: 100%;
    }
  }
`;

export default staticStyles;

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .TVL {
    max-width: 1600px;
    width: 100%;
    display: flex;
    align-items: center;
    grid-gap: 15px;
    padding: 24px 0px;
    @include respond-to(xl) {
      padding: 16px 40px;
    }
    @include respond-to(lg) {
      padding: 16px 20px;
    }
    p {
      font-size: $large;
      margin-bottom: 5px;
      @include respond-to(xl) {
        font-size: $regular;
        margin-bottom: 3px;
      }
      @include respond-to(lg) {
        font-size: $medium;
      }
      @include respond-to(sm) {
        font-weight: 300;
        margin-bottom: 2px;
        font-size: $regular;
      }
    }

    .TVL_Money {
      color: #00cc88;
    }
    h3 {
      white-space: nowrap;
      font-size: 24px;
      @include respond-to(xl) {
        font-size: 20px;
      }
      @include respond-to(sm) {
        font-size: 24px;
      }
    }
  }
`;

export default staticStyles;

import { Dispatch, SetStateAction } from 'react';
import { BigNumber, providers } from 'ethers';
import { eEthereumTxType, transactionType, GasResponse } from '@aave/protocol-js';
import { TransactionReceipt } from '@ethersproject/abstract-provider';
import Caver from 'caver-js';
import { AvailableWeb3Connectors } from '../libs/web3-data-provider';
import LendingPoolABI from '../contracts/LendingPool.json';
import WETHGatewayABI from '../contracts/WETHGateway.json';
import ERC20ABI from '../contracts/ERC20.json';
import MasterChefABI from '../contracts/Masterchef.json';
import InputDecoder from 'ethereum-input-data-decoder';
// @ts-ignore
import { prepare } from 'klip-sdk';
import _ from 'lodash';

function hexToAscii(_hex: string): string {
  const hex = _hex.toString();
  let str = '';
  for (let n = 0; n < hex.length; n += 2) {
    str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
  }
  return str;
}

export enum TxStatusType {
  submitted = 'submitted',
  confirmed = 'confirmed',
  error = 'error',
}

export interface EthTransactionData {
  name: string;
  txType: eEthereumTxType;
  unsignedData?: () => Promise<transactionType>;
  gas: GasResponse;
  loading?: boolean;
  txStatus?: TxStatusType;
  txHash?: string;
  txReceipt?: TransactionReceipt;
  error?: string;
}

export interface SendEthTransactionCallbacks {
  onExecution?: (txHash: string) => void;
  onConfirmation?: (receipt: TransactionReceipt) => void;
}

export async function sendEthTransaction(
  txGetter: () => Promise<transactionType>,
  provider: providers.Web3Provider | undefined,
  currentProviderName: AvailableWeb3Connectors | undefined,
  stateSetter: Dispatch<SetStateAction<EthTransactionData>>,
  customGasPrice: string | null,
  callbacks?: SendEthTransactionCallbacks,
  setQrCode?: (request_key: string, cb?: () => void, errorCb?: () => void) => void
) {
  if (!provider) return;

  stateSetter((state) => ({
    ...state,
    loading: true,
    txStatus: undefined,
    txHash: undefined,
    txReceipt: undefined,
    error: undefined,
  }));

  console.log('txgetter');
  console.log(txGetter);
  let extendedTxData: transactionType;
  try {
    extendedTxData = await txGetter();
    //if (customGasPrice) extendedTxData.gasPrice = BigNumber.from(customGasPrice);
    extendedTxData.gasPrice = BigNumber.from(250000000000);
    // @ts-ignore
    //extendedTxData.maxPriorityFeePerGas = BigNumber.from(750000000000);
  } catch (e) {
    console.log('tx building error', e);
    stateSetter((state) => ({
      ...state,
      loading: false,
      error: e.message.toString(),
    }));
    return;
  }
  console.log(extendedTxData);
  const { from, ...txData } = extendedTxData;
  const signer = provider.getSigner(from);
  let txResponse: any | undefined;
  try {
    console.log('current provider name', currentProviderName);
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      // @ts-ignore
      txResponse = await caver.klay.sendTransaction({
        ...txData,
        from,
        // @ts-ignore
        value: txData.value ? BigNumber.from(txData.value) : undefined,
        gas: 11000000,
      });
    } else if (currentProviderName === 'klip') {
      const ABIS = [LendingPoolABI, WETHGatewayABI, ERC20ABI, MasterChefABI];
      let decoded: any;
      let abi: any;
      for (let i = 0; i < ABIS.length; i++) {
        let decoder = new InputDecoder(ABIS[i]);
        decoded = decoder.decodeData(txData.data!);
        if (decoded.method !== null) {
          abi = JSON.stringify(_.find(ABIS[i], (func: any) => func.name === decoded.method));
          break;
        }
      }
      const inputs = _.map(decoded.inputs, (input) => {
        return input._isBigNumber !== undefined ? BigNumber.from(input).toString() : input;
      });
      const returnVal = await prepare.executeContract({
        bappName: 'Klap',
        to: txData.to,
        abi: abi,
        params: JSON.stringify(inputs),
        value: BigNumber.from(txData.value || '0').toString(),
      });
      setQrCode!(
        returnVal.request_key,
        () => {
          console.log('success');
          stateSetter((state) => ({
            ...state,
            txStatus: TxStatusType.confirmed,
            loading: false,
          }));

          // if onConfirmation callback provided - call it
          if (callbacks?.onConfirmation) {
            callbacks.onConfirmation(txResponse);
          }
          return;
        },
        () => {
          console.log('error');
          stateSetter((state) => ({
            ...state,
            txStatus: TxStatusType.error,
            loading: false,
          }));

          // if onConfirmation callback provided - call it
          if (callbacks?.onConfirmation) {
            callbacks.onConfirmation(txResponse);
          }
          return;
        }
      );
      stateSetter((state) => ({
        ...state,
        loading: false,
      }));
      return;
    } else {
      txResponse = await signer.sendTransaction({
        ...txData,
        value: txData.value ? BigNumber.from(txData.value) : undefined,
      });
    }
  } catch (e) {
    console.error('send-ethereum-tx', e);

    stateSetter((state) => ({
      ...state,
      loading: false,
      error: e.message.toString(),
    }));
    return;
  }

  const txHash =
    txResponse === undefined
      ? undefined
      : txResponse.transactionHash !== undefined
      ? txResponse.transactionHash
      : txResponse?.hash;

  if (!txHash) {
    stateSetter((state) => ({
      ...state,
      loading: false,
    }));
    return;
  }

  stateSetter((state) => ({
    ...state,
    txHash,
    txStatus: TxStatusType.submitted,
  }));

  // if onExecution callback provided - call it
  if (callbacks?.onExecution) {
    callbacks.onExecution(txHash);
  }

  try {
    // @ts-ignore
    if (currentProviderName === 'kaikas') {
      stateSetter((state) => ({
        ...state,
        txReceipt: txResponse,
        txStatus: TxStatusType.confirmed,
        loading: false,
      }));

      // if onConfirmation callback provided - call it
      if (callbacks?.onConfirmation) {
        callbacks.onConfirmation(txResponse);
      }
    } else {
      const txReceipt = await txResponse.wait(1);
      stateSetter((state) => ({
        ...state,
        txReceipt,
        txStatus: TxStatusType.confirmed,
        loading: false,
      }));

      // if onConfirmation callback provided - call it
      if (callbacks?.onConfirmation) {
        callbacks.onConfirmation(txReceipt);
      }
    }
  } catch (e) {
    let error = 'network error has occurred, please check tx status in an explorer';

    try {
      console.log('tx hash', txHash);
      let tx = await provider.getTransaction(txHash);
      // @ts-ignore TODO: need think about "tx" type
      const code = await provider.call(tx, tx.blockNumber);
      error = hexToAscii(code.substr(138));
    } catch (e) {
      console.log('network error', e);
    }

    stateSetter((state) => ({
      ...state,
      error,
      txStatus: TxStatusType.error,
      loading: false,
    }));
  }
}

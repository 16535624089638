import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .StakingWrapper {
    margin-top: 50px !important;
    margin-bottom: 10px !important;
    flex-direction: column !important;
    @include respond-to(sm) {
      margin-top: 0 !important;
      display: block !important;
    }

    .CurrentStakes {
      display: flex;
      flex-direction: row;
    }

    &__mobile-switcher {
      display: none;
      @include respond-to(sm) {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }

    &__content-inner {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 46px;
      @include respond-to(md) {
        display: block;
      }
    }

    &__content-left,
    &__content-right {
      border-radius: $borderRadius;
    }

    &__content-right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      width: 100%;
      .Caption__description {
        padding: 0 60px;
      }
      .TxConfirmationView {
        grid-gap: 24px;
      }
    }

    &__content-right-outer {
      grid-gap: 24px;
      display: flex;
      flex-direction: column;
    }

    &__content-left {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-left: 20px;
      grid-gap: 24px;
      @include respond-to(sm) {
        width: 100%;
        margin: 0;
        padding: 30px 15px 20px;
        display: none;
      }
    }
    &__contentRightActive {
      @include respond-to(md) {
        margin-bottom: 24px;
        width: auto;
      }
      @include respond-to(sm) {
        display: flex;
        flex-direction: column;
        grid-gap: 16px;
      }
    }

    &__asset-switcherWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      @include respond-to(lg) {
        margin-bottom: 20px;
      }
      @include respond-to(sm) {
        margin-bottom: 30px;
      }
      .LabeledSwitch {
        p {
          span {
            text-transform: uppercase;
          }
        }
      }
    }

    &__cards-inner {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      @include respond-to(xl) {
        margin-bottom: 30px;
      }
      @include respond-to(lg) {
        margin-bottom: 20px;
      }
      @include respond-to(sm) {
        margin-bottom: 30px;
        justify-content: center;
      }
    }

    .StakingWrapper__button {
      font-size: $medium;
      @include respond-to(xl) {
        font-size: $extraSmall;
      }
      @include respond-to(sm) {
        font-size: $small;
      }
    }

    &__info-timerWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__info-timerInner {
      width: 100%;
      border-radius: $borderRadius;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: default;
      @include respond-to(xl) {
        height: 32px;
      }
      .StakingWrapper__timer {
        margin-right: 0 !important;
        opacity: 0.5 !important;
      }
    }
    &__info-timerText {
      margin-top: 5px;
      font-size: $medium;
      @include respond-to(xl) {
        font-size: $extraSmall;
      }
      @include respond-to(sm) {
        font-size: $small;
      }
    }

    &__unstakeTime-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: $medium;
      margin-top: 10px;
      @include respond-to(xl) {
        font-size: $extraSmall;
      }
      p {
        margin-bottom: 1px;
      }
      .StakingWrapper__unstakeTimer {
        margin-right: 0 !important;
        font-size: $regular !important;
        @include respond-to(xl) {
          font-size: $small !important;
        }
      }
    }
    &__gradientButton {
      width: 100%;
      height: 40px;
      border-radius: $borderRadius;
      position: relative;
      font-size: $medium;
      transition: $transition;
      @include respond-to(xl) {
        font-size: $extraSmall;
        height: 32px;
      }
      @include respond-to(sm) {
        font-size: $small;
      }
      &:active {
        opacity: 0.8;
      }
      &:hover {
        &:after {
          opacity: 1;
        }
      }
      &:disabled {
        cursor: not-allowed;
        &:active {
          opacity: 1;
        }
        &:after {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        left: -1px;
        right: -1px;
        top: -1px;
        bottom: -1px;
        border-radius: $borderRadius;
        filter: blur(4px);
        opacity: 0;
        transition: $transition;
      }
    }
    &__gradientButton-inner {
      width: 100%;
      border-radius: $borderRadius;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      z-index: 2;
      @include respond-to(xl) {
        height: 32px;
      }
    }

    &__cooldownPeriodTime {
      font-size: $large;
      @include respond-to(xl) {
        font-size: $regular;
      }
      @include respond-to(lg) {
        font-size: $medium;
      }
      @include respond-to(md) {
        font-size: $regular;
      }
    }

    .StakingWrapper__link {
      z-index: 5;
    }

    .StakingWrapper__row {
      display: flex;
      flex-direction: column;
      grid-gap: 6px;

      .Value {
        align-items: flex-start;
      }

      .Row__content {
        align-items: flex-start;
      }

      .Row__title {
        color: #76808f;
      }
    }

    .Row__container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 24px;
    }
  }
`;

export default staticStyles;

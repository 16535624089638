import { defineMessages } from 'react-intl';

export default defineMessages({
  asset: 'Asset',
  lockedAmount: 'Locked',
  recievingAmount: 'Receiving',
  harvestAmount: 'Harvest',
  expiry: 'Expiry',
  status: 'Status',
  action: 'Action',
  noAssets: 'You have not locked assets yet.',
  lockdropTitle: 'KLAP Liquidity Drop',
  lockdropMessage:
    'Deposit KLAY for 3 months into the Liquidity Drop. Get free KLAP to pair your KLAY. You will receive KLAP-KLAY LP tokens after 3 months. You will be able to claim extra rewards from Pool 2 throughout the 3 months.',
  toBeEarned: 'To Be Earned',
  eventEndsIn: 'Event Ends in',
  tvl: 'KLAY Deposited',
  aggregateTVL: 'Locked',
  assetQuestion: 'Which asset do you want to deposit?',
  note: 'Deposit here to get aTokens',
  stakeLengthQuestion: 'How long do you want to stake for? (months)',
  amountStakeQuestion: 'How much do you want to deposit?',
  availableDeposit: 'Available to Deposit:',
  claim: 'Claim LP',
  harvestRewards: 'Harvest',
  unlocked: 'Unlocked',
  locked: 'Locked',
  deposited: 'Deposited',
  cap: 'Cap',
  readMore: 'Calculate your rewards here.',
  klapCalculation: 'If the liquidity drop ends now and you deposited {input} KLAY, you would get: ',
  mediumLink:
    'https://docs.google.com/spreadsheets/d/1XRQ9VBnmXTenkHnthZBWPmPU8L-yjUrSxFqYNZcPqPc/edit#gid=0',
  errorMessage:
    'There was an error processing your request. Please make sure the amount you plan to deposit will not lower your Health Ratio below 1. You can increase health ratio by repaying your borrows.',
});

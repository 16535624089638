import { useState } from 'react';
import { useConnectionStatusContext } from '../../../../libs/connection-status-provider';
import { usePolling } from '../../../../libs/hooks/use-polling';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import ERC20ABI from '../../../../contracts/ERC20.json';
import { formatEther } from 'ethers/lib/utils';
import { useMultiCall } from '../../../../libs/pool-data-provider/hooks/use-multicall';
import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { getKlapKlayPrice } from '../../../../helpers/get-klap-price';
import RewardClaimerABI from '../../../../contracts/RewardClaimer.json';
const POLLING_INTERVAL = 120 * 1000;

interface InfoData {
  klapLocked: number;
  klapStaked: number;
  klapPrice: number;
  totalSupply: number;
  klapBalance: number;

  preminingKlapRewards: number;
  preminingKlayRewards: number;
  preminingKlapClaimable: boolean;
}

interface ReturnData {
  loading: boolean;
  error: any;
  data: InfoData;
  refresh: () => void;
}

export const useGetMarketInfo: () => ReturnData = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<InfoData>({
    klapLocked: 0,
    klapStaked: 0,
    klapPrice: 0,
    totalSupply: 0,
    klapBalance: 0,
    preminingKlapRewards: 0,
    preminingKlayRewards: 0,
    preminingKlapClaimable: false,
  });
  const { userId } = useStaticPoolDataContext();
  const { isRPCActive } = useConnectionStatusContext();
  const erc20_call = useMultiCall([ERC20ABI, ERC20ABI]);
  const rewardclaimer_call = useMultiCall([RewardClaimerABI]);
  const { currentMarketData } = useProtocolDataContext();

  const fetchData = async () => {
    try {
      setLoading(true);

      const [[[veLockedAmount, totalSupply], [lpStaked]], { klap }] = await Promise.all([
        erc20_call(
          [currentMarketData.addresses.MAIN_TOKEN!, currentMarketData.addresses.LP_TOKEN!],
          [['balanceOf', 'totalSupply'], ['balanceOf']],
          [[[currentMarketData.addresses.VE!], []], [[currentMarketData.addresses.MASTERCHEF!]]]
        ),
        getKlapKlayPrice(currentMarketData.addresses.LP_TOKEN!),
      ]);
      let balance = 0;
      let preminingKlayRewards = 0;
      let preminingKlapRewards = 0;
      let preminingKlapClaimable = false;
      if (userId) {
        const [[[balanceUser]], [[klayToClaim, klapToClaim, isPreminingKlapClaimable]]] =
          await Promise.all([
            erc20_call([currentMarketData.addresses.MAIN_TOKEN!], [['balanceOf']], [[[userId]]]),
            rewardclaimer_call(
              [currentMarketData.addresses.PRE_MINING_REWARD_CLAIMER!],
              [['klayToClaim', 'klapToClaim', 'klapClaimable']],
              [[[userId], [userId], []]]
            ),
          ]);
        preminingKlapClaimable = isPreminingKlapClaimable[0];
        balance = Number(formatEther(balanceUser[0]));
        preminingKlayRewards = Number(formatEther(klayToClaim[0]));
        preminingKlapRewards = Number(formatEther(klapToClaim[0]));
      }

      const data: InfoData = {
        klapLocked: Number(formatEther(veLockedAmount[0])),
        klapStaked: Number(formatEther(lpStaked[0])),
        klapPrice: klap,
        totalSupply: Number(formatEther(totalSupply[0])),
        klapBalance: balance,
        preminingKlapRewards: preminingKlapRewards,
        preminingKlayRewards: preminingKlayRewards,
        preminingKlapClaimable: preminingKlapClaimable,
      };
      setData(data);
      setError(false);
    } catch (e) {
      console.log('e', e);
      setError(e.message);
    }
    setLoading(false);
  };

  usePolling(fetchData, POLLING_INTERVAL, !isRPCActive, []);

  return {
    loading,
    error,
    data: data,
    refresh: fetchData,
  };
};

import { defineMessages } from 'react-intl';

export default defineMessages({
  caption: 'Deposit LP Tokens',
  provideLiquidity: 'Provide Liquidity',
  description: 'How much do you want to stake?',
  safetyModule: 'Safety Module',
  protocolIncentives: 'protocol incentives',
  availableToStake: 'Available to stake',
  approveStake: 'Approve',
});

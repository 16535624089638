import React from 'react';
import { FilterOptions } from '../../modules/deposit/screens/DepositMain';
import SearchField from '../fields/SearchField';
import staticStyles from './style';
import { useIntl } from 'react-intl';
import messages from './messages';

export type AssetsFilterPanelProps = {
  options: string[];
  switchOnToggle: (value: FilterOptions) => void;
  switchValue: FilterOptions;
  searchValue: string;
  searchOnChange: (value: string) => void;
  darkOnDarkMode?: boolean;
};

interface SearchBarProps {
  searchValue: string;
  searchOnChange: (value: string) => void;
}

export const SearchBar = ({ searchValue, searchOnChange }: SearchBarProps) => {
  const intl = useIntl();
  return (
    <div className="AssetsFilterPanel__search-inner">
      <div className="AssetsFilterPanel__search-title">
        {intl.formatMessage(messages.searchByAssetName)}
      </div>
      <SearchField value={searchValue} onChange={searchOnChange} />
    </div>
  );
};

export default function AssetsFilterPanel({
  options,
  switchOnToggle,
  switchValue,
  searchValue,
  searchOnChange,
  darkOnDarkMode,
}: AssetsFilterPanelProps) {
  const intl = useIntl();
  return (
    <div className="AssetsFilterPanel">
      <div className="AssetsFilterPanel__content">
        <div className="SwitcherContent">
          <div>{intl.formatMessage(messages.filterBy)}</div>
          <div className="SwitcherBackground">
            {options.map((option) => (
              <div
                className={`SwitcherInput ${option === switchValue && 'SwitcherInput__active'}`}
                onClick={() => switchOnToggle(option as FilterOptions)}
              >
                {option === FilterOptions.All
                  ? intl.formatMessage(messages.all)
                  : option === FilterOptions.OwnedAssets
                  ? intl.formatMessage(messages.ownedAssets)
                  : intl.formatMessage(messages.stableCoins)}
              </div>
            ))}
          </div>
        </div>

        <div className="AssetsFilterPanel__search-inner">
          <div className="AssetsFilterPanel__search-title">
            {intl.formatMessage(messages.searchByAssetName)}
          </div>
          <SearchField value={searchValue} onChange={searchOnChange} />
        </div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </div>
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  native: 'Native',
  pageTitle: 'Markets',
  selectMarket: 'Select a market',
  rewardsInformation: 'Rewards information',
  klapLocked: 'KLAP Locked',
  klapStaked: 'KLAY-KLAP Staked',
  klapPrice: 'KLAP Price',
  klapMarketCap: 'KLAP Market Cap',
  totalTVL: 'KLAP TVL',
  vestAll: 'Vest All',
  announcementText:
    'Pre-mining is live! To celebrate the launch of mainnet and reward our early users, we will be rewarding depositors and borrowers KLAP and KLAY tokens.',
  announcementLink: 'Learn more here.',
  physicalLink: `https://medium.com/@klapfinance/klap-finance-klap-klay-pre-mining-program-d20dfa046d8d`,
  claim: 'Claim',
  preminingRewards: 'Pre-Mining Rewards',
});

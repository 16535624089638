import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import { useMenuContext } from '../../libs/menu';

import messages from './messages';
import staticStyles from './style';
import { networkConfigs } from '../../helpers/config/markets-and-network-config';

interface ConnectButtonProps {
  className?: string;
  size?: 'small' | 'normal' | 'medium';
}

export function SwitchNetwork({ className, size = 'normal' }: ConnectButtonProps) {
  const intl = useIntl();
  const { ethereum } = window as any;
  return (
    <button
      className={classNames('ConnectButton', `ConnectButton__${size}`, className)}
      type="button"
      onClick={() => {
        ethereum
          .request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: '0x3E9',
                chainName: 'Baobab',
                nativeCurrency: {
                  name: 'Klaytn Network',
                  symbol: 'KLAY',
                  decimals: 18,
                },
                rpcUrls: networkConfigs['1001'].publicJsonRPCUrl,
                blockExplorerUrls: ['https://scope.klaytn.com/'],
              },
            ],
          })
          .catch((err: any) => {
            console.error(err);
          });
      }}
    >
      <div className="ConnectButton__inner">
        <span>{intl.formatMessage(messages.switchNetwork)}</span>
      </div>

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .ConnectButton {
          &:hover {
            .ConnectButton__inner {
              opacity: 0.7;
            }
          }

          &__inner {
            background: #000;
            color: #fff;
            height: 50px;
          }
        }

        .ConnectButton__normal,
        .ConnectButton__medium {
          &:hover {
            .ConnectButton__inner {
              opacity: 0.7;
            }
          }

          .ConnectButton__inner {
            background: #000;
            color: ${'#fff'};
          }
        }
      `}</style>
    </button>
  );
}

export default function ConnectButton({ className, size = 'normal' }: ConnectButtonProps) {
  const intl = useIntl();
  const { showSelectWalletModal } = useUserWalletDataContext();
  const { closeMobileMenu } = useMenuContext();

  return (
    <button
      className={classNames('ConnectButton', `ConnectButton__${size}`, className)}
      type="button"
      onClick={() => {
        showSelectWalletModal();
        closeMobileMenu();
      }}
    >
      <div className="ConnectButton__inner">
        <span>{intl.formatMessage(messages.connect)}</span>
      </div>

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .ConnectButton {
          &:hover {
            .ConnectButton__inner {
              opacity: 0.7;
            }
          }

          &__inner {
            background: #000;
            color: #fff;
            height: 50px;
          }
        }

        .ConnectButton__normal,
        .ConnectButton__medium {
          &:hover {
            .ConnectButton__inner {
              opacity: 0.7;
            }
          }

          .ConnectButton__inner {
            background: #000;
            color: ${'#fff'};
          }
        }
      `}</style>
    </button>
  );
}

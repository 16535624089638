import React, { useState } from 'react';
import moment from 'moment';
import DefaultButton from '../../../components/basic/DefaultButton';
import Preloader from '../../../components/basic/Preloader';
import { useIntl } from 'react-intl';
import messages from './messages';
import { HorizontalDivider } from '../../../components/Divider';
import unlocked from '../images/unlocked.svg';
import locked from '../images/locked.svg';
import { useThemeContext } from '@aave/aave-ui-kit';
import classNames from 'classnames';
import { useServices } from '../functions';
import { useUserWalletDataContext } from '../../../libs/web3-data-provider';
import _ from 'lodash';

interface VeInfoProps {
  listTokens: {
    symbol: string;
    address: string;
    amount: number;
    timeContract: string;
    rewardContract: string;
    expiry: number;
    isKlapClaimable: boolean;
    klapAvailable: boolean;
    klayAvailable: boolean;
  }[];
}

const BLACKLISTED_ADDRESSES = [
  '0x57864492D21B6F264330aE23367A3870b05C66d4',
  '0x3FdC3A426848254965cDA9b1F52966bE01218D71',
  '0x67F68d3756edeBDE67d26073954A6Bd0E82150Fb',
  '0x53e2ecEa783753b2D09FaBF8424A89a0e9683a56',
  '0xee0fEbb1E014f8e56279416a9FD1f9BdC7b93205',
  '0x614Fa86eF285520e46840c371aa95d75217D1326',
  '0x8A18326Dee49042642b9AB6C44738CC7Ad1Fe505',
  '0x73EB4c1DADb5cee393DA1e80b9a7098fa5afd437',
  '0x232832F88e98e4680A12c03D12eF1FDA22ED7122',
];

const VeInfo = (props: VeInfoProps) => {
  const intl = useIntl();
  const { currentAccount } = useUserWalletDataContext();
  const { claimTokens, claimKlap, claimKlay } = useServices();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingKlap, setIsLoadingKlap] = useState(false);
  const [isLoadingKlay, setIsLoadingKlay] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { md } = useThemeContext();
  return (
    <div className="LockedTable">
      {!md ? (
        <>
          <div className="LockedTable__header">
            <div className="LockedTable__headertitle">{intl.formatMessage(messages.asset)}</div>
            <div className="LockedTable__headertitle">{intl.formatMessage(messages.amount)}</div>
            <div className="LockedTable__headertitle">{intl.formatMessage(messages.duration)}</div>
            <div className="LockedTable__headertitle">{intl.formatMessage(messages.expiry)}</div>
            <div className="LockedTable__headertitle">{intl.formatMessage(messages.status)}</div>
            <div className="LockedTable__headertitle">{intl.formatMessage(messages.action)}</div>
          </div>
          {HorizontalDivider}
          <div className="LockedTable__listcontainer">
            {props.listTokens.length === 0 ? (
              <div className="LockedTable__no-assets-container">
                <div className="LockedTable__no-assets-emoji">🤷‍♂️</div>
                <div className="LockedTable__no-assets-text">
                  {intl.formatMessage(messages.noAssets)}
                </div>
              </div>
            ) : (
              props.listTokens.map((token, index) => (
                <div className="LockedTable__row-container">
                  <div className="LockedTable__subtitle">{token.symbol}</div>
                  <div className="LockedTable__subtitle">
                    {token.amount.toLocaleString('en-us', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                    })}{' '}
                    {token.symbol}
                  </div>
                  <div className="LockedTable__subtitle">
                    {moment(token.expiry * 1000).fromNow()}
                  </div>
                  <div className="LockedTable__subtitle">
                    {moment(token.expiry * 1000).format('MM/DD/YY HH:mm')}
                  </div>
                  <div className="LockedTable__status-col">
                    <img
                      className="LockedTable__lock"
                      src={token.expiry < moment().unix() ? unlocked : locked}
                      alt="Status Icon"
                    />
                    {token.expiry < moment().unix()
                      ? intl.formatMessage(messages.unlocked)
                      : intl.formatMessage(messages.locked)}{' '}
                  </div>
                  <div className="LockedTable__buttonContainer">
                    {isLoadingKlap && currentIndex === index ? (
                      <div className="LockedTable__PreloaderContainer">
                        <Preloader smallSize />
                      </div>
                    ) : (
                      <DefaultButton
                        className="TableAction"
                        disabled={
                          !token.isKlapClaimable ||
                          !token.klapAvailable ||
                          _.find(
                            BLACKLISTED_ADDRESSES,
                            (i) => i.toLowerCase() === currentAccount.toLowerCase()
                          ) !== undefined
                        }
                        onClick={async () => {
                          setIsLoadingKlap(true);
                          setCurrentIndex(index);
                          await claimKlap(token.rewardContract);
                          setIsLoadingKlap(false);
                        }}
                        title={intl.formatMessage(messages.claimKlap)}
                        color="blue"
                      />
                    )}
                    {isLoadingKlay && currentIndex === index ? (
                      <div className="LockedTable__PreloaderContainer">
                        <Preloader smallSize />
                      </div>
                    ) : (
                      <DefaultButton
                        className="TableAction"
                        disabled={
                          !token.klayAvailable ||
                          _.find(
                            BLACKLISTED_ADDRESSES,
                            (i) => i.toLowerCase() === currentAccount.toLowerCase()
                          ) !== undefined
                        }
                        onClick={async () => {
                          setIsLoadingKlay(true);
                          setCurrentIndex(index);
                          await claimKlay(token.rewardContract);
                          setIsLoadingKlay(false);
                        }}
                        title={intl.formatMessage(messages.claimKlay)}
                        color="blue"
                      />
                    )}
                    {isLoading && currentIndex === index ? (
                      <div className="LockedTable__PreloaderContainer">
                        <Preloader smallSize />
                      </div>
                    ) : (
                      <DefaultButton
                        className="TableAction"
                        disabled={
                          token.amount < 0.0001 ||
                          token.expiry > moment().unix() ||
                          _.find(
                            BLACKLISTED_ADDRESSES,
                            (i) => i.toLowerCase() === currentAccount.toLowerCase()
                          ) !== undefined
                        }
                        onClick={async () => {
                          setIsLoading(true);
                          setCurrentIndex(index);
                          await claimTokens(token.timeContract, token.address);
                          setIsLoading(false);
                        }}
                        title={intl.formatMessage(messages.claim)}
                        color="blue"
                      />
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
          {HorizontalDivider}
        </>
      ) : (
        <div
          className={classNames(
            'MobileLockedTable__listcontainer',
            props.listTokens.length === 0 && 'MobileLockedTable__no-asset-container'
          )}
        >
          {props.listTokens.length === 0 ? (
            <div className="MobileLockedTable__container">
              <div className="LockedTable__no-assets-container">
                <div className="LockedTable__no-assets-emoji">🤷‍♂️</div>
                <div className="LockedTable__no-assets-text">
                  {intl.formatMessage(messages.noAssets)}
                </div>
              </div>
            </div>
          ) : (
            props.listTokens.map((token, index) => (
              <div className="MobileLockedTable__container">
                <div className="MobileLockedTable__row">
                  <div className="MobileLockedTable__title">
                    {intl.formatMessage(messages.asset)}
                  </div>
                  <div className="MobileLockedTable__answer">{token.symbol}</div>
                </div>
                <div className="MobileLockedTable__row">
                  <div className="MobileLockedTable__title">
                    {intl.formatMessage(messages.amount)}
                  </div>
                  <div className="MobileLockedTable__answer">
                    {token.amount.toLocaleString('en-us', {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 4,
                    })}{' '}
                    {token.symbol}
                  </div>
                </div>
                <div className="MobileLockedTable__row">
                  <div className="MobileLockedTable__title">
                    {intl.formatMessage(messages.duration)}
                  </div>
                  <div className="MobileLockedTable__answer">
                    {moment(token.expiry * 1000).fromNow()}
                  </div>
                </div>
                <div className="MobileLockedTable__row">
                  <div className="MobileLockedTable__title">
                    {intl.formatMessage(messages.expiry)}
                  </div>
                  <div className="MobileLockedTable__answer">
                    {moment(token.expiry * 1000).format('MM/DD/YY HH:mm')}
                  </div>
                </div>
                <div className="MobileLockedTable__row">
                  <div className="MobileLockedTable__title">
                    {intl.formatMessage(messages.status)}
                  </div>
                  <div className="MobileLockedTable__answer">
                    <div className="LockedTable__status-col">
                      <img
                        className="LockedTable__lock"
                        src={token.expiry < moment().unix() ? unlocked : locked}
                        alt="Status Icon"
                      />
                      {token.expiry < moment().unix() ? 'Unlocked' : 'Locked'}{' '}
                    </div>
                  </div>
                </div>
                <div className="MobileLockedTable__actionContainer">
                  <div className="LockedTable__buttonContainer">
                    {isLoadingKlap && currentIndex === index ? (
                      <div className="LockedTable__PreloaderContainer">
                        <Preloader smallSize />
                      </div>
                    ) : (
                      <DefaultButton
                        className="TableAction"
                        disabled={
                          !token.isKlapClaimable ||
                          !token.klapAvailable ||
                          _.find(
                            BLACKLISTED_ADDRESSES,
                            (i) => i.toLowerCase() === currentAccount.toLowerCase()
                          ) !== undefined
                        }
                        onClick={async () => {
                          setIsLoadingKlap(true);
                          setCurrentIndex(index);
                          await claimKlap(token.rewardContract);
                          setIsLoadingKlap(false);
                        }}
                        title={intl.formatMessage(messages.claimKlap)}
                        color="blue"
                      />
                    )}
                    {isLoadingKlay && currentIndex === index ? (
                      <div className="LockedTable__PreloaderContainer">
                        <Preloader smallSize />
                      </div>
                    ) : (
                      <DefaultButton
                        className="TableAction"
                        disabled={
                          !token.klayAvailable ||
                          _.find(
                            BLACKLISTED_ADDRESSES,
                            (i) => i.toLowerCase() === currentAccount.toLowerCase()
                          ) !== undefined
                        }
                        onClick={async () => {
                          setIsLoadingKlay(true);
                          setCurrentIndex(index);
                          await claimKlay(token.rewardContract);
                          setIsLoadingKlay(false);
                        }}
                        title={intl.formatMessage(messages.claimKlay)}
                        color="blue"
                      />
                    )}
                    {isLoading && currentIndex === index ? (
                      <div className="LockedTable__PreloaderContainer">
                        <Preloader smallSize />
                      </div>
                    ) : (
                      <DefaultButton
                        className="TableAction"
                        disabled={
                          token.amount < 0.0001 ||
                          token.expiry > moment().unix() ||
                          _.find(
                            BLACKLISTED_ADDRESSES,
                            (i) => i.toLowerCase() === currentAccount.toLowerCase()
                          ) !== undefined
                        }
                        onClick={async () => {
                          setIsLoading(true);
                          setCurrentIndex(index);
                          await claimTokens(token.timeContract, token.address);
                          setIsLoading(false);
                        }}
                        title={intl.formatMessage(messages.claim)}
                        color="blue"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default VeInfo;

import styled from 'styled-components';
import { Paper } from '@mui/material';
import { CardContainer } from '../../components/CardContainer';

export const Panel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-gap: 20px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const CardSmallBackground = styled(Paper)<{ backgroundcolor?: string }>`
  min-height: 180px;
  width: 100%;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;
  box-shadow: none !important;
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const CardBigBackground = styled(CardSmallBackground)`
  @media (min-width: 600px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const SplitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 25px;
  @media (min-width: 600px) {
    max-width: 650px;
  }
`;

export const LowerRightCard = styled(CardContainer).attrs((props) => ({
  ...props,
  backgroundcolor: 'white',
}))`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  grid-gap: 15px;
  padding: 24px;
`;

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .BasicTable__header {
    padding: 0 60px !important;
  }
  .VoteTable {
    &__header-column {
      &:first-of-type {
        align-items: flex-start;
        justify-content: flex-start;
        max-width: 230px;
        @media only screen and (max-width: 850px) {
          min-width: 80px;
        }
        @include respond-to(sm) {
          max-width: 60px;
          min-width: 60px;
        }
        @include respond-to(xs) {
          max-width: 60px;
          min-width: 35px;
        }
        @media only screen and (max-width: 400px) {
          max-width: 40px;
        }
      }
      &:nth-of-type(3) {
        @include respond-to(sm) {
          display: none;
        }
      }
    }
  }
`;

export default staticStyles;

import React from 'react';
import closeIcon from '../../../images/closeIcon.svg';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import messages from './messages';

const CloseIcon = styled.img`
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.7;
  }
`;

const KlipView = (props: { showQRCode: boolean; goBack: () => void; showX: boolean }) => {
  const intl = useIntl();
  return (
    <div
      style={{
        zIndex: props.showQRCode ? 100 : -10,
        opacity: props.showQRCode ? 1 : 0,
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#fff',
      }}
      id="qrCodeContainer"
    >
      <div style={{ padding: 30, borderRadius: 24, border: '1px solid black' }}>
        {props.showX ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
            <CloseIcon onClick={() => props.goBack()} alt="Close" src={closeIcon} />
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <canvas id="qrcode" />
        </div>
        <div>{intl.formatMessage(messages.qrCode)}</div>
      </div>
    </div>
  );
};

export default KlipView;

import { MAX_UINT_AMOUNT } from '@aave/protocol-js';
import { useWeb3React } from '@web3-react/core';
import { providers, Contract } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { getProvider } from '../../helpers/config/markets-and-network-config';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import VE_ABI from '../../contracts/Ve.json';
import ERC20ABI from '../../contracts/ERC20.json';
import RouterABI from '../../contracts/UniswapRouter.json';
import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import Caver, { AbiItem } from 'caver-js';
import waitForTxn from '../../helpers/wait-for-txn';
import _ from 'lodash';
import { useKlipContext } from '../../components/wrappers/ScreensWrapper';
// @ts-ignore
import { prepare } from 'klip-sdk';
import moment from 'moment';
import { ONE_MONTH } from '../manage/functions';

export const useServices = () => {
  const setShowQRCode = useKlipContext();
  const { currentAccount: userId, currentProviderName } = useUserWalletDataContext();
  const { currentMarketData, chainId } = useProtocolDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const sendObject = {
    from: (window as any).klaytn ? (window as any).klaytn?.selectedAddress : null,
    gas: 1800000,
  };
  const mmObject = { gasPrice: 250000000000 };
  const ve = (transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(VE_ABI as AbiItem[], currentMarketData.addresses.VE!) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.VE!,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    return new Contract(currentMarketData.addresses.VE!, VE_ABI, topHolderSigner);
  };

  const erc20 = (transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(
        ERC20ABI as AbiItem[],
        currentMarketData.addresses.MAIN_TOKEN!
      ) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.MAIN_TOKEN!,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    return new Contract(currentMarketData.addresses.MAIN_TOKEN!, ERC20ABI, topHolderSigner);
  };

  const erc20LP = (transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(
        ERC20ABI as AbiItem[],
        currentMarketData.addresses.LP_TOKEN!
      ) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.LP_TOKEN!,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    return new Contract(currentMarketData.addresses.LP_TOKEN!, ERC20ABI, topHolderSigner);
  };

  const router = (transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(
        RouterABI as AbiItem[],
        currentMarketData.addresses.CLAIMSWAP_ROUTER!
      ) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.CLAIMSWAP_ROUTER!,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    return new Contract(currentMarketData.addresses.CLAIMSWAP_ROUTER!, RouterABI, topHolderSigner);
  };

  const approve = async (address: string) => {
    if (currentProviderName === 'kaikas') {
      const returnVal = await erc20().methods.approve(address, MAX_UINT_AMOUNT).send(sendObject);
      await waitForTxn(provider, returnVal.transactionHash);
    } else if (currentProviderName === 'klip') {
      const returnVal = await erc20({
        value: '0',
        abi: JSON.stringify(_.find(ERC20ABI, (func) => func.name === 'approve')),
        params: JSON.stringify([address, '' + MAX_UINT_AMOUNT]),
      });
      setShowQRCode(returnVal.request_key);
    } else {
      const exit = await erc20().approve(address, MAX_UINT_AMOUNT, mmObject);
      await exit.wait();
    }
  };

  const approveLP = async () => {
    if (currentProviderName === 'kaikas') {
      const returnVal = await erc20LP()
        .methods.approve(currentMarketData.addresses.VE!, MAX_UINT_AMOUNT)
        .send(sendObject);
      await waitForTxn(provider, returnVal.transactionHash);
    } else if (currentProviderName === 'klip') {
      const res = await erc20({
        value: '0',
        abi: JSON.stringify(_.find(ERC20ABI, (func) => func.name === 'approve')),
        params: JSON.stringify([currentMarketData.addresses.VE!, '' + MAX_UINT_AMOUNT]),
      });
      console.log('res', res);
      setShowQRCode(res.request_key);
    } else {
      const exit = await erc20LP().approve(
        currentMarketData.addresses.VE!,
        MAX_UINT_AMOUNT,
        mmObject
      );
      await exit.wait();
    }
  };

  const addLiquidity = async (amountKlap: number, amountKlay: number, slippage: number) => {
    const arg = [
      currentMarketData.addresses.MAIN_TOKEN,
      parseEther(amountKlap.toFixed(8)).toString(),
      parseEther((amountKlap * (1 - 0.001 * slippage)).toFixed(8)).toString(),
      parseEther((amountKlay * (1 - 0.001 * slippage)).toFixed(8)).toString(),
      userId,
      moment().unix() + 10000,
    ];
    console.log('slippage', slippage);
    console.log(arg);
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await router()
          .methods.addLiquidityKLAY(
            currentMarketData.addresses.MAIN_TOKEN,
            parseEther(amountKlap.toFixed(8)).toString(),
            parseEther((amountKlap * (1 - 0.001 * slippage)).toFixed(8)).toString(),
            parseEther((amountKlay * (1 - 0.001 * slippage)).toFixed(8)).toString(),
            userId,
            moment().unix() + 10000
          )
          .send({ ...sendObject, value: parseEther('' + amountKlay) });
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await router({
          value: parseEther('' + amountKlay).toString(),
          abi: JSON.stringify(_.find(RouterABI, (func) => func.name === 'addLiquidityKLAY')),
          params: JSON.stringify(arg),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const exit = await router().addLiquidityKLAY(
          currentMarketData.addresses.MAIN_TOKEN,
          parseEther(amountKlap.toFixed(8)).toString(),
          parseEther((amountKlap * (1 - 0.001 * slippage)).toFixed(8)).toString(),
          parseEther((amountKlay * (1 - 0.001 * slippage)).toFixed(8)).toString(),
          userId,
          moment().unix() + 10000,
          { value: parseEther('' + amountKlap), gasPrice: 250000000000 }
        );
        await exit.wait();
      }
    } catch (e) {
      console.error('lp', e);
    }
  };

  const lockVeKlap = async (amount: number, lock_duration: number) => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await ve()
          .methods.create_lock(parseEther('' + amount), lock_duration * ONE_MONTH)
          .send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await ve({
          value: '0',
          abi: JSON.stringify(_.find(VE_ABI, (func) => func.name === 'create_lock')),
          params: JSON.stringify([
            parseEther('' + amount).toString(),
            '' + lock_duration * ONE_MONTH,
          ]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const exit = await ve().create_lock(
          parseEther('' + amount),
          lock_duration * ONE_MONTH,
          mmObject
        );
        await exit.wait();
      }
    } catch (e) {
      console.error('lp', e);
    }
  };

  const increaseAmount = async (tokenId: string, amount: number) => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await ve()
          .methods.increase_amount(tokenId, parseEther('' + amount))
          .send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await ve({
          value: '0',
          abi: JSON.stringify(_.find(VE_ABI, (func) => func.name === 'increase_amount')),
          params: JSON.stringify([tokenId, parseEther('' + amount).toString()]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const exit = await ve().increase_amount(tokenId, parseEther('' + amount), mmObject);
        await exit.wait();
      }
    } catch (e) {
      console.error('lp', e);
    }
  };

  const increaseTime = async (tokenId: string, lock_duration: number) => {
    try {
      console.log(lock_duration * ONE_MONTH);
      if (currentProviderName === 'kaikas') {
        const returnVal = await ve()
          .methods.increase_unlock_time(tokenId, lock_duration * ONE_MONTH)
          .send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await ve({
          value: '0',
          abi: JSON.stringify(_.find(VE_ABI, (func) => func.name === 'increase_unlock_time')),
          params: JSON.stringify([tokenId, (lock_duration * ONE_MONTH).toString()]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const exit = await ve().increase_unlock_time(tokenId, lock_duration * ONE_MONTH, mmObject);
        await exit.wait();
      }
    } catch (e) {
      console.error('time', e);
    }
  };

  const lockVeLP = async (amount: number, lock_duration: number, slippage?: number) => {
    try {
      console.log('args', 2, slippage || 5, lock_duration * ONE_MONTH);
      console.log('val', amount);
      if (currentProviderName === 'kaikas') {
        const returnVal = await ve()
          .methods.create_lp_lock(parseEther('' + amount), lock_duration * ONE_MONTH, userId)
          .send({ ...sendObject });
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await erc20({
          value: '0',
          abi: JSON.stringify(_.find(VE_ABI, (func) => func.name === 'create_lp_lock')),
          params: JSON.stringify([
            parseEther('' + amount).toString(),
            '' + lock_duration * ONE_MONTH,
            userId,
          ]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const exit = await ve().create_lp_lock(
          parseEther('' + amount),
          lock_duration * ONE_MONTH,
          userId,
          mmObject
        );
        await exit.wait();
      }
    } catch (e) {
      console.error('exit', e);
    }
  };

  const claimFromMasterChef = async () => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await ve()
          .methods.claimFromMasterChef(userId)
          .send({ ...sendObject });
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await erc20({
          value: '0',
          abi: JSON.stringify(_.find(VE_ABI, (func) => func.name === 'claimFromMasterChef')),
          params: JSON.stringify([userId]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const exit = await ve().claimFromMasterChef(userId, mmObject);
        await exit.wait();
      }
    } catch (e) {
      console.error('exit', e);
    }
  };

  return {
    addLiquidity,
    approveLP,
    approve,
    lockVeKlap,
    lockVeLP,
    increaseAmount,
    increaseTime,
    claimFromMasterChef,
  };
};

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .AssetsFilterPanel {
    margin-bottom: 30px;
    display: flex;
    @include respond-to(xl) {
      margin-bottom: 20px;
    }
    @include respond-to(sm) {
      margin-bottom: 30px;
      justify-content: center;
    }

    .AssetsFilterPanel__search-title {
      color: #828282;
      margin-bottom: 7px;
      font-weight: 300;
    }

    &__content {
      .SwitcherContent {
        display: flex;
        flex-direction: column;
        grid-gap: 7px;
        color: #828282;
        font-weight: 300;

        .SwitcherBackground {
          font-weight: normal;
          display: flex;
          flex-direction: row;
          border-radius: 50px;
          grid-gap: 5px;
          background-color: black;
          padding: 4px;

          .SwitcherInput {
            padding: 13px 16px;
            font-size: 16px;
            cursor: pointer;
            color: white;
            border-radius: 50px;
          }

          .SwitcherInput__active {
            cursor: default !important;
            background-color: white !important;
            color: black !important;
          }
        }
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex: 1;
      @include respond-to(sm) {
        justify-content: center;
      }
    }

    &__search-inner {
      @include respond-to(sm) {
        display: none;
      }
    }
  }
`;

export default staticStyles;

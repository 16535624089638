import { AbstractConnectorArguments, ConnectorUpdate } from '@web3-react/types';
import { AbstractConnector } from '@web3-react/abstract-connector';
import warning from 'tiny-warning';

export type SendReturnResult = { result: any };
export type SendReturn = any;

export type Send = (method: string, params?: any[]) => Promise<SendReturnResult | SendReturn>;
export type SendOld = ({ method }: { method: string }) => Promise<SendReturnResult | SendReturn>;

function parseSendReturn(sendReturn: SendReturnResult | SendReturn): any {
  return sendReturn.hasOwnProperty('result') ? sendReturn.result : sendReturn;
}

export class NoEthereumProviderError extends Error {
  public constructor() {
    super();
    this.name = this.constructor.name;
    this.message = 'No Ethereum provider was found on window.ethereum.';
  }
}

export class UserRejectedRequestError extends Error {
  public constructor() {
    super();
    this.name = this.constructor.name;
    this.message = 'The user rejected the request.';
  }
}

export class KaikasConnector extends AbstractConnector {
  constructor(kwargs: AbstractConnectorArguments) {
    super(kwargs);

    this.handleNetworkChanged = this.handleNetworkChanged.bind(this);
    this.handleChainChanged = this.handleChainChanged.bind(this);
    this.handleAccountsChanged = this.handleAccountsChanged.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  private handleChainChanged(chainId: string | number): void {
    this.emitUpdate({ chainId, provider: (window as any).klaytn });
  }

  private handleAccountsChanged(accounts: string[]): void {
    if (accounts.length === 0) {
      this.emitDeactivate();
    } else {
      this.emitUpdate({ account: accounts[0] });
    }
  }

  private handleClose(code: number, reason: string): void {
    this.emitDeactivate();
  }

  private handleNetworkChanged(networkId: string | number): void {
    this.emitUpdate({ chainId: networkId, provider: (window as any).klaytn });
  }

  public async activate(): Promise<ConnectorUpdate> {
    if (!(window as any).klaytn) {
      throw new NoEthereumProviderError();
    }

    if ((window as any).klaytn.on) {
      (window as any).klaytn.on('chainChanged', this.handleChainChanged);
      (window as any).klaytn.on('accountsChanged', this.handleAccountsChanged);
      (window as any).klaytn.on('close', this.handleClose);
      (window as any).klaytn.on('networkChanged', this.handleNetworkChanged);
    }

    if (((window as any).klaytn as any).isKaikas) {
      ((window as any).klaytn as any).autoRefreshOnNetworkChange = true;
    }

    // try to activate + get account via eth_requestAccounts
    let account;
    /*try {
      account = await ((window as any).klaytn.send as Send)('klaytn_requestAccounts').then(
        (sendReturn) => parseSendReturn(sendReturn)[0]
      );
    } catch (error) {
      console.log('heres error');
      if ((error as any).code === 4001) {
        throw new UserRejectedRequestError();
      }
      warning(false, 'eth_requestAccounts was unsuccessful, falling back to enable');
    }*/

    // if unsuccessful, try enable
    if (!account) {
      // if enable is successful but doesn't return accounts, fall back to getAccount (not happy i have to do this...)
      account = await (window as any).klaytn
        .enable()
        .then((sendReturn: any) => sendReturn && parseSendReturn(sendReturn)[0]);
      console.log('this works');
      console.log(account);
    }

    return { provider: (window as any).klaytn, ...(account ? { account } : {}) };
  }

  public async getProvider(): Promise<any> {
    return (window as any).klaytn;
  }

  public async getChainId(): Promise<number | string> {
    if (!(window as any).klaytn) {
      throw new NoEthereumProviderError();
    }

    let chainId;
    if (!chainId) {
      chainId =
        ((window as any).klaytn as any).chainId ||
        ((window as any).klaytn as any).netVersion ||
        ((window as any).klaytn as any).networkVersion ||
        ((window as any).klaytn as any)._chainId;
    }
    return chainId;
  }

  public async getAccount(): Promise<null | string> {
    if (!(window as any).klaytn) {
      throw new NoEthereumProviderError();
    }

    let account;
    try {
      account = await (window as any)
        .klaytn('selectedAddress')
        .then((sendReturn: any) => parseSendReturn(sendReturn)[0]);
    } catch {
      warning(false, 'eth_accounts was unsuccessful, falling back to enable');
    }

    if (!account) {
      try {
        account = await (window as any).klaytn
          .enable()
          .then((sendReturn: any) => parseSendReturn(sendReturn)[0]);
      } catch {
        warning(false, 'enable was unsuccessful, falling back to eth_accounts v2');
      }
    }

    if (!account) {
      account = parseSendReturn((window as any).klaytn({ method: 'selectedAddress' }));
    }

    return account;
  }

  public deactivate() {
    if ((window as any).klaytn && (window as any).klaytn.removeListener) {
      (window as any).klaytn.removeListener('chainChanged', this.handleChainChanged);
      (window as any).klaytn.removeListener('accountsChanged', this.handleAccountsChanged);
      (window as any).klaytn.removeListener('close', this.handleClose);
      (window as any).klaytn.removeListener('networkChanged', this.handleNetworkChanged);
    }
  }

  public async isAuthorized(): Promise<boolean> {
    if (!(window as any).klaytn) {
      return false;
    }

    try {
      return await ((window as any).klaytn.send as Send)('selectedAddress').then((sendReturn) => {
        if (parseSendReturn(sendReturn).length > 0) {
          return true;
        } else {
          return false;
        }
      });
    } catch {
      return false;
    }
  }
}

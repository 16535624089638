import { useWeb3React } from '@web3-react/core';
import { providers, Contract } from 'ethers';
import { getProvider } from '../../../../helpers/config/markets-and-network-config';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import CheeksABI from '../../../../contracts/Cheeks.json';
import { useUserWalletDataContext } from '../../../../libs/web3-data-provider';
import Caver, { AbiItem } from 'caver-js';
import waitForTxn from '../../../../helpers/wait-for-txn';
import _ from 'lodash';
import { useKlipContext } from '../../../../components/wrappers/ScreensWrapper';
// @ts-ignore
import { prepare } from 'klip-sdk';

export const useServices = () => {
  const setShowQRCode = useKlipContext();
  const { currentAccount: userId, currentProviderName } = useUserWalletDataContext();
  const { currentMarketData, chainId } = useProtocolDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const sendObject = {
    from: (window as any).klaytn ? (window as any).klaytn?.selectedAddress : null,
    gas: 1800000,
  };
  const mmObject = { gasPrice: 250000000000 };

  const cheeks = (transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(
        CheeksABI as AbiItem[],
        currentMarketData.addresses.CHEEKS!
      ) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.CHEEKS!,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    return new Contract(currentMarketData.addresses.CHEEKS!, CheeksABI, topHolderSigner);
  };

  const mint = async (setError: (type: string) => void) => {
    if (currentProviderName === 'kaikas') {
      try {
        const returnVal = await cheeks().methods.mint().send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } catch (e) {
        setError(
          'There was a problem minting Cheek tokens. Please contact a team member to help you.'
        );
      }
    } else if (currentProviderName === 'klip') {
      const returnVal = await cheeks({
        value: '0',
        abi: JSON.stringify(_.find(CheeksABI, (func) => func.name === 'mint')),
        params: JSON.stringify([]),
      });
      setShowQRCode(returnVal.request_key);
    } else {
      try {
        const exit = await cheeks().mint(mmObject);
        await exit.wait();
      } catch (e) {
        console.error(e);
        if (e.data.message.includes('Already minted')) {
          setError('You may only mint Cheek Tokens once.');
        } else if (e.data.message.includes('Another user claimed recently')) {
          setError('Please wait a couple seconds and try agian.');
        } else if (e.data.message.includes('Mint turned off')) {
          setError('Minting Cheek tokens is disabled right now. Please try again later.');
        } else {
          setError(
            'There was a problem minting Cheek tokens. Please contact a team member to help you.'
          );
        }
      }
    }
  };

  return {
    mint,
  };
};

//import { MAX_UINT_AMOUNT } from '@aave/protocol-js';
import { useWeb3React } from '@web3-react/core';
import { providers, Contract } from 'ethers';
import { parseEther } from 'ethers/lib/utils';
import { getProvider } from '../../helpers/config/markets-and-network-config';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import MultiFeeABI from '../../contracts/MultiFeeDistribution.json';
//import ERC20ABI from '../../contracts/ERC20.json';
import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import Caver, { AbiItem } from 'caver-js';
import waitForTxn from '../../helpers/wait-for-txn';
import { useKlipContext } from '../../components/wrappers/ScreensWrapper';
// @ts-ignore
import { prepare } from 'klip-sdk';
import _ from 'lodash';

export const ONE_MONTH = 2630000;
export const ONE_WEEK = 604800;

export const useServices = () => {
  const setShowQRCode = useKlipContext();
  const { currentAccount: userId, currentProviderName } = useUserWalletDataContext();
  const { currentMarketData, chainId } = useProtocolDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const sendObject = {
    from: (window as any).klaytn ? (window as any).klaytn?.selectedAddress : null,
    gas: 1800000,
  };
  const mmObject = { gasPrice: 250000000000 };
  const multiFee = (transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(
        MultiFeeABI as AbiItem[],
        currentMarketData.addresses.MULTIFEE_DISTRIBUTION!
      ) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.MULTIFEE_DISTRIBUTION!,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    return new Contract(
      currentMarketData.addresses.MULTIFEE_DISTRIBUTION!,
      MultiFeeABI,
      topHolderSigner
    );
  };

  /*const approveTokens = async () => {
    console.log((provider!.provider as any).isKaikas);

    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    let contract;
    if (isKaikas) {
      console.log('here');
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      contract = new caver.klay.Contract(
        ERC20ABI as AbiItem[],
        currentMarketData.addresses.MAIN_TOKEN!
      );
      try {
        const staking = await contract.methods
          .approve(currentMarketData.addresses.MULTIFEE_DISTRIBUTION!, MAX_UINT_AMOUNT)
          .send(sendObject);
        console.log(staking);
        await waitForTxn(provider, staking.transactionHash);
      } catch (e) {
        console.log('e', e);
      }
      return;
    } else {
      contract = new Contract(currentMarketData.addresses.MAIN_TOKEN!, ERC20ABI, topHolderSigner);
    }

    try {
      const staking = await contract.approve(
        currentMarketData.addresses.MULTIFEE_DISTRIBUTION!,
        MAX_UINT_AMOUNT
      );
      console.log(staking);
      await staking.wait();
    } catch (e) {
      console.log('e', e);
    }
  };

  const stakeTokens = async (amount: number, isLocked: boolean) => {
    try {
      if (isKaikas) {
        const staking = await stakingService()
          .methods.stake(parseEther('' + amount), isLocked)
          .send(sendObject);
        await waitForTxn(provider, staking.transactionHash);
      } else {
        const staking = await stakingService().stake(parseEther('' + amount), isLocked);
        await staking.wait();
      }
    } catch (e) {
      console.error('e', e);
    }
  };*/

  const claimVeNFT = async (tokenId: string) => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await multiFee().methods.claimVeNFT(tokenId).send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await multiFee({
          value: '0',
          abi: JSON.stringify(_.find(MultiFeeABI, (func) => func.name === 'claimVeNFT')),
          params: JSON.stringify([tokenId]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const withdraw = await multiFee().claimVeNFT(tokenId, mmObject);
        await withdraw.wait();
      }
    } catch (e) {}
  };

  const exitEarly = async (amount: number) => {
    amount = amount - 0.0001;
    console.log('amount', amount);
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await multiFee()
          .methods.exit(parseEther('' + amount).div(2))
          .send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await multiFee({
          value: '0',
          abi: JSON.stringify(_.find(MultiFeeABI, (func) => func.name === 'exit')),
          params: JSON.stringify([
            parseEther('' + amount)
              .div(2)
              .toString(),
          ]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const exit = await multiFee().exit(parseEther('' + amount).div(2), mmObject);
        await exit.wait();
      }
    } catch (e) {}
  };

  const exitVeKlap = async (amount: number, lock_duration: number) => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await multiFee()
          .methods.exitForVe(0, 0, lock_duration * ONE_MONTH, parseEther('' + amount))
          .send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await multiFee({
          value: '0',
          abi: JSON.stringify(_.find(MultiFeeABI, (func) => func.name === 'exitForVe')),
          params: JSON.stringify([
            '0',
            '0',
            '' + lock_duration * ONE_MONTH,
            parseEther('' + amount).toString(),
          ]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const exit = await multiFee().exitForVe(
          0,
          0,
          lock_duration * ONE_MONTH,
          parseEther('' + amount),
          mmObject
        );
        await exit.wait();
      }
    } catch (e) {}
  };

  const exitByLP = async (
    amountKlap: number,
    amountKlay: number,
    lock_duration: number,
    slippage?: number
  ) => {
    try {
      console.log(
        'args',
        2,
        slippage || 5,
        lock_duration * ONE_MONTH,
        parseEther('' + (amountKlap - 0.0001)).toString()
      );
      console.log('val', amountKlay);
      if (currentProviderName === 'kaikas') {
        const returnVal = await multiFee()
          .methods.exitForVe(
            1,
            slippage || 5,
            lock_duration * ONE_MONTH,
            parseEther('' + amountKlap)
          )
          .send({ ...sendObject, value: parseEther(amountKlay.toFixed(8)) });
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await multiFee({
          value: parseEther(amountKlay.toFixed(8)).toString(),
          abi: JSON.stringify(_.find(MultiFeeABI, (func) => func.name === 'exitForVe')),
          params: JSON.stringify([
            '1',
            '' + slippage || '5',
            '' + lock_duration * ONE_MONTH,
            parseEther('' + amountKlay).toString(),
          ]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const exit = await multiFee().exitForVe(
          1,
          slippage || 5,
          lock_duration * ONE_MONTH,
          parseEther('' + amountKlap),
          { ...mmObject, value: parseEther('' + amountKlay) }
        );
        await exit.wait();
      }
    } catch (e) {
      console.error('exit', e);
    }
  };

  return {
    exitVeKlap,
    exitByLP,
    exitEarly,
    claimVeNFT,
  };
};

import React from 'react';
import classNames from 'classnames';

import staticStyles from '../ConnectButton/style';

interface ButtonProps {
  name: string;
  size?: 'big' | 'medium' | 'normal' | 'small';
  onClick?: (event: any) => void;
  style?: any;
  className?: string;
  type?: any;
  disabled?: boolean;
  inside?: any;
  icon?: any;
}

const Button = ({
  type,
  size = 'normal',
  name,
  onClick,
  style,
  className,
  disabled,
  inside,
  icon,
}: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={classNames('ConnectButton', `ConnectButton__${size}`, className)}
    >
      <div style={{ ...style }} className="ConnectButton__inner">
        {inside ? (
          inside
        ) : (
          <span>
            {name}
            {icon ? icon : null}
          </span>
        )}
      </div>

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .ConnectButton {
          &:hover {
            .ConnectButton__inner {
              opacity: 0.7;
            }
          }

          &__inner {
            width: fit-content;
            color: #000;
            height: 50px;
          }
          border: 2px solid #000;
          border-radius: 24px;
        }

        .ConnectButton__normal,
        .ConnectButton__medium {
          &:hover {
            .ConnectButton__inner {
              opacity: 0.7;
            }
          }
        }
      `}</style>
    </button>
  );
};

export default Button;

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .VestAllButton {
    height: 40px;
    .ConnectButton__inner {
      height: 37px !important;
      min-height: 30px !important;
    }
  }

  .TopTVLCard {
    background: #f2f5f9;
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .TVLContainer {
    margin-bottom: 16px;
  }

  .Markets {
    display: flex;
    flex-direction: column !important;
    flex: 1;

    &__searchbar-container {
      padding-top: 16px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding-right: 107px;
      @include respond-to(xl) {
        padding-right: 5.7%;
      }
      @include respond-to(md) {
        padding-right: 0px;
        padding-bottom: 16px;
        padding-top: 16px;
      }
    }

    &__top-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      grid-gap: 16px;
      @include respond-to(md) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 15px;
        grid-gap: 0px;
      }
    }

    &__size,
    &__market-switcher {
      display: none;
      @include respond-to(sm) {
        margin-top: 15px;
        display: block;
      }
    }

    &__marketSwitcher--title {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      font-size: $regular;
    }

    &__mobile--cards {
      display: none;
      @include respond-to(sm) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }

    &__help--modalInner {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .Markets__help--modal {
      .TextWithModal__text {
        font-size: $medium;
      }
    }

    &__price-switcher {
      margin-top: 30px;
      @include respond-to(xl) {
        margin-top: 20px;
      }
      @include respond-to(sm) {
        display: none;
      }
    }
  }
`;

export default staticStyles;

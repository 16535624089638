import React, { useEffect, useState } from 'react';
import { CardInnerContainer } from '../../manage/components/styles';
import Preloader from '../../../components/basic/Preloader';
import DefaultButton from '../../../components/basic/DefaultButton';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import messages from './messages';
import messagesCore from '../messages';
import Logo from '../../../components/basic/Logo';
import moment from 'moment';
import AmountField from '../../../components/fields/AmountField';
import { useStaticPoolDataContext } from '../../../libs/pool-data-provider';
import { useGetLockdropInfo } from '../hooks';
import { useServices } from '../functions';
import { formatEther } from 'ethers/lib/utils';
import Link from '../../../components/basic/Link';
import { providers } from 'ethers';
import { useWeb3React } from '@web3-react/core';

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

const CardInnerContainerStyled = styled(CardInnerContainer)`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  padding: 16px;
`;

const StyledAmountField = styled(AmountField)`
  .BasicField input {
    color: #000 !important;
  }
`;

const LockdropModal = () => {
  const { data } = useGetLockdropInfo();
  const { userId } = useStaticPoolDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const { deposit } = useServices();
  const [amount, setAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState(0);
  const [showError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const getBalance = async () => {
      console.log('userid', userId);
      const balance = await provider!.getBalance(userId!);
      setMaxAmount(Number(formatEther(balance)));
    };
    if (userId && !isLoading) {
      getBalance();
    }
  }, [userId]);
  return (
    <div className="LockdropModal">
      <h1 className="LockdropModal__title">
        <Logo /> <b>{intl.formatMessage(messages.lockdropTitle)}</b>
      </h1>
      <div className="LockdropModal__topMessage">
        {intl.formatMessage(messages.lockdropMessage)}
        <Link
          absolute
          inNewWindow
          className="LockdropModal__readMore"
          to={intl.formatMessage(messages.mediumLink)}
        >
          {intl.formatMessage(messages.readMore)}
        </Link>
      </div>
      <div className="LockdropModal__aggregateContainer">
        <div className="LockdropModal__indivStatContainer">
          <div className="LockdropModal__stat" style={{ color: '#fff' }}>
            $
            {(Number(data.totalDeposits) * data.klayPrice).toLocaleString('en-us', {
              maximumFractionDigits: 2,
            })}
          </div>
          <div className="LockdropModal__statTitle">
            {intl.formatMessage(messages.aggregateTVL)}
          </div>
        </div>
      </div>
      <div className="LockdropModal__statsContainer">
        <div className="LockdropModal__indivStatContainer">
          <div className="LockdropModal__stat" style={{ color: '#00CC88' }}>
            {data.klapRewards}{' '}
            <div className="LockdropModal__stat" style={{ marginLeft: 4, color: '#fff' }}>
              IN KLAP
            </div>
          </div>
          <div className="LockdropModal__statTitle">{intl.formatMessage(messages.toBeEarned)}</div>
        </div>
        <div className="LockdropModal__indivStatContainer">
          <div className="LockdropModal__stat" style={{ color: '#FF3366' }}>
            {moment(data.expirationDate * 1000).fromNow()}
          </div>
          <div className="LockdropModal__statTitle">{intl.formatMessage(messages.eventEndsIn)}</div>
        </div>
        <div className="LockdropModal__indivStatContainer">
          <div className="LockdropModal__stat" style={{ color: '#fff' }}>
            {Number(data.totalDeposits).toLocaleString('en-us', {
              maximumFractionDigits: 2,
            })}
          </div>
          <div className="LockdropModal__statTitle">{intl.formatMessage(messages.tvl)}</div>
        </div>
      </div>
      <OuterContainer>
        <CardInnerContainerStyled>
          <div className="LockdropModal__questionContainer">
            <p className="LockdropModal__question">
              {intl.formatMessage(messages.amountStakeQuestion)}
            </p>
            <StyledAmountField
              title={intl.formatMessage(messages.availableDeposit)}
              maxAmount={maxAmount}
              symbol={'KLAY'}
              maxDecimals={4}
              value={'' + amount}
              onChange={(amount) => setAmount(amount)}
              onMaxButtonClick={() => setAmount('' + maxAmount)}
              style={{
                margin: 0,
                marginBottom: !showError ? 25 : 3,
              }}
            />
            <p className="LockdropModal__depositText" style={{ marginBottom: showError ? 0 : 20 }}>
              {intl.formatMessage(messages.klapCalculation, {
                input: Number(amount),
              })}
              {5000000 * (Number(amount) / (Number(data.totalDeposits) + Number(amount)))} KLAP.
            </p>
            {showError && (
              <p
                className="LockdropModal__depositText"
                style={{ color: 'red', marginRight: 3, marginBottom: 20, maxWidth: 650 }}
              >
                {intl.formatMessage(messages.errorMessage)}
              </p>
            )}
          </div>
          {isLoading ? (
            <div style={{ maxWidth: 80 }}>
              <Preloader smallSize />
            </div>
          ) : (
            <DefaultButton
              onClick={async () => {
                setIsLoading(true);
                await deposit(Number(amount));
                setIsLoading(false);
              }}
              disabled={
                Number(amount) > maxAmount ||
                moment().unix() > data.expirationDate ||
                Number(amount) === 0
              }
              title={intl.formatMessage(messagesCore.depositButton)}
              color="green"
            />
          )}
        </CardInnerContainerStyled>
      </OuterContainer>
      <style jsx={true} global={true}>{`
        .LockdropModal {
          min-width: 560px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      `}</style>
    </div>
  );
};

export default LockdropModal;

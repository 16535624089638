import { useEffect, useState } from 'react';
import Preloader from '../../../components/basic/Preloader';
import { CardContainer } from '../../../components/CardContainer';
import Logo from '../../../components/basic/Logo';
import DefaultButton from '../../../components/basic/DefaultButton';
import {
  TitleCard,
  APRContainer,
  DescriptionTitle,
  Description,
  ListContainer,
  StyledAmountField,
  CardInnerContainer,
} from './styles';
import MonthButtons from './MonthButtons';
import ImageLogo from '../../../images/klapLogo.svg';
import KlayLogo from '../../../images/klay.png';
import styled from 'styled-components';
import messages from '../messages';
import { useIntl } from 'react-intl';
import SlippageButtons from '../../ve/components/SlippageButtons';
import messagesLocal from '../../ve/messages';
import TooltipCoinBreakdown from '../../../components/TooltipCoinBreakdown';
import { BigNumber } from 'ethers';
import { useMultiCall } from '../../../libs/pool-data-provider/hooks/use-multicall';
import VeABI from '../../../contracts/Ve.json';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { ONE_MONTH } from '../functions';
import { useGetManageInfo } from '../hooks';
import MasterchefUIHelper from '../../../contracts/MasterChefUIHelper.json';
import { useStaticPoolDataContext } from '../../../libs/pool-data-provider';

interface ProvideLiquidityProps {
  apr: { base: number; klay: number; boosted: number | string }[];
  onProvideLiquidity: (
    amountKlap: number,
    amountKlay: number,
    lock_duration: number,
    slippage: number
  ) => void;
  ratioKlapToKlay: number;
  maxKlap: number;
  maxKlay: number;
  isLoading: boolean;
  userFactor: BigNumber;
  sumOfFactor: BigNumber;
}

export const MonthContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;

  @media only screen and (max-width: 450px) {
    min-width: 0px;
    max-width: 100%;
    width: 100%;
  }
`;

const CurrencyContainer = styled.div`
  padding: 12px 16px;
  border: 1px solid #000000;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
  height: 52px;
  justify-content: center;
  align-items: center;
`;

const CurrencyContainerOutside = styled.div`
  @media only screen and (max-width: 767px) {
    display: none;
  }
`;

export const InputFieldCard = ({
  amount,
  max,
  setAmount,
  amountTitle,
  source,
}: {
  amount: string;
  max: string;
  setAmount: (amount: string) => void;
  amountTitle: string;
  source?: any;
}) => {
  const intl = useIntl();
  return (
    <div style={{ display: 'flex', flexDirection: 'row', gridGap: 32, maxWidth: '100%' }}>
      <CurrencyContainerOutside>
        <p style={{ marginBottom: 8 }}>{intl.formatMessage(messages.currency)}</p>
        <CurrencyContainer style={{ width: source ? 'auto' : '160px' }}>
          {source}
          <p>
            <b>{amountTitle}</b>
          </p>
        </CurrencyContainer>
      </CurrencyContainerOutside>
      <div style={{ maxWidth: '100%' }}>
        <p style={{ marginBottom: 8 }}>{intl.formatMessage(messages.amount)}</p>
        <StyledAmountField
          title={intl.formatMessage(messages.available)}
          maxAmount={max}
          symbol={''}
          maxDecimals={4}
          value={'' + amount}
          onChange={(amount) => setAmount(amount)}
          onMaxButtonClick={() => setAmount(max)}
          style={{ maxWidth: '75%', margin: 0 }}
        />
      </div>
    </div>
  );
};

const ProvideLiquidityCard = (props: ProvideLiquidityProps) => {
  const { currentMarketData } = useProtocolDataContext();
  const { userId } = useStaticPoolDataContext();
  const { data, refresh } = useGetManageInfo();
  const [klapAmount, setKlapAmount] = useState('');
  const [klayAmount, setKlayAmount] = useState('');
  const [selectedButton, setSelectedButton] = useState(3);
  const [apr, setApr] = useState(0);
  const [slippageButton, setSlippageButton] = useState(0.1);
  const [boostedApr, setBoostedApr] = useState('0');
  const intl = useIntl();
  const masterchefUiHelper = useMultiCall([MasterchefUIHelper]);

  useEffect(() => {
    const search = async () => {
      if (!userId || data.baseDialutingAmount.eq(0)) {
        setBoostedApr('0');
        return;
      }
      console.log('data', data);
      console.log('got here', userId);
      const lpAmount =
        klapAmount == '' ? 0 : parseEther('' + Math.sqrt(Number(klapAmount) * Number(klayAmount)));
      const [[[userFactor, sumFactor]]] = await masterchefUiHelper(
        currentMarketData.addresses.MASTERCHEF_UI_HELPER!,
        [['getUpdatedFactor']],
        [[[userId, lpAmount, selectedButton * ONE_MONTH]]]
      );
      console.log('lp amount', lpAmount.toString());
      console.log('time', selectedButton * ONE_MONTH);
      console.log('before user factor', data.userFactor.toString());
      console.log('after user factor', BigNumber.from(userFactor).toString());
      console.log('before sum factor', data.sumOfFactor.toString());
      console.log('after sum factor', BigNumber.from(sumFactor).toString());
      const finalApr =
        (Number(formatEther(data.baseDialutingAmount)) *
          (BigNumber.from(userFactor).mul(1000000).div(BigNumber.from(sumFactor)).toNumber() /
            1000000)) /
        ((Number(formatEther(data.userAndVeDepositedAmount)) + Number(formatEther(lpAmount))) *
          data.lpPrice);
      console.log('final apr', finalApr);
      setBoostedApr('' + finalApr);
    };
    const timer = setTimeout(() => {
      search();
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [klayAmount, selectedButton, data]);

  return (
    <CardContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: 20,
        padding: '16px 24px 32px 24px',
        alignItems: 'center',
      }}
    >
      <TitleCard>
        {intl.formatMessage(messages.provideLiquidity)} <Logo />
      </TitleCard>
      <APRContainer style={{ alignItems: 'center' }}>
        <p
          style={{
            fontWeight: 'bold',
            fontSize: 21,
            color: '#929AA5',
            display: 'flex',
            gridGap: 8,
          }}
        >
          APR
          <p style={{ color: '#00CC88' }}>
            {props.apr[apr] && !isNaN(props.apr[apr].base) && !isNaN(props.apr[apr].klay)
              ? boostedApr == '0'
                ? (
                    Number(props.apr[apr].base) * 100 +
                    Number(props.apr[apr].klay) * 100
                  ).toLocaleString('en-us', { maximumFractionDigits: 2 })
                : (
                    Number(boostedApr) * 100 +
                    Number(props.apr[apr].base) * 100 +
                    Number(props.apr[apr].klay) * 100
                  ).toLocaleString('en-us', { maximumFractionDigits: 2 })
              : 0}
            %
          </p>
        </p>
        {props.apr[apr] && !isNaN(props.apr[apr].base) && !isNaN(props.apr[apr].klay) ? (
          <TooltipCoinBreakdown
            boostedApr={boostedApr}
            baseApr={props.apr[apr].base}
            klayApr={props.apr[apr].klay}
            showTypingIndicator={boostedApr == '0'}
          />
        ) : null}
      </APRContainer>
      <ListContainer>
        <DescriptionTitle style={{ fontWeight: 'normal' }}>
          {intl.formatMessage(messages.pairKlap)}
        </DescriptionTitle>
        <DescriptionTitle>{intl.formatMessage(messages.benefits)}</DescriptionTitle>
        <ul style={{ width: '100%' }}>
          <Description>{intl.formatMessage(messages.benefitsProvideLiquidity1)}</Description>
          <Description>{intl.formatMessage(messages.benefitsProvideLiquidity2)}</Description>
          <Description>{intl.formatMessage(messages.benefitsProvideLiquidity3)}</Description>
          <Description>{intl.formatMessage(messages.benefitsProvideLiquidity4)}</Description>
        </ul>
      </ListContainer>
      <CardInnerContainer>
        <InputFieldCard
          source={<img style={{ height: 20 }} src={ImageLogo} alt={`Currency Logo KLAP`} />}
          amountTitle="KLAP"
          amount={klapAmount}
          max={'' + props.maxKlap}
          setAmount={(amount) => {
            setKlapAmount(amount);
            setKlayAmount('' + parseFloat(amount) * props.ratioKlapToKlay);
          }}
        />
        <div style={{ height: 24 }} />
        <InputFieldCard
          source={<img style={{ height: 16 }} src={KlayLogo} alt={`Currency Logo KLAY`} />}
          amountTitle="KLAY"
          amount={klayAmount}
          max={'' + props.maxKlay}
          setAmount={(amount) => {
            setKlayAmount(amount);
            setKlapAmount('' + parseFloat(amount) / props.ratioKlapToKlay);
          }}
        />
      </CardInnerContainer>
      <div style={{ width: '100%' }}>
        <p style={{ marginBottom: 8 }}>{intl.formatMessage(messages.lengthQuestionMain)}</p>
        <MonthContainer>
          <MonthButtons
            selectedButton={selectedButton}
            setSelectedButton={(num) => {
              setSelectedButton(num);
              switch (num) {
                case 3:
                  setApr(0);
                  break;
                case 6:
                  setApr(1);
                  break;
                case 12:
                  setApr(2);
                  break;
                default:
                  setApr(3);
                  break;
              }
            }}
          />
        </MonthContainer>
        <p style={{ marginBottom: 8, marginTop: 20 }}>
          {intl.formatMessage(messagesLocal.slippageTolerance)}
        </p>
        <MonthContainer>
          <SlippageButtons selectedButton={slippageButton} setSelectedButton={setSlippageButton} />
        </MonthContainer>
      </div>
      {props.isLoading ? (
        <div style={{ maxWidth: 80 }}>
          <Preloader smallSize />
        </div>
      ) : (
        <DefaultButton
          disabled={
            parseFloat(klapAmount) > props.maxKlap ||
            parseFloat(klayAmount) > props.maxKlay ||
            klapAmount.length === 0 ||
            parseFloat(klapAmount) === 0 ||
            klayAmount.length === 0 ||
            parseFloat(klayAmount) === 0
          }
          onClick={() =>
            props.onProvideLiquidity(
              Number(klapAmount),
              Number(klayAmount),
              selectedButton,
              slippageButton * 10
            )
          }
          title={intl.formatMessage(messages.provideLiquidityButton)}
          color="green"
        />
      )}
    </CardContainer>
  );
};

export default ProvideLiquidityCard;

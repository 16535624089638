import React, { ReactNode } from 'react';

import BasicTable from '../../../components/BasicTable';
import TableColumn from '../../../components/BasicTable/TableColumn';
import TableHeaderButton from '../../../components/BasicTable/TableHeaderButton';
import { useIntl } from 'react-intl';
import messages from '../messages';

import staticStyles from './style';

interface MarketTableProps {
  sortName: string;
  setSortName: (value: string) => void;
  sortDesc: boolean;
  setSortDesc: (value: boolean) => void;
  children: ReactNode;
}

export default function VoteTable({
  sortName,
  setSortName,
  sortDesc,
  setSortDesc,
  children,
}: MarketTableProps) {
  const intl = useIntl();

  const columns = [
    {
      title: intl.formatMessage(messages.assets),
      sortKey: 'currencySymbol',
    },
    {
      title: intl.formatMessage(messages.emissions),
      sortKey: 'emissions',
    },
    {
      title: intl.formatMessage(messages.apr),
      sortKey: 'apr',
    },
    {
      title: intl.formatMessage(messages.newEmissions),
      sortKey: 'newEmissions',
    },
    {
      title: intl.formatMessage(messages.newAPR),
      sortKey: 'newAPR',
    },
  ];

  return (
    <BasicTable
      className="VoteTable"
      headerColumns={
        <React.Fragment>
          {columns.map((column, index) => (
            <TableColumn className="VoteTable__header-column" key={index}>
              <TableHeaderButton
                sortName={sortName}
                sortDesc={sortDesc}
                setSortName={setSortName}
                setSortDesc={setSortDesc}
                sortKey={column.sortKey}
                withSorting={true}
                title={column.title}
                size="small"
              />
            </TableColumn>
          ))}
          <TableColumn className="VoteTable__header-column" key={'vote'}>
            <TableHeaderButton
              sortName={sortName}
              sortDesc={sortDesc}
              setSortName={setSortName}
              setSortDesc={setSortDesc}
              sortKey={''}
              withSorting={true}
              title={intl.formatMessage(messages.yourVote)}
              size="small"
            />
          </TableColumn>
        </React.Fragment>
      }
    >
      {children}

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </BasicTable>
  );
}

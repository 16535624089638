import React from 'react';
import { useIntl } from 'react-intl';

import { useThemeContext } from '@aave/aave-ui-kit';
import Link from '../../basic/Link';

import messages from './messages';
import staticStyles from './style';

import linkIcon from '../../../images/purporeLinkIcon.svg';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import Button from '../../Button';
import { shortenAddress } from '../../../ui-config/networks';
import { useUserWalletDataContext } from '../../../libs/web3-data-provider';

interface TxBottomStatusLineProps {
  title: string;
  confirmed?: boolean;
  submitted?: boolean;
  failed?: boolean;
  error?: boolean;
  txHash?: string;
}

export default function TxBottomStatusLine({
  title,
  confirmed,
  submitted,
  failed,
  error,
  txHash,
}: TxBottomStatusLineProps) {
  const intl = useIntl();
  const { currentProviderName } = useUserWalletDataContext();
  const { currentTheme } = useThemeContext();
  const { networkConfig } = useProtocolDataContext();

  const txReceiptUrl = txHash && networkConfig.explorerLinkBuilder({ tx: txHash });

  return (
    <div className="TxBottomStatusLine">
      <p className="TxBottomStatusLine__title">
        {confirmed
          ? intl.formatMessage(messages.congrats)
          : submitted
          ? `${intl.formatMessage(messages.txId)} ${shortenAddress(txHash!)}`
          : intl.formatMessage(messages.error)}
      </p>
      <div className="TxBottomStatusLine__container">
        {confirmed && (
          <Link className="TxBottomStatusLine__link" to={'/dashboard'} absolute={true} color="dark">
            <Button name={`${intl.formatMessage(messages.dashboard)}`} />
          </Link>
        )}
        {currentProviderName !== 'klip' ? (
          <Link
            className="TxBottomStatusLine__link"
            to={txReceiptUrl!}
            absolute={true}
            inNewWindow={true}
            color="dark"
          >
            <Button
              name={`${intl.formatMessage(messages.explorer)}`}
              icon={<img src={linkIcon} width={12} height={12} alt="" />}
            />
          </Link>
        ) : null}
      </div>
      {/*<p className="TxBottomStatusLine__title">{title}</p>

      <div className="TxBottomStatusLine__status-inner">
        <DotStatus confirmed={confirmed} submitted={submitted} failed={failed} error={error} />
      </div>

      <div className="TxBottomStatusLine__linkInner">
        {!!txReceiptUrl && (
          <Link
            className="TxBottomStatusLine__link"
            to={txReceiptUrl}
            absolute={true}
            inNewWindow={true}
            color="dark"
          >
            <span>{intl.formatMessage(messages.explorer)}</span>
            <img src={linkIcon} width={12} height={12} alt="" />
          </Link>
        )}
        </div>*/}
      <p></p>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .TxBottomStatusLine {
          &__title {
            color: #76808f;
            font-size: 16px !important;
          }
          color: ${currentTheme.textDarkBlue.hex};
          &:after {
            background: ${currentTheme.textDarkBlue.hex};
          }
        }
      `}</style>
    </div>
  );
}

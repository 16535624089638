import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .PriceButton__tooltip {
    border-radius: 16px;
  }

  .PriceButton {
    position: relative;

    &:disabled {
      &:hover,
      &:active {
        transform: scale(1) !important;
      }
    }

    &:hover {
      &:before,
      &:after {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    &:active {
      transform: scale(0.98);
    }

    &:after,
    &:before {
      content: '';
      position: absolute;
      border-radius: $borderRadius;
      transition: all 0.3s ease;
      background-size: 300% !important;
      animation: animate 4.5s infinite;
    }

    &:before,
    &:after {
      top: -2px;
      bottom: -2px;
      left: -2px;
      right: -2px;
    }

    &:before {
      filter: blur(2px);
    }

    &__inner {
      padding: 0px 16px;
      width: fit-content;
      min-height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      border-radius: 24px;
      position: relative;
      z-index: 2;
      @include respond-to(sm) {
        min-height: 54px;
        padding: 0 10px;
      }
      span {
        font-weight: 500;
        font-size: $medium;
        @include respond-to(sm) {
          font-size: $regular;
        }
      }
    }
  }

  .PriceButton__normal {
    .PriceButton__inner {
      width: 118px;
      display: flex;
      justify-content: space-between;
      min-height: 40px;
      @include respond-to(xl) {
        width: 105px;
        min-height: 32px;
      }
      @include respond-to(sm) {
        width: 300px;
        padding: 0 50px;
        min-height: 50px;
      }
      span {
        font-size: $regular;
        @include respond-to(xl) {
          font-size: $small;
        }
        @include respond-to(sm) {
          font-size: $regular;
        }
      }
    }
  }

  .PriceButton__medium {
    .PriceButton__inner {
      width: 100%;
      min-height: 40px;
      @include respond-to(xl) {
        min-height: 30px;
      }

      span {
        font-weight: 500;
        font-size: $medium;
        @include respond-to(sm) {
          font-size: $small;
        }
      }
    }
  }

  @keyframes animate {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`;

export default staticStyles;

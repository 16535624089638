import { defineMessages } from 'react-intl';

export default defineMessages({
  provideLiquidity: 'Provide Liquidity',
  lockLpToken: 'Lock LP Token',
  expand: 'Expand',
  slippageTolerance: 'Slippage Tolerance',

  manageVeKlap: 'Manage VeKLAP',
  selectedLockInfo: 'Selected Lock Info:',
  increaseLockedAmount: 'Increase locked amount:',
  increaseLockPeriodTo: 'Increase lock period to (months):',
  increaseAmountButton: 'Increase Amount',
  increaseLockPeriodButton: 'Increase Lock Period',
  manage: 'Manage',
  approve: 'Approve',
  lockLP: 'Lock LP',
});

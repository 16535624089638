import React from 'react';
import { useWeb3React } from '@web3-react/core';

export default function MainnetWarning() {
  const { chainId } = useWeb3React();

  if (chainId === 1 || chainId === undefined) return null;

  return <div className="MainnetWarning"></div>;
}

import { useState } from 'react';
import { CardEndContainer, CardInnerContainer } from '../../../manage/components/styles';
import Preloader from '../../../../components/basic/Preloader';
import { CardContainer } from '../../../../components/CardContainer';
import Logo from '../../../../components/basic/Logo';
import DefaultButton from '../../../../components/basic/DefaultButton';
import ImageLogo from '../../../../images/klapLogo.svg';
import KlayLogo from '../../../../images/klay.png';
import { InputFieldCard } from '../../../manage/components/ProvideLiquidity';
import styled from 'styled-components';
import SlippageButtons from '../../../ve/components/SlippageButtons';
import { useIntl } from 'react-intl';
import messages from './messages';
import messagesLocal from '../../../ve/messages';

const MiniTitle = styled.h1<{ nomargin: boolean }>`
  margin-bottom: ${({ nomargin }) => (nomargin ? 24 : 0)}px;
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const ExpandButton = styled.button`
  padding: 8px 16px;
  border-radius: 24px;
  border: 1px solid #000000;
`;

interface LiquidityDualComponentProps {
  onProvideLiquidity: (amountKlap: number, amountKlay: number, slippage: number) => void;
  maxLP: number;
  maxKlay: number;
  maxKlap: number;
  ratioKlapToKlay: number;
  isLoadingProvideLiquidity: boolean;
  isNotApprovedKlap: boolean;
  child: any;
}

const LiquidityDualComponent = (props: LiquidityDualComponentProps) => {
  const intl = useIntl();
  const [isBottomExpanded, setIsBottomExpanded] = useState(false);
  const [klapAmount, setKlapAmount] = useState('');
  const [klayAmount, setKlayAmount] = useState('');
  const [slippageButton, setSlippageButton] = useState(0.1);

  return (
    <CardContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: 20,
        padding: '16px 24px 32px 24px',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <CardEndContainer>
        <CardInnerContainer>
          <MiniTitle nomargin={!isBottomExpanded}>
            <div style={{ display: 'flex' }}>
              {intl.formatMessage(messagesLocal.provideLiquidity)}
              <Logo style={{ marginTop: '-7px' }} />
            </div>
            {isBottomExpanded && (
              <ExpandButton onClick={() => setIsBottomExpanded(!isBottomExpanded)}>
                {intl.formatMessage(messagesLocal.expand)}
              </ExpandButton>
            )}
          </MiniTitle>
          {!isBottomExpanded && (
            <>
              <InputFieldCard
                source={<img style={{ height: 20 }} src={ImageLogo} alt={`Currency Logo KLAP`} />}
                amountTitle="KLAP"
                amount={klapAmount}
                max={'' + props.maxKlap}
                setAmount={(amount) => {
                  setKlapAmount(amount);
                  setKlayAmount('' + parseFloat(amount) * props.ratioKlapToKlay);
                }}
              />
              <div style={{ height: 24 }} />
              <InputFieldCard
                source={<img style={{ height: 16 }} src={KlayLogo} alt={`Currency Logo KLAY`} />}
                amountTitle="KLAY"
                amount={klayAmount}
                max={'' + props.maxKlay}
                setAmount={(amount) => {
                  setKlayAmount(amount);
                  setKlapAmount('' + parseFloat(amount) / props.ratioKlapToKlay);
                }}
              />
              <div style={{ maxWidth: '50%', marginTop: 20 }}>
                <p style={{ marginBottom: 8 }}>
                  {intl.formatMessage(messagesLocal.slippageTolerance)}
                </p>
                <SlippageButtons
                  selectedButton={slippageButton}
                  setSelectedButton={setSlippageButton}
                />
              </div>
              <ButtonContainer>
                {props.isLoadingProvideLiquidity ? (
                  <div style={{ maxWidth: 80 }}>
                    <Preloader smallSize />
                  </div>
                ) : (
                  <DefaultButton
                    disabled={
                      parseFloat(klapAmount) > props.maxKlap ||
                      parseFloat(klayAmount) > props.maxKlay ||
                      klapAmount === '' ||
                      klayAmount === ''
                    }
                    onClick={() =>
                      props.onProvideLiquidity(
                        Number(klapAmount),
                        Number(klayAmount),
                        slippageButton * 10
                      )
                    }
                    title={
                      props.isNotApprovedKlap
                        ? intl.formatMessage(messagesLocal.approve)
                        : intl.formatMessage(messagesLocal.provideLiquidity)
                    }
                    color="green"
                  />
                )}
              </ButtonContainer>
            </>
          )}
        </CardInnerContainer>
        <CardInnerContainer>
          <MiniTitle nomargin={isBottomExpanded}>
            <div style={{ display: 'flex' }}>
              {intl.formatMessage(messages.caption)}
              <Logo style={{ marginTop: '-7px' }} />
            </div>
            {!isBottomExpanded && (
              <ExpandButton onClick={() => setIsBottomExpanded(!isBottomExpanded)}>
                {intl.formatMessage(messagesLocal.expand)}
              </ExpandButton>
            )}
          </MiniTitle>
          {isBottomExpanded && props.child}
        </CardInnerContainer>
      </CardEndContainer>
    </CardContainer>
  );
};

export default LiquidityDualComponent;

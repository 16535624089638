import { defineMessages } from 'react-intl';

export default defineMessages({
  optionTitleLeft: 'All',
  optionTitleRight: 'Stable Coins',
  noDataText: 'There are no currencies matching the parameters',

  availableToBorrow: 'Available to borrow',
  myBorrows: 'My Borrows',
  noCurrentBorrows: 'No Current Borrows',
});

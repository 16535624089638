import React, { ReactNode, ReactNodeArray } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import messages from './messages';
import staticStyles from './style';

import closeIcon from '../../../../images/x-icon.svg';
import Modal from '../../../Modal';

interface ConnectWalletWrapperProps {
  children: ReactNode | ReactNodeArray;
  className?: string;
  isVisible: boolean;
  onBackdropPress: () => void;
}

export default function ConnectWalletWrapper({
  children,
  className,
  isVisible,
  onBackdropPress,
}: ConnectWalletWrapperProps) {
  const intl = useIntl();

  return (
    <Modal
      onBackdropPress={onBackdropPress}
      isVisible={isVisible}
      withCloseButton={true}
      className={classNames('ConnectWalletWrapper', className)}
      closeIcon={closeIcon}
    >
      <div className="ConnectWalletWrapper__inner">
        <div className="ConnectWalletWrapper__caption-inner">
          <h2>{intl.formatMessage(messages.caption)}</h2>
        </div>

        {children}
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        .ConnectWalletWrapper {
          color: #000;
          background: #fff !important;
          h2 {
            color: #000;
          }
        }
      `}</style>
    </Modal>
  );
}

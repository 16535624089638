import { useEffect, useState } from 'react';
import {
  APRContainer,
  CardEndContainer,
  CardInnerContainer,
  Description,
  DescriptionTitle,
  ListContainer,
  TitleCard,
} from '../../manage/components/styles';
import Preloader from '../../../components/basic/Preloader';
import { CardContainer } from '../../../components/CardContainer';
import Logo from '../../../components/basic/Logo';
import DefaultButton from '../../../components/basic/DefaultButton';
import MonthButtons from '../../manage/components/MonthButtons';
import ImageLogo from '../../../images/klapLogo.svg';
import KlayLogo from '../../../images/klay.png';
import { InputFieldCard, MonthContainer } from '../../manage/components/ProvideLiquidity';
import styled from 'styled-components';
import SlippageButtons from './SlippageButtons';
import { useIntl } from 'react-intl';
import messagesLocal from '../messages';
import messages from '../../manage/messages';
import TooltipCoinBreakdown from '../../../components/TooltipCoinBreakdown';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import { BigNumber } from 'ethers';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { useMultiCall } from '../../../libs/pool-data-provider/hooks/use-multicall';
import { ONE_MONTH } from '../../manage/functions';
import MasterchefUIHelper from '../../../contracts/MasterChefUIHelper.json';
import { useStaticPoolDataContext } from '../../../libs/pool-data-provider';
import { useGetManageInfo } from '../../manage/hooks';

const MiniTitle = styled.h1<{ nomargin: boolean }>`
  margin-bottom: ${({ nomargin }) => (nomargin ? 24 : 0)}px;
  display: flex;
  justify-content: space-between;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const ExpandButton = styled.button`
  padding: 8px 16px;
  border-radius: 24px;
  border: 1px solid #000000;
`;

interface GetVeKlapCardProps {
  apr: { base: number; klay: number; boosted: string | number }[];
  onLock: (amount: number, lock_duration: number) => void;
  onProvideLiquidity: (amountKlap: number, amountKlay: number, slippage: number) => void;
  maxLP: number;
  maxKlay: number;
  maxKlap: number;
  ratioKlapToKlay: number;
  isLoadingLock: boolean;
  isLoadingProvideLiquidity: boolean;
  isNotApprovedLP: boolean;
  isNotApprovedKlap: boolean;
  userFactor: BigNumber;
  sumOfFactor: BigNumber;
}

const ProvideLiquidityLPCard = (props: GetVeKlapCardProps) => {
  const { currentMarketData } = useProtocolDataContext();
  const intl = useIntl();
  const { data, refresh } = useGetManageInfo();
  const { userId } = useStaticPoolDataContext();
  const [isBottomExpanded, setIsBottomExpanded] = useState(false);
  const [klapAmount, setKlapAmount] = useState('');
  const [klayAmount, setKlayAmount] = useState('');
  const [slippageButton, setSlippageButton] = useState(0.1);
  const [lpAmount, setLPAmount] = useState('');
  const [selectedButton, setSelectedButton] = useState(3);
  const [apr, setApr] = useState(0);
  const [boostedApr, setBoostedApr] = useState('0');
  const masterchefUiHelper = useMultiCall([MasterchefUIHelper]);

  useEffect(() => {
    const search = async () => {
      if (!userId || data.baseDialutingAmount.eq(0)) {
        setBoostedApr('0');
        return;
      }
      console.log('data', data);
      console.log('got here', userId);
      const lpAmountParsed = lpAmount == '' ? 0 : parseEther('' + lpAmount);
      const [[[userFactor, sumFactor]]] = await masterchefUiHelper(
        currentMarketData.addresses.MASTERCHEF_UI_HELPER!,
        [['getUpdatedFactor']],
        [[[userId, lpAmountParsed, selectedButton * ONE_MONTH]]]
      );
      console.log('lp amount', lpAmountParsed.toString());
      console.log('time', selectedButton * ONE_MONTH);
      console.log('before user factor', data.userFactor.toString());
      console.log('after user factor', BigNumber.from(userFactor).toString());
      console.log('before sum factor', data.sumOfFactor.toString());
      console.log('after sum factor', BigNumber.from(sumFactor).toString());
      const finalApr =
        (Number(formatEther(data.baseDialutingAmount)) *
          (BigNumber.from(userFactor).mul(1000000).div(BigNumber.from(sumFactor)).toNumber() /
            1000000)) /
        ((Number(formatEther(data.userAndVeDepositedAmount)) +
          Number(formatEther(lpAmountParsed))) *
          data.lpPrice);
      console.log('final apr', finalApr);
      setBoostedApr('' + finalApr);
    };
    const timer = setTimeout(() => {
      search();
    }, 1500);

    return () => {
      clearTimeout(timer);
    };
  }, [lpAmount, data]);

  return (
    <CardContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: 20,
        padding: '16px 24px 32px 24px',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <CardEndContainer>
        <TitleCard>
          {intl.formatMessage(messages.provideLiquidity)} <Logo />
        </TitleCard>
        <APRContainer style={{ alignItems: 'center' }}>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: 21,
              color: '#00CC88',
              display: 'flex',
              gridGap: 8,
            }}
          >
            APR{' '}
            {props.apr[apr] && !isNaN(props.apr[apr].base) && !isNaN(props.apr[apr].klay)
              ? boostedApr == '0'
                ? (
                    Number(props.apr[apr].base) * 100 +
                    Number(props.apr[apr].klay) * 100
                  ).toLocaleString('en-us', { maximumFractionDigits: 2 })
                : (
                    Number(boostedApr) * 100 +
                    Number(props.apr[apr].base) * 100 +
                    Number(props.apr[apr].klay) * 100
                  ).toLocaleString('en-us', { maximumFractionDigits: 2 })
              : 0}
            %
          </p>
          {props.apr[apr] && !isNaN(props.apr[apr].base) && !isNaN(props.apr[apr].klay) ? (
            <TooltipCoinBreakdown
              boostedApr={boostedApr}
              baseApr={props.apr[apr].base}
              klayApr={props.apr[apr].klay}
              showTypingIndicator={boostedApr == '0'}
            />
          ) : null}
        </APRContainer>
        <ListContainer>
          <DescriptionTitle>{intl.formatMessage(messages.benefits)}</DescriptionTitle>
          <ul style={{ width: '100%' }}>
            <Description>{intl.formatMessage(messages.benefitsProvideLiquidity1)}</Description>
            <Description>{intl.formatMessage(messages.benefitsProvideLiquidity2)}</Description>
            <Description>{intl.formatMessage(messages.benefitsProvideLiquidity3)}</Description>
            <Description>{intl.formatMessage(messages.benefitsProvideLiquidity4)}</Description>
          </ul>
        </ListContainer>
        <CardInnerContainer>
          <MiniTitle nomargin={!isBottomExpanded}>
            {intl.formatMessage(messagesLocal.provideLiquidity)}{' '}
            {isBottomExpanded && (
              <ExpandButton onClick={() => setIsBottomExpanded(!isBottomExpanded)}>
                {intl.formatMessage(messagesLocal.expand)}
              </ExpandButton>
            )}
          </MiniTitle>
          {!isBottomExpanded && (
            <>
              <InputFieldCard
                source={<img style={{ height: 20 }} src={ImageLogo} alt={`Currency Logo KLAP`} />}
                amountTitle="KLAP"
                amount={klapAmount}
                max={'' + props.maxKlap}
                setAmount={(amount) => {
                  setKlapAmount(amount);
                  setKlayAmount('' + parseFloat(amount) * props.ratioKlapToKlay);
                }}
              />
              <div style={{ height: 24 }} />
              <InputFieldCard
                source={<img style={{ height: 16 }} src={KlayLogo} alt={`Currency Logo KLAY`} />}
                amountTitle="KLAY"
                amount={klayAmount}
                max={'' + props.maxKlay}
                setAmount={(amount) => {
                  setKlayAmount(amount);
                  setKlapAmount('' + parseFloat(amount) / props.ratioKlapToKlay);
                }}
              />
              <p style={{ marginBottom: 8 }}>
                {intl.formatMessage(messagesLocal.slippageTolerance)}
              </p>
              <MonthContainer>
                <SlippageButtons
                  selectedButton={slippageButton}
                  setSelectedButton={(num) => {
                    setSlippageButton(num);
                  }}
                />
              </MonthContainer>
              <ButtonContainer>
                {props.isLoadingProvideLiquidity ? (
                  <div style={{ maxWidth: 80 }}>
                    <Preloader smallSize />
                  </div>
                ) : (
                  <DefaultButton
                    disabled={
                      parseFloat(klapAmount) > props.maxKlap ||
                      parseFloat(klayAmount) > props.maxKlay ||
                      klapAmount === '' ||
                      klayAmount === ''
                    }
                    onClick={() =>
                      props.onProvideLiquidity(
                        Number(klapAmount),
                        Number(klayAmount),
                        slippageButton
                      )
                    }
                    title={
                      props.isNotApprovedKlap
                        ? intl.formatMessage(messagesLocal.approve)
                        : intl.formatMessage(messages.provideLiquidityButton)
                    }
                    color="green"
                  />
                )}
              </ButtonContainer>
            </>
          )}
        </CardInnerContainer>
        <CardInnerContainer>
          <MiniTitle nomargin={isBottomExpanded}>
            {intl.formatMessage(messagesLocal.lockLpToken)}{' '}
            {!isBottomExpanded && (
              <ExpandButton onClick={() => setIsBottomExpanded(!isBottomExpanded)}>
                {intl.formatMessage(messagesLocal.expand)}
              </ExpandButton>
            )}
          </MiniTitle>
          {isBottomExpanded && (
            <>
              <div style={{ marginBottom: 32 }}>
                <InputFieldCard
                  amountTitle="KLAP-KLAY LP"
                  amount={lpAmount}
                  max={'' + props.maxLP}
                  setAmount={(amount) => {
                    setLPAmount(amount);
                  }}
                />
              </div>
              <p style={{ marginBottom: 8 }}>{intl.formatMessage(messages.lengthQuestion)}</p>
              <MonthContainer>
                <MonthButtons
                  selectedButton={selectedButton}
                  setSelectedButton={(num) => {
                    setSelectedButton(num);
                    switch (num) {
                      case 3:
                        setApr(0);
                        break;
                      case 6:
                        setApr(1);
                        break;
                      case 12:
                        setApr(2);
                        break;
                      default:
                        setApr(3);
                        break;
                    }
                  }}
                />
              </MonthContainer>
              <ButtonContainer>
                {props.isLoadingLock ? (
                  <div style={{ maxWidth: 80 }}>
                    <Preloader smallSize />
                  </div>
                ) : (
                  <DefaultButton
                    disabled={
                      parseFloat(lpAmount) > props.maxLP ||
                      lpAmount.length === 0 ||
                      parseFloat(lpAmount) === 0
                    }
                    onClick={() => props.onLock(parseFloat(lpAmount), selectedButton)}
                    title={
                      props.isNotApprovedLP
                        ? intl.formatMessage(messagesLocal.approve)
                        : intl.formatMessage(messagesLocal.lockLP)
                    }
                    color="green"
                  />
                )}
              </ButtonContainer>
            </>
          )}
        </CardInnerContainer>
      </CardEndContainer>
    </CardContainer>
  );
};

export default ProvideLiquidityLPCard;

import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { valueToBigNumber } from '@aave/protocol-js';
import { useThemeContext } from '@aave/aave-ui-kit';

import { useDynamicPoolDataContext } from '../../../../libs/pool-data-provider';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import MarketTable from '../../components/MarketTable';
import MarketTableItem from '../../components/MarketTableItem';
import TopCard from '../../components/TopCard';
import MarketMobileCard from '../../components/MarketMobileCard';

import messages from './messages';
import staticStyles from './style';
import { useIncentivesDataContext } from '../../../../libs/pool-data-provider/hooks/use-incentives-data-context';
import { SearchBar } from '../../../../components/AssetsFilterPanel';
import DefaultButton from '../../../../components/basic/DefaultButton';
import { useServices } from './functions';
import _ from 'lodash';
import Preloader from '../../../../components/basic/Preloader';
import { useGetMarketInfo } from './hooks';
import styled from 'styled-components';
import { useUserWalletDataContext } from '../../../../libs/web3-data-provider';

const PreminingRewardsText = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const PreminingContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  align-items: center;
  margin: 8px 0px;
`;

export default function Markets() {
  const intl = useIntl();
  const [isVestAllLoading, setIsVestAllLoading] = useState(false);
  const { vest, claimKlap, claimKlay } = useServices();
  const { currentAccount: userId } = useUserWalletDataContext();
  const { currentTheme } = useThemeContext();
  const { reserves } = useDynamicPoolDataContext();
  const { reserveIncentives } = useIncentivesDataContext();
  const { data } = useGetMarketInfo();
  const [isPriceInUSD /*, setIsPriceInUSD*/] = useState(
    localStorage.getItem('marketsIsPriceInUSD') === 'true'
  );
  const [isKlapLoading, setIsKlapLoading] = useState(false);
  const [isKlayLoading, setIsKlayLoading] = useState(false);
  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const [searchVal, setSearchVal] = useState('');
  let totalMarketSize = valueToBigNumber('0');
  let sortedData = reserves
    .filter((res) => res.isActive && !res.isFrozen)
    .filter((val) => val.symbol.toLowerCase().indexOf(searchVal.toLowerCase()) !== -1)
    .map((reserve) => {
      totalMarketSize = totalMarketSize.plus(
        valueToBigNumber(reserve.totalLiquidity).multipliedBy(
          reserve.priceInMarketReferenceCurrency
        )
      );

      const totalLiquidity = Number(reserve.totalLiquidity);
      const totalLiquidityInUSD = valueToBigNumber(reserve.totalLiquidity)
        .multipliedBy(reserve.priceInMarketReferenceCurrency)
        .toNumber();

      const totalBorrows = Number(reserve.totalDebt);
      const totalBorrowsInUSD = valueToBigNumber(reserve.totalDebt)
        .multipliedBy(reserve.priceInMarketReferenceCurrency)

        .toNumber();
      const reserveIncentiveData = reserveIncentives[reserve.underlyingAsset.toLowerCase()];
      return {
        totalLiquidity,
        totalLiquidityInUSD,
        totalBorrows: reserve.borrowingEnabled ? totalBorrows : -1,
        totalBorrowsInUSD: reserve.borrowingEnabled ? totalBorrowsInUSD : -1,
        id: reserve.id,
        underlyingAsset: reserve.underlyingAsset,
        currencySymbol: reserve.symbol,
        depositAPY: reserve.borrowingEnabled ? Number(reserve.supplyAPY) : -1,
        variableBorrowRate: reserve.borrowingEnabled ? Number(reserve.variableBorrowAPY) : -1,
        avg30DaysLiquidityRate: Number(reserve.avg30DaysLiquidityRate),
        stableBorrowRate:
          reserve.stableBorrowRateEnabled && reserve.borrowingEnabled
            ? Number(reserve.stableBorrowAPY)
            : -1,
        avg30DaysVariableRate: Number(reserve.avg30DaysVariableBorrowRate),
        borrowingEnabled: reserve.borrowingEnabled,
        stableBorrowRateEnabled: reserve.stableBorrowRateEnabled,
        isFreezed: reserve.isFrozen,
        aincentivesAPR: reserveIncentiveData ? reserveIncentiveData.aIncentives.incentiveAPR : '0',
        vincentivesAPR: reserveIncentiveData ? reserveIncentiveData.vIncentives.incentiveAPR : '0',
        sincentivesAPR: reserveIncentiveData ? reserveIncentiveData.sIncentives.incentiveAPR : '0',
        aTokenAddress: reserve.aTokenAddress,
        variableDebtAddress: reserve.variableDebtTokenAddress,
        canVest: reserve.canVest,
        decimals: reserve.decimals,
        price: Number(reserve.priceInMarketReferenceCurrency),
        depositFarmAPY: reserve.depositFarmAPY,
        depositVeFarmAPY: reserve.depositVeFarmAPY,
        borrowFarmAPY: reserve.borrowFarmAPY,
        borrowVeFarmAPY: reserve.borrowVeFarmAPY,
        borrowKlayAPY: reserve.borrowKlayAPY,
        depositKlayAPY: reserve.depositKlayAPY,
        name: reserve.name,
        vestedAmount: reserve.vestedAmount,
      };
    });

  if (sortDesc) {
    if (sortName === 'currencySymbol') {
      sortedData.sort((a, b) =>
        b.currencySymbol.toUpperCase() < a.currencySymbol.toUpperCase() ? -1 : 0
      );
    } else {
      // @ts-ignore
      sortedData.sort((a, b) => a[sortName] - b[sortName]);
    }
  } else {
    if (sortName === 'currencySymbol') {
      sortedData.sort((a, b) =>
        a.currencySymbol.toUpperCase() < b.currencySymbol.toUpperCase() ? -1 : 0
      );
    } else {
      // @ts-ignore
      sortedData.sort((a, b) => b[sortName] - a[sortName]);
    }
  }

  return (
    <>
      <div className="TopTVLCard">
        <TopCard
          title={intl.formatMessage(messages.totalTVL)}
          value={
            '$' +
            totalMarketSize.toNumber().toLocaleString('en-us', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }
        />
      </div>
      <ScreenWrapper pageTitle={intl.formatMessage(messages.pageTitle)} className="Markets">
        {(data.preminingKlapRewards > 0 && data.preminingKlapClaimable) ||
        data.preminingKlayRewards > 0 ? (
          <PreminingContainer>
            <PreminingRewardsText>
              {intl.formatMessage(messages.preminingRewards)} - KLAP:{' '}
              {data.preminingKlapRewards.toLocaleString('en-us', { maximumFractionDigits: 2 })},
              KLAY:{' '}
              {data.preminingKlayRewards.toLocaleString('en-us', { maximumFractionDigits: 2 })}
            </PreminingRewardsText>
            {isKlayLoading ? (
              <div style={{ width: 95 }}>
                <Preloader smallSize />
              </div>
            ) : (
              <DefaultButton
                className="VestAllButton"
                color="blue"
                title={`${intl.formatMessage(messages.claim)} KLAY`}
                onClick={async (e) => {
                  e.stopPropagation();
                  setIsKlayLoading(true);
                  await claimKlay();
                  setIsKlayLoading(false);
                }}
              />
            )}
            {isKlapLoading ? (
              <div style={{ width: 95 }}>
                <Preloader smallSize />
              </div>
            ) : (
              <DefaultButton
                className="VestAllButton"
                color="blue"
                disabled={!data.preminingKlapClaimable}
                title={`${intl.formatMessage(messages.claim)} KLAP`}
                onClick={async (e) => {
                  e.stopPropagation();
                  setIsKlapLoading(true);
                  await claimKlap();
                  setIsKlapLoading(false);
                }}
              />
            )}
          </PreminingContainer>
        ) : null}
        <div className="Markets__searchbar-container">
          <SearchBar searchValue={searchVal} searchOnChange={setSearchVal} />
          {isVestAllLoading ? (
            <div style={{ width: 95 }}>
              <Preloader smallSize />
            </div>
          ) : userId ? (
            <DefaultButton
              className="VestAllButton"
              color="blue"
              title={intl.formatMessage(messages.vestAll)}
              onClick={async (e) => {
                e.stopPropagation();
                const addresses = _.map(reserves, (item) => [
                  item.aTokenAddress,
                  item.variableDebtTokenAddress,
                ]);
                const flattenedAddresses = _.flatten(addresses);
                setIsVestAllLoading(true);
                await vest(flattenedAddresses);
                setIsVestAllLoading(false);
              }}
            />
          ) : null}
        </div>
        {/*<div className="Markets__price-switcher">
        <LabeledSwitcher
          value={!isPriceInUSD}
          leftOption="USD"
          rightOption={intl.formatMessage(messages.native)}
          onToggle={() =>
            toggleLocalStorageClick(isPriceInUSD, setIsPriceInUSD, 'marketsIsPriceInUSD')
          }
        />
        </div>*/}

        <MarketTable
          sortName={sortName}
          setSortName={setSortName}
          sortDesc={sortDesc}
          setSortDesc={setSortDesc}
        >
          {sortedData.map((item, index) => (
            <MarketTableItem
              claimTokens={[item.aTokenAddress, item.variableDebtAddress]}
              {...item}
              isPriceInUSD={isPriceInUSD}
              key={index}
            />
          ))}
        </MarketTable>

        <div className="Markets__mobile--cards">
          {sortedData.map((item, index) => (
            <MarketMobileCard {...item} key={index} />
          ))}
        </div>

        <style jsx={true} global={true}>
          {staticStyles}
        </style>
        <style jsx={true} global={true}>{`
          .Markets {
            &__top-content {
              color: ${'#000'};
            }
            &__marketSwitcher--title {
              color: ${currentTheme.textDarkBlue.hex};
            }
          }
        `}</style>
      </ScreenWrapper>
    </>
  );
}

import _ from 'lodash';
import styled from 'styled-components';

export const TimeContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media only screen and (max-width: 450px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const TimeButton = styled.button<{ selected: boolean }>`
  padding: 16px 12px;
  border-radius: 70px;
  cursor: pointer;
  border: 2px solid rgba(28, 35, 51, 0.04);
  color: #1c2333;
  transition: 0.3s;
  height: 50px;
  width: 100%;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ selected }) =>
    selected
      ? `
  cursor: auto;
  border: 2px solid #3E74FF;
  color: #3E74FF;
`
      : `
  &:hover {
    opacity: 0.7;
  }
`}
`;

const MultiplierText = styled.div`
  color: #00cc88;
  font-size: 10px;
`;

const TIMES = [3, 6, 12, 24];

interface MonthButtonsProps {
  selectedButton: number | string;
  setSelectedButton: (amount: any) => void;
  timesOverride?: any[];
  dontShowM?: boolean;
  className?: string;
  showMultiplier?: boolean;
}

const MonthButtons = ({
  selectedButton,
  setSelectedButton,
  timesOverride = TIMES,
  dontShowM = false,
  className,
  showMultiplier = false,
}: MonthButtonsProps) => {
  return (
    <TimeContainer className={className}>
      {_.map(timesOverride, (time) => (
        <TimeButton
          key={time}
          selected={selectedButton === time}
          onClick={() => setSelectedButton(time)}
        >
          {time} {!dontShowM && 'm'}
          {showMultiplier && <MultiplierText>({time}x)</MultiplierText>}
        </TimeButton>
      ))}
    </TimeContainer>
  );
};

export default MonthButtons;

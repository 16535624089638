import { defineMessages } from 'react-intl';

export default defineMessages({
  baseAPR: 'Base APR',
  farmAPR: 'Farm APR',
  veBoosterAPR: 'Ve Booster APR',
  klayAPR: 'KLAY APR',
  boosterNote: 'Note: You must have assets in the pool to see accurate Boosted Ve APR',
  showTypingIndicator: 'Please start typing to see correct APR',
});

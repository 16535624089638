import { defineMessages } from 'react-intl';

export default defineMessages({
  asset: 'Asset',
  amount: 'Amount',
  duration: 'Duration',
  expiry: 'Expiry',
  status: 'Status',
  action: 'Action',
  noAssets: 'You have not locked assets yet.',
  lockdropTitle: 'KLAP Lockdrop',
  lockdropMessage:
    'Deposit KLAP tokens temporarily into our Lockdrop vault, choose between the four different durations and you will earn interest and rewards during the lockup period and receive 100% of your deposit back at expiry.',
  toBeEarned: 'To Be Earned',
  eventEndsIn: 'Event Ends in',
  tvl: 'Locked in Tranche',
  aggregateTVL: 'Aggregate Lockdrop TVL',
  assetQuestion: 'Which asset do you want to deposit?',
  note: 'Deposit here to get aTokens',
  stakeLengthQuestion: 'How long do you want to stake for? (months)',
  amountStakeQuestion: 'How much do you want to stake?',
  availableDeposit: 'Available to Deposit:',
  claim: 'Claim Asset',
  claimKlap: 'Claim KLAP',
  claimKlay: 'Claim KLAY',
  unlocked: 'Unlocked',
  locked: 'Locked',
  deposited: 'Deposited',
  cap: 'Cap',
  readMore: 'Read More',
  mediumLink:
    'https://medium.com/@klapfinance/lockdrop-is-live-and-all-klappers-can-get-involved-to-earn-kl-efb3411aede',
  errorMessage:
    'There was an error processing your request. Please make sure the amount you plan to deposit will not lower your Health Ratio below 1. You can increase health ratio by repaying your borrows.',
});

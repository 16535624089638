import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import NoDataPanel from '../../components/NoDataPanel';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import styled from 'styled-components';
import SearchField from '../../components/fields/SearchField';
import { useEffect, useState } from 'react';
import staticStyles from './style';
import DefaultButton from '../../components/basic/DefaultButton';
import { useDynamicPoolDataContext } from '../../libs/pool-data-provider';
import VoteTable from './VoteTable/VoteTable';
import VoteTableItem from './VoteTable/VoteTableItem';
import { InfoData, useGetVoteInfo } from '../../libs/vote-data-provider/hooks';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import _ from 'lodash';
import { useServices } from './functions';
import Preloader from '../../components/basic/Preloader';
import { useIntl } from 'react-intl';
import defaultMessages from '../../defaultMessages';
import messages from './messages';
import { useThemeContext } from '@aave/aave-ui-kit';
import MobileVoteItem from './VoteTable/MobileVoteItem';
import { useMultiCall } from '../../libs/pool-data-provider/hooks/use-multicall';
import CHEF_INCENTIVES_ABI from '../../contracts/ChefIncentivesController.json';
import { BigNumber } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { getKlapKlayPrice } from '../../helpers/get-klap-price';

const UpperLeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const MobileTableContainer = styled.div``;

/*const Secondary = styled.div`
  color: #76808f !important;
  font-size: 16px !important;
`;

const SecondaryBold = styled.div`
  color: #000;
  font-weight: bold;
`;

const VotingStats = styled.div`
  display: flex;
  grid-gap: 24px;
  padding-top: 20px;
`;

const Stat = styled.div`
  display: flex;
  grid-gap: 5px;
  align-items: center;
  flex-direction: column;
`;*/

export default function Vote() {
  const intl = useIntl();
  const { currentMarketData } = useProtocolDataContext();
  const [searchValue, setSearchValue] = useState('');
  const { currentAccount: userId } = useUserWalletDataContext();
  const { chainId } = useProtocolDataContext();
  const { reserves } = useDynamicPoolDataContext();
  const { data } = useGetVoteInfo();
  const { voteTokens, unvoteTokens } = useServices();
  const [sortName, setSortName] = useState('');
  const [sortDesc, setSortDesc] = useState(false);
  const [selectedVe, setSelectedVe] = useState('-1');
  const [currentNFT, setNFT] = useState<InfoData | null>(null);
  const [voteList, setVoteList] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [emissionData, setEmissionData] = useState<any[]>([]);
  const [totalAllocPoints, setTotalAllocPoints] = useState('0');
  const [totalDialuting, setTotalDialuting] = useState('0');
  const [totalNonDialuting, setTotalNonDialuting] = useState('0');
  const [klapPrice, setKlapPrice] = useState(1);
  const { sm } = useThemeContext();
  const multicall = useMultiCall([CHEF_INCENTIVES_ABI]);

  let sortedData = reserves
    .filter((res) => res.symbol.toLowerCase().includes(searchValue.toLowerCase()))
    .filter((res) => res.isActive && !res.isFrozen)
    .map((reserve) => {
      return [
        {
          id: reserve.id,
          isAToken: true,
          currencySymbol: reserve.symbol,
          address: reserve.aTokenAddress,
          price: reserve.priceInMarketReferenceCurrency,
          decimals: reserve.decimals,
          name: reserve.name,
        },
        {
          id: reserve.id,
          isAToken: false,
          currencySymbol: reserve.symbol,
          address: reserve.variableDebtTokenAddress,
          price: reserve.priceInMarketReferenceCurrency,
          decimals: reserve.decimals,
          name: reserve.name,
        },
      ];
    })
    .flat();

  const getEmissionData = async () => {
    const totalLength = sortedData.length;
    const methodsAlloc = _.map(_.range(0, totalLength), () => 'allocPoints');
    const methodsPoolInfo = _.map(_.range(0, totalLength), () => 'poolInfo');
    const pools: any = _.map(sortedData, (data) => [data.address]);
    const [[chefResponse], { klap }] = await Promise.all([
      multicall(
        currentMarketData.addresses.CHEFS_INCENTIVE_CONTROLLER!,
        [
          [
            'totalAllocPoints',
            'rewardsPerSecond',
            'dialutingRepartition',
            ...methodsAlloc,
            ...methodsPoolInfo,
          ],
        ],
        [[[], [], [], ...pools, ...pools]]
      ),
      getKlapKlayPrice(currentMarketData.addresses.LP_TOKEN!),
    ]);
    const [_totalAllocPoints, rewardsPerSecond, dialutingRepartition] = chefResponse.splice(0, 3);
    const allocPoints = chefResponse.splice(0, totalLength);
    const poolInfo = chefResponse.splice(0, totalLength);
    const klapPrice = klap;

    const nonDialutingRepartition = BigNumber.from(1000).sub(
      BigNumber.from(dialutingRepartition[0])
    );
    const nondialuting = BigNumber.from(rewardsPerSecond[0])
      .mul(BigNumber.from(86400 * 365))
      .mul(nonDialutingRepartition)
      .div(1000);
    const dialuting = BigNumber.from(rewardsPerSecond[0])
      .mul(BigNumber.from(86400 * 365))
      .mul(BigNumber.from(dialutingRepartition[0]))
      .div(BigNumber.from(1000));

    let emissionAndAPRData = [];
    for (let i = 0; i < totalLength; i += 1) {
      const totalSupply = BigNumber.from(poolInfo[i][0]).eq(BigNumber.from(0))
        ? BigNumber.from('1').toNumber()
        : sortedData[i].decimals === 18
        ? formatEther(poolInfo[i][0])
        : BigNumber.from(poolInfo[i][0])
            .div(BigNumber.from(10).pow(BigNumber.from(sortedData[i].decimals)))
            .toString();
      console.log('total supply', totalSupply);
      emissionAndAPRData.push({
        allocPoints: BigNumber.from(allocPoints[i][0]).toString(),
        totalSupply: totalSupply,
      });
    }
    setTotalDialuting(formatEther(dialuting));
    setTotalNonDialuting(formatEther(nondialuting));
    setTotalAllocPoints(BigNumber.from(_totalAllocPoints[0]).toString());
    setEmissionData(emissionAndAPRData);
    setKlapPrice(klapPrice);
  };

  useEffect(() => {
    if (data.length > 0 && currentNFT === null) {
      setSelectedVe(data[0].tokenId);
      setNFT(data[0]);
    }
  }, [data]);

  useEffect(() => {
    if (sortedData.length > 0 && voteList.length === 0) {
      getEmissionData();
      setVoteList(_.map(_.range(0, sortedData.length), () => 0));
    }
  }, [sortedData]);

  if (!userId && chainId !== 250) {
    return (
      <NoDataPanel
        title={intl.formatMessage(defaultMessages.connectWallet)}
        description={intl.formatMessage(defaultMessages.pleaseConnectWallet)}
        withConnectButton={true}
      />
    );
  }
  const globalPercentage = _.sum(voteList);
  return (
    <>
      <ScreenWrapper className="Vote">
        <UpperLeftContainer>
          <div>
            <h1 style={{ marginBottom: 20 }}>{intl.formatMessage(messages.yourVotingInfo)}</h1>
            {selectedVe !== '-1' ? (
              // @ts-ignore
              <Dropdown
                options={_.map(data, (e) => e.tokenId)}
                value={selectedVe}
                onChange={(e) => {
                  setSelectedVe(e.value);
                  setVoteList(_.map(voteList, (vote, _index) => 0));
                  setNFT(_.find(data!, (nft) => nft.tokenId === e.value)!);
                }}
              />
            ) : (
              <h4>{intl.formatMessage(messages.youNeedVe)}</h4>
            )}
          </div>
        </UpperLeftContainer>
        <div className="Vote__menu">
          <div className="Vote__search-inner">
            <div className="Vote__search-title">
              {intl.formatMessage(messages.searchByPoolName)}
            </div>
            <SearchField value={searchValue} onChange={setSearchValue} />
          </div>
          <div className="Vote__search-inner">
            {isLoading ? (
              <Preloader smallSize />
            ) : selectedVe !== '-1' ? (
              <DefaultButton
                onClick={async () => {
                  setIsLoading(true);
                  if (currentNFT!.voted) {
                    await unvoteTokens(selectedVe);
                  } else {
                    console.log('votelist', voteList);
                    await voteTokens(
                      selectedVe,
                      _.flatten(sortedData.map((item) => item.address)),
                      voteList
                    );
                  }
                  setIsLoading(false);
                }}
                className="Vote__cast-vote-btn"
                color="blue"
                title={
                  currentNFT!.voted
                    ? intl.formatMessage(messages.uncastVote)
                    : intl.formatMessage(messages.castYourVote)
                }
              />
            ) : (
              <DefaultButton
                className="Vote__cast-vote-btn"
                color="blue"
                disabled
                title={intl.formatMessage(messages.lockKlapToVote)}
              />
            )}
          </div>
        </div>
        {!sm ? (
          <VoteTable
            sortName={sortName}
            setSortName={setSortName}
            sortDesc={sortDesc}
            setSortDesc={setSortDesc}
          >
            {sortedData.map((item, index) => (
              <VoteTableItem
                klapPrice={klapPrice}
                currentNFT={!currentNFT ? 0 : Number(currentNFT?.tokenId)}
                setGlobalPercentage={(amount) => {
                  setVoteList(
                    _.map(voteList, (vote, _index) => (_index === index ? amount : vote))
                  );
                }}
                globalPercentage={globalPercentage}
                nftFactor={
                  currentNFT === null || (currentNFT !== null && currentNFT!.voted)
                    ? 0
                    : Number(currentNFT.balance)
                }
                disabled={currentNFT ? currentNFT!.voted : true}
                totalAllocPoint={totalAllocPoints}
                allocPoint={emissionData[index] ? emissionData[index].allocPoints : '0'}
                maxDialutingEmissions={totalNonDialuting}
                accKlapPerFactorShare={
                  emissionData[index] ? emissionData[index].accKlapPerFactorShare : '0'
                }
                baseEmissions={totalDialuting}
                totalSupply={emissionData[index] ? emissionData[index].totalSupply : '0'}
                {...item}
                price={Number(item.price)}
                key={index}
              />
            ))}
          </VoteTable>
        ) : (
          <MobileTableContainer>
            {sortedData.map((item, index) => (
              <MobileVoteItem
                klapPrice={klapPrice}
                currentNFT={!currentNFT ? 0 : Number(currentNFT?.tokenId)}
                setGlobalPercentage={(amount) => {
                  setVoteList(
                    _.map(voteList, (vote, _index) => (_index === index ? amount : vote))
                  );
                }}
                globalPercentage={globalPercentage}
                nftFactor={
                  currentNFT === null || (currentNFT !== null && currentNFT!.voted)
                    ? 0
                    : Number(currentNFT.balance)
                }
                disabled={currentNFT ? currentNFT!.voted : true}
                totalAllocPoint={totalAllocPoints}
                allocPoint={emissionData[index] ? emissionData[index].allocPoints : '0'}
                maxDialutingEmissions={totalNonDialuting}
                accKlapPerFactorShare={
                  emissionData[index] ? emissionData[index].accKlapPerFactorShare : '0'
                }
                baseEmissions={totalDialuting}
                totalSupply={emissionData[index] ? emissionData[index].totalSupply : '0'}
                {...item}
                price={Number(item.price)}
                key={index}
              />
            ))}
          </MobileTableContainer>
        )}
      </ScreenWrapper>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import BasicForm from '../../../../components/forms/BasicForm';

import messages from './messages';

export default function UnstakeAmount() {
  const intl = useIntl();
  const history = useHistory();
  const { selectedStakeData, selectedStake, stakingService } = useStakeDataContext();

  const handleSubmit = (amount: string) => {
    const query = queryString.stringify({ amount: Number(amount).toFixed(4) });
    history.push(`/staking/${selectedStake}/unstake/confirmation?${query}`);
  };

  const stkBalance = selectedStakeData.stakeTokenUserBalance;
  const handleGetTransactions = (userId: string) => async () => {
    const txn = () => stakingService.populateTransaction.withdraw(userId, stkBalance);
    return { txn: txn, type: 'WITHDRAW' };
  };
  return (
    <BasicForm
      title={intl.formatMessage(messages.caption, { asset: 'KLAP-KLAY LP' })}
      description={intl.formatMessage(messages.description)}
      amountFieldTitle={intl.formatMessage(messages.currentlyStaked)}
      maxAmount={(Math.floor(Number(stkBalance) * 100000) / 100000).toString()}
      currencySymbol={'KLAP-KLAY LP'}
      onSubmit={handleSubmit}
      submitButtonTitle={intl.formatMessage(messages.unstake)}
      getTransactionData={handleGetTransactions}
    />
  );
}

import React from 'react';
import { useIntl } from 'react-intl';

import Value from '../../../../components/basic/Value';
import LiquidityMiningBreakdown from '../../../../components/liquidityMining/LiquidityMiningBreakdown';
import TooltipCoinBreakdown from '../../../../components/TooltipCoinBreakdown';

import messages from './messages';
import staticStyles from './style';

interface StakingTopPanelProps {
  title: string;
  baseApr: number | string;
  boostedApr: number | string;
  klayApr: number | string;
  totalStake: number | string;
  poolShare: number | string;
}

export default function StakingTopPanel({
  title,
  baseApr,
  boostedApr,
  klayApr,
  totalStake,
  poolShare,
}: StakingTopPanelProps) {
  const intl = useIntl();

  const data = [
    {
      title: messages.totalStake,
      value: totalStake,
      symbol: 'KLAP-KLAY LP',
    },
    {
      title: messages.poolShare,
      value: poolShare,
      symbol: '%',
    },
  ];

  return (
    <div className="StakingTopPanel">
      <div className="StakingTopPanel__title">{title}</div>
      <div className="StakingTopPanel__values">
        {data.map((item) => (
          <div className="StakingTopPanel__value-inner" key={item.symbol}>
            <p className="StakingTopPanel__value-title">{intl.formatMessage(item.title)}</p>
            <Value
              value={item.value}
              symbol={item.symbol}
              maximumValueDecimals={2}
              minimumValueDecimals={2}
              color="white"
              tokenIcon={item.symbol === 'USD'}
            />
          </div>
        ))}
        <div className="StakingTopPanel__value-inner" key={'%'}>
          <p className="StakingTopPanel__value-title">{intl.formatMessage(messages.currentAPR)}</p>
          <p style={{ color: '#00CC88', display: 'flex', justifyContent: 'center', gridGap: 5 }}>
            <Value
              value={
                !isNaN(Number(boostedApr)) && !isNaN(Number(baseApr)) && !isNaN(Number(klayApr))
                  ? boostedApr == '0'
                    ? Number(baseApr) * 100 + Number(klayApr) * 100
                    : Number(boostedApr) * 100 + Number(baseApr) * 100 + Number(klayApr) * 100
                  : 0
              }
              symbol={'%'}
              maximumValueDecimals={2}
              minimumValueDecimals={2}
              color="white"
            />
            <TooltipCoinBreakdown
              baseApr={Number(baseApr)}
              boostedApr={Number(boostedApr)}
              klayApr={Number(klayApr)}
              showTypingIndicator={boostedApr == '0'}
            />
          </p>
        </div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true}>{`
        .StakingTopPanel {
          color: ${'#000'};
        }
      `}</style>
    </div>
  );
}

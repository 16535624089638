import React from 'react';
import { RowContainer, Subtitle, Title, Row } from './styles';
import moment from 'moment';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import messages from '../messages';

interface GeistInfoProps {
  listTokens: {
    amount: number;
    expiry: number;
  }[];
  totalAmount: number;
  totalAmountUSD: number;
}

export const CardTitle = styled(Title)`
  font-size: 24px !important;
  margin-bottom: 10px;
  font-weight: 700 !important;
`;

const GeistInfo = (props: GeistInfoProps) => {
  const intl = useIntl();
  return (
    <RowContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        paddingBottom: 0,
        gridGap: 16,
      }}
      id="vestingSchedule"
      hideborder
    >
      <CardTitle>{intl.formatMessage(messages.klapVestingSchedule)}</CardTitle>
      <Row showborderbottom>
        <Subtitle>{intl.formatMessage(messages.vesting)}</Subtitle>
        <Subtitle>{intl.formatMessage(messages.expiry)}</Subtitle>
      </Row>
      {props.listTokens.map((token, index) => (
        <Row
          style={{
            padding: '2px 0px',
            paddingBottom: index === props.listTokens.length - 1 ? 18 : 0,
          }}
          showborderbottom={index === props.listTokens.length - 1}
        >
          <Subtitle>
            <b style={{ color: 'black', marginRight: 5 }}>
              {token.amount.toLocaleString('en-us', {
                minimumFractionDigits: 0,
                maximumFractionDigits: 5,
              })}
            </b>{' '}
            KLAP
          </Subtitle>
          <Subtitle>
            <b style={{ color: 'black' }}>{moment(token.expiry * 1000).format('MM/DD/YY HH:mm')}</b>
          </Subtitle>
        </Row>
      ))}
      <div>
        <Subtitle style={{ marginTop: 5, marginBottom: 5 }}>
          {intl.formatMessage(messages.totalKlapVesting)}:{' '}
          <b style={{ marginLeft: 4, marginRight: 4, color: 'black' }}>
            {props.totalAmount.toLocaleString('en-us', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 5,
            })}
          </b>{' '}
          KLAP
        </Subtitle>
        <Subtitle>
          {intl.formatMessage(messages.totalUsdValue)}:{' '}
          <b style={{ marginLeft: 4, marginRight: 4, color: 'black' }}>
            $
            {props.totalAmountUSD.toLocaleString('en-us', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          </b>{' '}
          USD
        </Subtitle>
      </div>
    </RowContainer>
  );
};

export default GeistInfo;

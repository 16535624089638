import React, { useEffect, useState } from 'react';
import { CardInnerContainer } from '../../manage/components/styles';
import Preloader from '../../../components/basic/Preloader';
import DefaultButton from '../../../components/basic/DefaultButton';
import MonthButtons from '../../manage/components/MonthButtons';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import messages from './messages';
import messagesCore from '../messages';
import Logo from '../../../components/basic/Logo';
import moment from 'moment';
import AmountField from '../../../components/fields/AmountField';
import {
  useDynamicPoolDataContext,
  useStaticPoolDataContext,
} from '../../../libs/pool-data-provider';
import _ from 'lodash';
import { useGetLockdropInfo } from '../hooks';
import { useProtocolDataContext } from '../../../libs/protocol-data-provider';
import { useServices } from '../functions';
import ERC20ABI from '../../../contracts/ERC20.json';
import { formatEther } from 'ethers/lib/utils';
import { useMultiCall } from '../../../libs/pool-data-provider/hooks/use-multicall';
import Link from '../../../components/basic/Link';
import { BigNumber } from 'ethers';

const OuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
`;

const CardInnerContainerStyled = styled(CardInnerContainer)`
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-gap: 5px;
  padding: 16px;
`;

const StyledAmountField = styled(AmountField)`
  .BasicField input {
    color: #000 !important;
  }
`;

const LockdropModal = () => {
  const { data } = useGetLockdropInfo();
  const { reserves } = useDynamicPoolDataContext();
  const { currentMarketData } = useProtocolDataContext();
  const { userId } = useStaticPoolDataContext();
  const { depositTokens, approveTokens } = useServices();
  const [amount, setAmount] = useState('');
  const [maxAmount, setMaxAmount] = useState(0);
  const [showError, setShowError] = useState(false);
  const [asset, setAsset] = useState<{
    symbol: string;
    aToken: string;
    decimal: number;
  } | null>(null);
  const [isApproved, setIsApproved] = useState(false);
  const [time, setTime] = useState(1);
  const [contract, setContract] = useState(
    currentMarketData.addresses.LOCKDROP_CONTRACTS?.ONE_MONTH
  );
  const erc20 = useMultiCall([ERC20ABI]);
  const [isLoading, setIsLoading] = useState(false);
  const intl = useIntl();

  useEffect(() => {
    const getBalance = async () => {
      console.log('asset', asset!.aToken);
      console.log('userid', userId);
      const [balance, allowance, decimal] = await erc20(
        asset!.aToken,
        [['balanceOf', 'allowance', 'decimals']],
        [[[userId], [userId, contract], []]]
      );
      setMaxAmount(
        Number(
          BigNumber.from(balance[0])
            .div(BigNumber.from(10 ** (BigNumber.from(decimal[0]).toNumber() - 4)))
            .toNumber() / 10000
        )
      );
      setIsApproved(Number(formatEther(allowance[0])) > 0);
    };
    if (asset !== null && !isLoading) {
      getBalance();
    }
  }, [asset, isLoading, contract]);
  return (
    <div className="LockdropModal">
      <h1 className="LockdropModal__title">
        <Logo /> <b>{intl.formatMessage(messages.lockdropTitle)}</b>
      </h1>
      <div className="LockdropModal__topMessage">
        {intl.formatMessage(messages.lockdropMessage)}
        <Link
          absolute
          inNewWindow
          className="LockdropModal__readMore"
          to={intl.formatMessage(messages.mediumLink)}
        >
          {intl.formatMessage(messages.readMore)}
        </Link>
      </div>
      <div className="LockdropModal__aggregateContainer">
        <div className="LockdropModal__indivStatContainer">
          <div className="LockdropModal__stat" style={{ color: '#fff' }}>
            $
            {(
              data.metrics.one.tvl +
              data.metrics.three.tvl +
              data.metrics.six.tvl +
              data.metrics.twelve.tvl
            ).toLocaleString('en-us', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </div>
          <div className="LockdropModal__statTitle">
            {intl.formatMessage(messages.aggregateTVL)}
          </div>
        </div>
      </div>
      <div className="LockdropModal__statsContainer">
        <div className="LockdropModal__indivStatContainer">
          <div className="LockdropModal__stat" style={{ color: '#00CC88' }}>
            {data.klapRewards}
            <div className="LockdropModal__stat" style={{ marginLeft: 4, color: '#fff' }}>
              KLAP
            </div>
          </div>
          <div className="LockdropModal__stat" style={{ color: '#00CC88' }}>
            {data.klayRewards}{' '}
            <div className="LockdropModal__stat" style={{ marginLeft: 4, color: '#fff' }}>
              IN KLAY
            </div>
          </div>
          <div className="LockdropModal__statTitle">{intl.formatMessage(messages.toBeEarned)}</div>
        </div>
        <div className="LockdropModal__indivStatContainer">
          <div className="LockdropModal__stat" style={{ color: '#FF3366' }}>
            {moment(data.expirationDate * 1000).fromNow()}
          </div>
          <div className="LockdropModal__statTitle">{intl.formatMessage(messages.eventEndsIn)}</div>
        </div>
        <div className="LockdropModal__indivStatContainer">
          <div className="LockdropModal__stat" style={{ color: '#fff' }}>
            $
            {time === 1
              ? data.metrics.one.tvl.toLocaleString('en-us', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : time === 3
              ? data.metrics.three.tvl.toLocaleString('en-us', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : time === 6
              ? data.metrics.six.tvl.toLocaleString('en-us', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : data.metrics.twelve.tvl.toLocaleString('en-us', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
          </div>
          <div className="LockdropModal__statTitle">{intl.formatMessage(messages.tvl)}</div>
        </div>
      </div>
      <OuterContainer>
        <CardInnerContainerStyled>
          <div className="LockdropModal__questionContainer">
            <p className="LockdropModal__question">
              {intl.formatMessage(messages.assetQuestion)}
              <Link className="LockdropModal__readMore" inNewWindow to="/deposit">
                {intl.formatMessage(messages.note)}
              </Link>
            </p>
            <div style={{ marginBottom: 25 }}>
              <MonthButtons
                className="LockdropModal__logoContainer"
                timesOverride={_.map(reserves, (reserve) => 'a' + reserve.symbol)}
                selectedButton={asset ? 'a' + asset.symbol : ''}
                setSelectedButton={(asset) => {
                  const reserve = _.find(reserves, (reserve) => 'a' + reserve.symbol === asset);
                  console.log('reserve', reserve);
                  setAsset({
                    aToken: reserve?.aTokenAddress!,
                    decimal: reserve?.decimals!,
                    symbol: reserve?.symbol!,
                  });
                }}
                dontShowM
              />
            </div>
          </div>
          <div className="LockdropModal__questionContainer">
            <p className="LockdropModal__question">
              {intl.formatMessage(messages.stakeLengthQuestion)}
            </p>
            <div style={{ maxWidth: '50%', marginBottom: 25 }}>
              <MonthButtons
                timesOverride={[1, 3, 6, 12]}
                selectedButton={time}
                showMultiplier
                setSelectedButton={(tm) => {
                  setTime(tm);
                  if (tm === 1) {
                    setContract(currentMarketData.addresses.LOCKDROP_CONTRACTS?.ONE_MONTH);
                  } else if (tm === 3) {
                    setContract(currentMarketData.addresses.LOCKDROP_CONTRACTS?.THREE_MONTH);
                  } else if (tm === 6) {
                    setContract(currentMarketData.addresses.LOCKDROP_CONTRACTS?.SIX_MONTH);
                  } else if (tm === 12) {
                    setContract(currentMarketData.addresses.LOCKDROP_CONTRACTS?.TWELVE_MONTH);
                  }
                }}
              />
            </div>
          </div>
          <div className="LockdropModal__questionContainer">
            <p className="LockdropModal__question">
              {intl.formatMessage(messages.amountStakeQuestion)}
            </p>
            <StyledAmountField
              title={intl.formatMessage(messages.availableDeposit)}
              maxAmount={maxAmount}
              symbol={asset ? asset.symbol : ''}
              maxDecimals={4}
              value={'' + amount}
              onChange={(amount) => setAmount(amount)}
              onMaxButtonClick={() => setAmount('' + maxAmount)}
              style={{
                margin: 0,
                marginBottom: (time === 6 || time === 12) && !showError ? 25 : 3,
              }}
            />
            {(time === 1 || time === 3) && (
              <p
                className="LockdropModal__depositText"
                style={{ marginBottom: showError ? 0 : 20 }}
              >
                <p style={{ color: '#000', marginRight: 3 }}>
                  $
                  {time === 1
                    ? data.metrics.one.tvl.toLocaleString('en-us', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : data.metrics.three.tvl.toLocaleString('en-us', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </p>{' '}
                {intl.formatMessage(messages.deposited)} /
                <p style={{ color: '#000', marginRight: 3, marginLeft: 3 }}>
                  $
                  {time === 1
                    ? data.metrics.one.cap.toLocaleString('en-us', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : data.metrics.three.cap.toLocaleString('en-us', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                </p>{' '}
                {intl.formatMessage(messages.cap)}
              </p>
            )}
            {showError && (
              <p
                className="LockdropModal__depositText"
                style={{ color: 'red', marginRight: 3, marginBottom: 20, maxWidth: 650 }}
              >
                {intl.formatMessage(messages.errorMessage)}
              </p>
            )}
          </div>
          {isLoading ? (
            <div style={{ maxWidth: 80 }}>
              <Preloader smallSize />
            </div>
          ) : (
            <DefaultButton
              onClick={async () => {
                setIsLoading(true);
                if (isApproved) {
                  await depositTokens(
                    contract!,
                    Number(amount),
                    asset!.decimal,
                    asset!.aToken,
                    () => setShowError(true)
                  );
                  setIsLoading(false);
                } else {
                  await approveTokens(contract!, asset!.aToken);
                  setIsLoading(false);
                }
              }}
              disabled={
                asset === null ||
                Number(amount) > maxAmount ||
                moment().unix() > data.expirationDate
              }
              title={
                isApproved
                  ? intl.formatMessage(messagesCore.depositButton)
                  : intl.formatMessage(messagesCore.approve)
              }
              color="green"
            />
          )}
        </CardInnerContainerStyled>
      </OuterContainer>
      <style jsx={true} global={true}>{`
        .LockdropModal {
          min-width: 560px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
      `}</style>
    </div>
  );
};

export default LockdropModal;

import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import NoDataPanel from '../../components/NoDataPanel';
import GetVeKlap from './components/GetVeKlap';
import RowCard from './components/RowCard';
import GeistInfo, { CardTitle } from './components/GeistInfo';
import { Panel, SplitContainer, LowerRightCard } from './styles';
import { useGetManageInfo } from './hooks';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { useState } from 'react';
import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import { useServices } from './functions';
import { useServices as useServicesVe } from '../ve/functions';
import VestingKlap from './components/VestingKlap';
import ProvideLiquidityCard from './components/ProvideLiquidity';
import EarlyExitPanel from './components/EarlyExitPanel';
import Logo from '../../images/klapLogo.svg';
import VeInfo from './components/VeInfo';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import defaultMessages from '../../defaultMessages';
import messages from './messages';

export default function Manage() {
  const intl = useIntl();
  const { exitByLP, exitVeKlap, exitEarly, claimVeNFT } = useServices();
  const { claimFromMasterChef } = useServicesVe();
  const { currentAccount: userId } = useUserWalletDataContext();
  const { data, refresh } = useGetManageInfo();
  const { chainId } = useProtocolDataContext();
  const [isLockLoading, setIsLockLoading] = useState(false);
  const [isLockLPLoading, setIsLockLPLoading] = useState(false);
  const [isExitEarlyLoading, setIsExitEarlyLoading] = useState(false);
  //const [isBurnLoading, setIsBurnLoading] = useState(false);
  const [isUnlockedClaimLoading, setIsUnlockedClaimedLoading] = useState(false);

  if (!userId && chainId !== 250) {
    return (
      <NoDataPanel
        title={intl.formatMessage(defaultMessages.connectWallet)}
        description={intl.formatMessage(defaultMessages.pleaseConnectWallet)}
        withConnectButton={true}
      />
    );
  }
  return (
    <>
      <ScreenWrapper className="ManageKlap">
        <VestingKlap
          amountVested={data.klapEarned.toLocaleString('en-us', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 5,
          })}
        />
        <Panel style={{ marginTop: 20, marginBottom: 32 }}>
          <SplitContainer>
            <GetVeKlap
              aprs={data.veKlapAPRs}
              max={data.klapEarned}
              onLock={async (amount, lock_duration) => {
                setIsLockLoading(true);
                await exitVeKlap(amount, lock_duration);
                await refresh();
                setIsLockLoading(false);
              }}
              isLoading={isLockLoading}
            />
            {/*<StakedCard
              title="Burn KLAP"
              treasury={{
                items: [
                  {
                    amount: 1000,
                    symbol: 'USDC',
                    usdValue: 1,
                  },
                  {
                    amount: 1000,
                    symbol: 'FTM',
                    usdValue: 1,
                  },
                  {
                    amount: 1000,
                    symbol: 'KLAP',
                    usdValue: 1,
                  },
                ],
                maxKlapSupply: 100,
              }}
              question="How much do you want to burn?"
              walletBalance={data.tokenBalance}
              doTransaction={async (amount) => {
                setIsBurnLoading(true);
                if (data.isTokenApproved) {
                  //await stakeTokens(amount, true);
                } else {
                  //await approveTokens();
                }
                await refresh();
                setIsBurnLoading(false);
              }}
              buttonTitle={data.isTokenApproved ? 'Burn' : 'Approve'}
              isLoading={isBurnLoading}
            />*/}
          </SplitContainer>
          <SplitContainer>
            <ProvideLiquidityCard
              userFactor={data.userFactor}
              sumOfFactor={data.sumOfFactor}
              apr={data.veKlapKlayAPR}
              maxKlap={data.klapEarned}
              maxKlay={data.klayBalance}
              ratioKlapToKlay={data.klapTokenPrice / data.klayTokenPrice}
              onProvideLiquidity={async (amountKlap, amountKlay, lock_duration, slippage) => {
                setIsLockLPLoading(true);
                await exitByLP(amountKlap, amountKlay, lock_duration, slippage);
                await refresh();
                setIsLockLPLoading(false);
              }}
              isLoading={isLockLPLoading}
            />
          </SplitContainer>
        </Panel>
        <Panel style={{ flexDirection: 'column', gridGap: 32 }}>
          <EarlyExitPanel
            isLoading={isExitEarlyLoading}
            amountUsd={'' + data.klapEarned * data.klapTokenPrice}
            amount={'' + data.klapEarned}
            onClaim={async (amount) => {
              setIsExitEarlyLoading(true);
              await exitEarly(Number(amount));
              await refresh();
              setIsExitEarlyLoading(false);
            }}
          />
          <LowerRightCard>
            <VeInfo
              listTokens={data.veNFTs}
              onClaim={async (tokenId) => {
                await claimVeNFT(tokenId);
                await refresh();
              }}
              onClaimFromMasterchef={async () => {
                await claimFromMasterChef();
                await refresh();
              }}
            />
          </LowerRightCard>
          <LowerRightCard>
            <GeistInfo
              totalAmount={_.sumBy(data.vesting, (vest) => vest.amount)}
              totalAmountUSD={_.sumBy(data.vesting, (vest) => vest.amount) * data.klapTokenPrice}
              listTokens={data.vesting}
            />
          </LowerRightCard>
          <LowerRightCard>
            <CardTitle style={{ width: '100%' }}>
              {intl.formatMessage(messages.vestedKlap)}
            </CardTitle>
            <RowCard
              dontShowDivider
              tokenName={'KLAP'}
              title={intl.formatMessage(messages.vestedKlap)}
              description={intl.formatMessage(messages.klapThatHasFullyVested)}
              amount={data.klapFullyVested || 0}
              img={Logo}
              button={{
                title: intl.formatMessage(messages.claimKlap),
                onClick: async () => {
                  setIsUnlockedClaimedLoading(true);
                  await exitEarly(data.klapFullyVested);
                  await refresh();
                  setIsUnlockedClaimedLoading(false);
                },
              }}
              isLoading={isUnlockedClaimLoading}
            />
          </LowerRightCard>
        </Panel>
      </ScreenWrapper>
      <style jsx={true} global={true}>{`
        .ManageKlap {
          flex-direction: column;
          padding-top: 40px;
          padding-bottom: 40px;
          max-width: 1200px;

          @media only screen and (max-width: 1023px) {
            padding: 40px 40px;
          }
        }
      `}</style>
    </>
  );
}

import logo from '../../../images/klapLogo.svg';
import klayLogo from '../../../images/klay.png';

export const KlayLogo = ({ style, height = 40 }: any) => {
  return (
    <img style={{ ...style, height: height, marginLeft: 5 }} src={klayLogo} alt={'KLAY Logo'} />
  );
};

const Logo = ({ style, height = 40 }: any) => {
  return <img style={{ height: height, marginLeft: 5, ...style }} src={logo} alt={'KLAP Logo'} />;
};

export default Logo;

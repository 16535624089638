import React, { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { useThemeContext } from '@aave/aave-ui-kit';

import ContentWrapperWithTopLine from '../ContentWrapperWithTopLine';
import AssetsFilterPanel from '../../AssetsFilterPanel';
import MarketSwitcher from '../../market/MarketSwitcher';
import Row from '../../basic/Row';
import Value from '../../basic/Value';

import messages from './messages';
import staticStyles from './style';
import { FilterOptions } from '../../../modules/deposit/screens/DepositMain';
import Button from '../../Button';
import { Link } from 'react-router-dom';

interface DepositBorrowMainWrapperProps {
  children: ReactNode;
  items: ReactNode;
  contentTitle?: string;
  itemsTitle: string;
  isShowRightPanel?: boolean;
  searchValue: string;
  setSearchValue: (value: string) => void;
  filter: FilterOptions;
  setFilter: (value: FilterOptions) => void;
  withSwitchMarket?: boolean;
  emptyMessage: string;
  totalValue: string | number;
}

export default function DepositBorrowMainWrapper({
  children,
  items,
  contentTitle,
  itemsTitle,
  isShowRightPanel,
  searchValue,
  setSearchValue,
  filter,
  setFilter,
  withSwitchMarket,
  totalValue,
  emptyMessage,
}: DepositBorrowMainWrapperProps) {
  const intl = useIntl();
  const { sm } = useThemeContext();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: 16,
        height: '100%',
        width: '100%',
      }}
    >
      <AssetsFilterPanel
        options={Object.values(FilterOptions)}
        switchValue={filter}
        switchOnToggle={setFilter}
        searchValue={searchValue}
        searchOnChange={setSearchValue}
        darkOnDarkMode={true}
      />
      <div className="DepositBorrowMainWrapper">
        <div className="DepositBorrowMainWrapper__left-inner">
          {!sm && (
            <ContentWrapperWithTopLine title={contentTitle} className="">
              <div className="DepositBorrowMainWrapper__content">{children}</div>
            </ContentWrapperWithTopLine>
          )}

          {sm && <div className="DepositBorrowMainWrapper__mobile--content">{children}</div>}
        </div>

        <div className="DepositBorrowMainWrapper__right-inner">
          <div style={{ color: '#828282', fontWeight: 300 }}>{itemsTitle}</div>
          <ContentWrapperWithTopLine
            style={{
              boxShadow:
                '0px 4px 24px rgba(88, 102, 126, 0.08), 0px 1px 2px rgba(88, 102, 126, 0.12)',
              padding: 16,
              borderRadius: 16,
            }}
          >
            {totalValue === 0 ? (
              <div style={{ width: '100%', textAlign: 'center', padding: 10 }}>{emptyMessage}</div>
            ) : (
              <>
                <div className="DepositBorrowMainWrapper__items">{items}</div>
                <Row
                  className="DepositBorrowMainWrapper__total"
                  title={intl.formatMessage(messages.total)}
                >
                  <Value
                    value={totalValue}
                    tokenIcon={true}
                    withoutSymbol={true}
                    symbol="USD"
                    maximumValueDecimals={2}
                  />
                </Row>
              </>
            )}
          </ContentWrapperWithTopLine>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Link to="/dashboard">
              <Button name={intl.formatMessage(messages.goToDashboard)} />
            </Link>
          </div>
        </div>

        <style jsx={true} global={true}>
          {staticStyles}
        </style>
        <style jsx={true} global={true}>{`
          .DepositBorrowMainWrapper {
            &__items {
              border-bottom: 0.5px solid #828282;
            }

            &__caption {
              color: #000;
            }
            .DepositBorrowMainWrapper__changeMarket-inner {
              color: #000;
            }

            &__title {
              color: #000 !important;
            }
          }
        `}</style>
      </div>
    </div>
  );
}

import { BasicModal } from '@aave/aave-ui-kit';
import styled from 'styled-components';

const Modal = styled(BasicModal)`
  .ConnectWalletModal {
    background-color: white !important;
  }

  .BasicModal__close img {
    width: 20px !important;
    height: 20px !important;
  }

  .BasicModal__close-wrapper {
    top: 24px;
    right: 44px;
  }
`;

export default Modal;

import { defineMessages } from 'react-intl';

export default defineMessages({
  description: 'Connect your wallet and jump into DeFi',

  titleBrowserWallet: 'Metamask',
  noBrowserBrowserWallet: 'No browser wallet detected.',

  continueWithoutWallet: 'or continue without wallet',
  errorCaption: 'Error detected',

  needHelp: 'Need help connecting a wallet? {readOurFAQ}',
  readOurFAQ: 'Read our FAQ',

  disclaimerBottomText:
    '{disclaimer} Wallets are provided by External Providers and by selecting you agree to Terms of those Providers. Your access to the wallet might be reliant on the External Provider being operational.',
  disclaimer: 'Disclaimer:',
  firstMessage:
    'For a optimized experience using Klaytn and Klap, we suggest the following wallets:',
  secondMessage: 'Not optimal experience but still usable for Klaytn and Klap.',
  metamaskGuide: 'Guide to Metamask on Klaytn:',
});

import styled from 'styled-components';
import AmountField from '../../../components/fields/AmountField';

export const RowContainer = styled.div<{ hideborder: boolean }>`
  display: grid;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  grid-template-columns: 3fr 1fr 1fr;
  ${({ hideborder }) => (hideborder ? '' : 'border-bottom: 1px solid rgb(236, 236, 236);')}
`;

export const Title = styled.div`
  font-size: 16px !important;
  font-weight: bold !important;
`;

export const Subtitle = styled.div`
  font-size: 16px !important;
  color: #76808f;
  display: flex;
  align-items: center;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 6px;
`;

export const Row = styled.div<{ showborderbottom: boolean }>`
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 100%;
  padding-bottom: 15px;
  ${({ showborderbottom }) =>
    showborderbottom ? `border-bottom: 1px solid rgb(236, 236, 236);` : ''}
`;

export const TitleSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledAmountField = styled(AmountField)`
  .BasicField input {
    color: #000 !important;
  }
`;

export const TitleCard = styled.div`
  font-size: 24px !important;
  font-weight: bold !important;
  color: #000 !important;
  display: flex;
  align-items: center;
`;

export const APRContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  grid-gap: 5px;
`;

export const DescriptionTitle = styled.div`
  font-size: 14px;
  color: #76808f;
  font-weight: bold;
  text-align: left;
  width: 100%;
  line-height: 1.4;
`;

export const Description = styled.li`
  font-size: 14px;
  color: #76808f;
  list-style: disc;
  margin-left: 26px;
  line-height: 1.4;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 4px;
`;

export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  grid-gap: 20px;
  min-width: 300px;

  @media only screen and (max-width: 450px) {
    min-width: 0px;
    max-width: 100%;
    width: 100%;
  }
`;

export const ExitContainer = styled.div`
  padding: 24px;
  border: 1px solid #ff3366;
  border-radius: 16px;
  grid-gap: 11px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
  }
`;

export const CardInnerContainer = styled(ExitContainer)`
  border: 1px solid #d3d8e0;
  display: block;
`;

export const CardEndContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  grid-gap: 20px;
  align-items: center;
`;

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .LockdropModal {
    width: 100% !important;
    max-width: 600px !important;
    margin-bottom: 15px;
    justify-content: flex-start;
    @include respond-to(lg) {
      max-width: 100% !important;
    }

    @include respond-to(sm) {
      min-width: 100%;
    }

    &__readMore {
      margin-left: 4px;
      color: blue;
      text-decoration: underline;
    }

    &__depositText {
      font-size: 14px;
      color: #76808f;
      display: flex;
    }

    &__logoContainer {
      max-width: 500px;
      @include respond-to(md) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &__aggregateContainer {
      margin-bottom: 12px;
      height: 65px;
      .LockdropModal__indivStatContainer {
        height: 100%;
      }
    }

    &__statsContainer {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 16px;
      margin-bottom: 16px;
      width: 100%;
    }

    &__stat {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 2px;
      line-height: 0.87;
      display: flex;
    }

    &__statTitle {
      font-weight: 400;
      font-size: 12px;
      color: #aeb4bc;
    }

    &__indivStatContainer {
      border-radius: 16px;
      background-color: #121519;
      padding: 4px 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-height: 95px;
    }

    &__topMessage {
      width: 100%;
      text-align: left;
      font-weight: 400;
      padding: 16px 0px;
      color: #474d57;
    }

    &__title {
      display: flex;
      align-items: center;
      grid-gap: 6px;
    }

    &__questionContainer {
      width: 100%;
    }

    &__question {
      text-align: left;
      margin-bottom: 10px;
    }
  }

  .TableAction,
  .TableAction .ConnectButton__inner,
  .TableAction .ConnectButton__inner span {
    height: 30px;
    max-width: 85px;
    min-width: 85px !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .MobileTableAction,
  .MobileTableAction .ConnectButton__inner,
  .MobileTableAction .ConnectButton__inner span {
    height: 30px;
    max-width: 165px;
    min-width: 165px !important;
    font-weight: 300 !important;
    font-size: 12px !important;
    padding: 0px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .LockedTable {
    display: flex;
    width: 100%;
    flex-direction: column;
    grid-gap: 16px;
    justify-content: space-between;

    &__PreloaderContainer {
      min-width: 85px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include respond-to(md) {
      box-shadow: none;
      padding: 0px;
      border-radius: 0px;
      grid-gap: 16px;
      max-height: fit-content;
    }

    &__listcontainer {
      overflow: scroll;
      grid-gap: 16px;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &__buttonContainer {
      display: flex;
      grid-gap: 6px;
      justify-content: center;
      align-items: center;
    }

    &__no-assets-container {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      grid-gap: 7px;

      @include respond-to(md) {
        padding: 16px;
      }
    }

    &__no-assets-emoji {
      font-size: 80px;
      line-height: 0.9;
    }

    &__no-assets-text {
      color: #474d57;
    }

    &__header {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
      justify-content: space-between;
      padding: 0px 12px;
    }

    &__headertitle {
      display: flex;
      color: #76808f;
    }

    &__titlecontainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__row-container {
      background: #f2f5f9;
      border-radius: 16px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 2fr;
      padding: 12px 16px;
    }

    &__status-col {
      display: flex;
      grid-gap: 8px;
      align-items: center;
    }

    &__subtitle {
      display: flex;
      align-items: center;
    }

    &__lock {
      margin-top: -2px;
    }
  }

  .MobileLockedTable {
    &__listcontainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      @include respond-to(sm) {
        grid-template-columns: 1fr;
      }
    }

    &__no-asset-container {
      grid-template-columns: 1fr;
    }

    &__container {
      width: 100%;
      box-shadow: 0px 4px 24px rgba(88, 102, 126, 0.08), 0px 1px 2px rgba(88, 102, 126, 0.12);
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      grid-gap: 16px;
      padding: 16px;
    }

    &__row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__actionContainer {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
    }

    &__title {
      color: #828282;
    }
  }
`;

export default staticStyles;

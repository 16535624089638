import { useState } from 'react';
import { useConnectionStatusContext } from '../../libs/connection-status-provider';
import { usePolling } from '../../libs/hooks/use-polling';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { useMultiCall } from '../../libs/pool-data-provider/hooks/use-multicall';
import MultiFeeABI from '../../contracts/MultiFeeDistribution.json';
import ERC20ABI from '../../contracts/ERC20.json';
import VE_ABI from '../../contracts/Ve.json';
import CHEF_INCENTIVES_ABI from '../../contracts/ChefIncentivesController.json';
import MULTIREWARDER_ABI from '../../contracts/MultiRewarder.json';
import MASTERCHEF_ABI from '../../contracts/Masterchef.json';
import { useStaticPoolDataContext } from '../../libs/pool-data-provider';
import { BigNumber, providers } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import _ from 'lodash';
import { useWeb3React } from '@web3-react/core';
import { getKlapKlayPrice } from '../../helpers/get-klap-price';
import { ONE_WEEK, ONE_MONTH } from './functions';

const POLLING_INTERVAL = 120 * 1000;

interface InfoData {
  klapBalance: number;
  klayBalance: number;
  veNFTs: {
    amount: number;
    expiry: number;
    tokenId: string;
    isKlap: boolean;
    earnedKlayKlapLP?: number;
    earnedKlap: number;
  }[];
  vesting: {
    expiry: number;
    amount: number;
  }[];
  klapFullyVested: number;
  klapEarned: number;
  pendingReward: number;
  veKlapAPRs: number[];
  veKlapKlayAPR: {
    base: number;
    boosted: number | string;
    klay: number;
  }[];
  klayTokenPrice: number;
  klapTokenPrice: number;
  klapVeApproved: boolean;
  klapLPBalance: number;
  userFactor: BigNumber;
  sumOfFactor: BigNumber;
  userAndVeDepositedAmount: BigNumber;
  klapVeLPApproved: boolean;
  klapClaimswapApproved: boolean;
  baseDialutingAmount: BigNumber;
  lpPrice: number;
  veUserFactor: BigNumber;
}

interface ReturnData {
  loading: boolean;
  error: any;
  data: InfoData;
  refresh: () => void;
}

export const useGetManageInfo: () => ReturnData = () => {
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  let { userId } = useStaticPoolDataContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<InfoData>({
    klapBalance: 0,
    klayBalance: 0,
    veNFTs: [],
    vesting: [],
    klapEarned: 0,
    pendingReward: 0,
    veKlapAPRs: [],
    veKlapKlayAPR: [],
    klayTokenPrice: 0,
    klapTokenPrice: 0,
    klapFullyVested: 0,
    klapVeApproved: false,
    klapLPBalance: 0,
    klapVeLPApproved: false,
    klapClaimswapApproved: false,
    userFactor: BigNumber.from(0),
    sumOfFactor: BigNumber.from(0),
    userAndVeDepositedAmount: BigNumber.from(0),
    baseDialutingAmount: BigNumber.from(0),
    lpPrice: 0,
    veUserFactor: BigNumber.from(0),
  });
  const { currentMarketData } = useProtocolDataContext();
  const { isRPCActive } = useConnectionStatusContext();
  const multicall = useMultiCall([
    MultiFeeABI,
    ERC20ABI,
    ERC20ABI,
    VE_ABI,
    MULTIREWARDER_ABI,
    MASTERCHEF_ABI,
  ]);
  const multicall_second = useMultiCall([VE_ABI, MultiFeeABI]);

  const fetchData = async () => {
    if (userId === undefined || userId === null || userId === '') return;
    setLoading(true);
    try {
      const [
        [
          [withdrawableBalance, earnedBalances, fullyVestedAmount],
          [tokenBalance, allowance, claimswapAllowance, totalSupply],
          [balanceLP, [totalStaked], allowanceLP],
          [nftBalance, userFactor],
          [pendingKlayTokens, klayTokenPerBlock],
          [poolInfo, rewardsPerSecond, dialutingRepartition, userInfo, pendingReward],
        ],
        klayBalance,
        { klap, klay, lp },
      ] = await Promise.all([
        multicall(
          [
            currentMarketData.addresses.MULTIFEE_DISTRIBUTION!,
            currentMarketData.addresses.MAIN_TOKEN!,
            currentMarketData.addresses.LP_TOKEN!,
            currentMarketData.addresses.VE!,
            currentMarketData.addresses.MULTIREWARDER!,
            currentMarketData.addresses.MASTERCHEF!,
          ],
          [
            ['withdrawableBalance', 'earnedBalances', 'fullyVestedAmount'],
            ['balanceOf', 'allowance', 'allowance', 'balanceOf'],
            ['balanceOf', 'balanceOf', 'allowance'],
            ['balanceOf', 'getUserFactor'],
            ['pendingTokens', 'retrieveTokenPerBlock'],
            ['poolInfo', 'rewardsPerSecond', 'dialutingRepartition', 'userInfo', 'claimableReward'],
          ],
          [
            [[userId], [userId], [userId]],
            [
              [userId],
              [userId, currentMarketData.addresses.VE!],
              [userId, currentMarketData.addresses.CLAIMSWAP_ROUTER!],
              [currentMarketData.addresses.MASTERCHEF!],
            ],
            [
              [userId],
              [currentMarketData.addresses.MASTERCHEF!],
              [userId, currentMarketData.addresses.VE!],
            ],
            [[userId], [userId]],
            [
              [currentMarketData.addresses.LP_TOKEN!, userId],
              [currentMarketData.addresses.LP_TOKEN!, 0],
            ],
            [
              [currentMarketData.addresses.LP_TOKEN!],
              [],
              [],
              [currentMarketData.addresses.LP_TOKEN!, userId],
              [userId, [currentMarketData.addresses.LP_TOKEN!]],
            ],
          ]
        ),
        provider!.getBalance(userId),
        getKlapKlayPrice(currentMarketData.addresses.LP_TOKEN!),
      ]);
      const klayPrice = klay;
      const klapPrice = klap;
      const lpPrice = lp;

      const length = BigNumber.from(nftBalance[0]).toNumber();
      const methods = _.map(_.range(0, length), () => 'tokenOfOwnerByIndex');
      const args = _.map(_.range(0, length), (id) => [userId, id]);
      const [tokenIds] = await multicall_second(
        [currentMarketData.addresses.VE!],
        [methods],
        [args]
      );
      const methodsLocked = _.map(_.range(0, length), () => 'locked');
      const methodsAmount = _.map(_.range(0, length), () => 'amount_stored');
      const methodsType = _.map(_.range(0, length), () => 'tokenType');
      const methodsVeRewards = _.map(_.range(0, length), () => 'veStakingRewardsClaimable');
      const methodsHypotheticalNFT = _.map(_.range(0, 4), () => 'balanceOfHypotheticalNFT');
      const methodsGetVeRewardAmount = _.map(_.range(0, 4), () => 'get_ve_reward_amount');

      const args_rewards = _.map([3, 6, 12, 24], (month) => {
        console.log('week amount', Math.floor((month * ONE_MONTH) / ONE_WEEK));
        return ['1000000000000000000', Math.floor((month * ONE_MONTH) / ONE_WEEK)];
      });
      //console.log('ids', tokenIds);
      const args_locked = _.map(_.range(0, length), (id) => [tokenIds[id][0]]);
      //console.log('args', args_locked);
      const [veResponse, multiFeeResponse] = await multicall_second(
        [currentMarketData.addresses.VE!, currentMarketData.addresses.MULTIFEE_DISTRIBUTION!],
        [
          [
            ...methodsLocked,
            ...methodsType,
            ...methodsAmount,
            ...methodsHypotheticalNFT,
            ...methodsGetVeRewardAmount,
          ],
          [...methodsVeRewards],
        ],
        [
          [...args_locked, ...args_locked, ...args_locked, ...args_rewards, ...args_rewards],
          [...args_locked],
        ]
      );
      console.log('ve response', veResponse);
      const locked = veResponse.splice(0, length);
      const type = veResponse.splice(0, length);
      const amount = veResponse.splice(0, length);
      const hypotheticalAmounts = veResponse.splice(0, 4);
      const rewardAmounts = veResponse.splice(0, 4);
      console.log('rewardAmounts', rewardAmounts);
      console.log('nfts', tokenIds);
      let isFirstLP = true;
      const veData: any[] = _.map(_.range(0, length), (i) => {
        const isKlap = Number(type[i]) === 1;
        console.log('pending rewards', pendingReward);
        const pending = parseFloat(formatEther(pendingReward[0]));
        const earnedKlayKlapLP = pending;
        const currentRewards = parseFloat(formatEther(multiFeeResponse[i][0]));

        console.log('earhed klap', currentRewards);
        const returnVal = {
          amount: parseFloat(formatEther(amount[i][0])),
          expiry: BigNumber.from(locked[i][1]).toNumber(),
          tokenId: BigNumber.from(tokenIds[i][0]).toString(),
          isKlap: isKlap,
          earnedKlayKlapLP: isFirstLP ? earnedKlayKlapLP : undefined,
          earnedKlap: currentRewards,
        };
        if (!isKlap) {
          isFirstLP = false;
        }
        return returnVal;
      });
      const baseDialutingAmount = BigNumber.from(rewardsPerSecond[0])
        .mul(86400 * 365)
        .mul(BigNumber.from((klapPrice * 1000).toFixed(0)))
        .mul(dialutingRepartition[0])
        .div(1000 * 1000);

      const baseNonDialutingAmount = BigNumber.from(rewardsPerSecond[0])
        .mul(86400 * 365)
        .mul(BigNumber.from((klapPrice * 1000).toFixed(0)))
        .mul(BigNumber.from(1000).sub(dialutingRepartition[0]))
        .div(1000 * 1000);

      const totalSupplyLP = BigNumber.from(totalSupply[0]).eq(BigNumber.from(0))
        ? 1
        : BigNumber.from(totalSupply[0]);
      const data: InfoData = {
        klapVeLPApproved: BigNumber.from(allowanceLP[0]).gt(0),
        klapLPBalance: Number(formatEther(balanceLP[0])),
        klayTokenPrice: klayPrice,
        klapTokenPrice: klapPrice,
        klapBalance: parseFloat(formatEther(tokenBalance[0])),
        klayBalance: parseFloat(formatEther(klayBalance)),
        klapEarned:
          parseFloat(formatEther(withdrawableBalance[0])) +
          parseFloat(formatEther(withdrawableBalance[1])),
        vesting: _.map(earnedBalances[1], (i) => ({
          expiry: BigNumber.from(i[1]).toNumber(),
          amount: parseFloat(formatEther(i[0])),
        })).filter((i) => i.expiry !== 0),
        klapFullyVested: parseFloat(formatEther(fullyVestedAmount[0])),
        veNFTs: veData,
        pendingReward: parseFloat(formatEther(pendingReward[0])),
        klapVeApproved: BigNumber.from(allowance[0]).gt(0),
        klapClaimswapApproved: BigNumber.from(claimswapAllowance[0]).gt(0),
        veKlapAPRs: _.map(rewardAmounts, (reward) => {
          console.log('reward', formatEther(reward[0]));
          console.log('rewad amount', BigNumber.from(reward[0]).toString());
          return (
            (Number(formatEther(reward[0])) + Number(formatEther('500000000000000000'))) /
            Number(formatEther('500000000000000000'))
          );
        }),
        veUserFactor: BigNumber.from(userFactor[0]),
        veKlapKlayAPR: _.map(hypotheticalAmounts, () => ({
          base:
            Number(formatEther(baseNonDialutingAmount)) /
            (Number(formatEther(totalStaked)) === 0
              ? 1
              : Number(formatEther(totalStaked)) * lpPrice),
          boosted:
            Number(formatEther(userFactor[0])) === 0 || Number(formatEther(userInfo[0])) === 0
              ? '0'
              : (Number(formatEther(baseDialutingAmount)) *
                  (BigNumber.from(userInfo[3])
                    .mul(1000000)
                    .div(BigNumber.from(poolInfo[3]))
                    .toNumber() /
                    1000000)) /
                (Number(formatEther(BigNumber.from(userInfo[0]).add(userInfo[1]))) * lpPrice),
          klay:
            Number(
              formatEther(
                BigNumber.from(klayTokenPerBlock[0])
                  .mul(86400 * 365)
                  .div(totalSupplyLP)
              )
            ) *
            (klayPrice / lpPrice),
        })),
        userFactor: BigNumber.from(userInfo[3]),
        sumOfFactor: BigNumber.from(poolInfo[3]),
        userAndVeDepositedAmount: BigNumber.from(userInfo[0]).add(userInfo[1]),
        baseDialutingAmount,
        lpPrice,
      };
      console.log('boosted', data.veKlapKlayAPR);
      console.log('cabed', data.veKlapAPRs);
      setData(data);
      setError(false);
    } catch (e) {
      console.error('error manage', e);
      setError(e.message);
    }
    setLoading(false);
  };

  usePolling(fetchData, POLLING_INTERVAL, !isRPCActive, []);

  return {
    loading,
    error,
    data: data,
    refresh: fetchData,
  };
};

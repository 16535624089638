import { useState } from 'react';
import {
  APRContainer,
  CardEndContainer,
  CardInnerContainer,
  Description,
  DescriptionTitle,
  FieldContainer,
  ListContainer,
  StyledAmountField,
  TitleCard,
} from './styles';
import Preloader from '../../../components/basic/Preloader';
import { CardContainer } from '../../../components/CardContainer';
import Logo from '../../../components/basic/Logo';
import DefaultButton from '../../../components/basic/DefaultButton';
import MonthButtons from './MonthButtons';
import { useIntl } from 'react-intl';
import messages from '../messages';

interface GetVeKlapCardProps {
  aprs: number[];
  onLock: (amount: number, lock_duration: number) => void;
  max: number;
  isLoading: boolean;
  isNotApproved?: boolean;
  showAPR?: boolean;
}

const GetVeKlapCard = (props: GetVeKlapCardProps) => {
  const intl = useIntl();
  const [amount, setAmount] = useState('');
  const [selectedButton, setSelectedButton] = useState(3);
  const [apr, setApr] = useState(0);

  const handleAmountChange = (e: any) => {
    setAmount(e);
  };

  const handleMaxButtonClick = () => {
    setAmount('' + props.max);
  };

  return (
    <CardContainer
      style={{
        display: 'flex',
        flexDirection: 'column',
        gridGap: 20,
        padding: '16px 24px 32px 24px',
        alignItems: 'center',
        height: '100%',
        justifyContent: 'space-between',
      }}
    >
      <CardEndContainer>
        <TitleCard>
          {intl.formatMessage(messages.getVeKlap)} <Logo />
        </TitleCard>
        <APRContainer>
          <p
            style={{
              fontWeight: 'bold',
              fontSize: 21,
              color: '#00CC88',
              display: 'flex',
              gridGap: 8,
            }}
          >
            {props.showAPR ? 'APR' : ''}{' '}
            {!isNaN(props.aprs[apr])
              ? (props.aprs[apr] * 100).toLocaleString('en-us', {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })
              : 0}
            %
          </p>
          {!props.showAPR && (
            <DescriptionTitle style={{ fontWeight: 'normal', width: 'fit-content' }}>
              {intl.formatMessage(messages.earlyExit)}
            </DescriptionTitle>
          )}
        </APRContainer>
        <ListContainer>
          <DescriptionTitle>{intl.formatMessage(messages.benefits)}</DescriptionTitle>
          <ul style={{ width: '100%' }}>
            <Description>{intl.formatMessage(messages.benefitsVeKlap1)}</Description>
            <Description>{intl.formatMessage(messages.benefitsVeKlap2)}</Description>
            <Description>{intl.formatMessage(messages.benefitsVeKlap3)}</Description>
            <Description>{intl.formatMessage(messages.benefitsVeKlap4)}</Description>
          </ul>
        </ListContainer>
        <CardInnerContainer>
          <FieldContainer>
            <div style={{ maxWidth: '100%' }}>
              <p style={{ marginBottom: 8 }}>{intl.formatMessage(messages.lockQuestion)}</p>
              <StyledAmountField
                title={intl.formatMessage(messages.availableToLock)}
                maxAmount={props.max}
                symbol={'KLAP'}
                maxDecimals={4}
                value={amount}
                onChange={handleAmountChange}
                onMaxButtonClick={handleMaxButtonClick}
                style={{ margin: 0 }}
              />
            </div>
            <div>
              <p style={{ marginBottom: 8 }}>{intl.formatMessage(messages.lengthQuestion)}</p>
              <MonthButtons
                selectedButton={selectedButton}
                setSelectedButton={(num) => {
                  setSelectedButton(num);
                  switch (num) {
                    case 3:
                      setApr(0);
                      break;
                    case 6:
                      setApr(1);
                      break;
                    case 12:
                      setApr(2);
                      break;
                    default:
                      setApr(3);
                      break;
                  }
                }}
              />
            </div>
          </FieldContainer>
        </CardInnerContainer>
        {/*<TitleSection>
          <Description>Wallet Balance:</Description>
          <Description>
            <b>
              {props.walletBalance.toLocaleString('en-us', {
                minimumSignificantDigits: 1,
                maximumSignificantDigits: 5,
              })}
            </b>{' '}
            KLAP
          </Description>
            </TitleSection>*/}
      </CardEndContainer>
      {props.isLoading ? (
        <div style={{ maxWidth: 80 }}>
          <Preloader smallSize />
        </div>
      ) : (
        <DefaultButton
          disabled={
            parseFloat(amount) > props.max || amount.length === 0 || parseFloat(amount) === 0
          }
          onClick={() => props.onLock(parseFloat(amount), selectedButton)}
          title={
            props.isNotApproved
              ? intl.formatMessage(messages.approve)
              : intl.formatMessage(messages.lock)
          }
          color="green"
        />
      )}
    </CardContainer>
  );
};

export default GetVeKlapCard;

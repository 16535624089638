import { useState } from 'react';
import { useConnectionStatusContext } from '../../libs/connection-status-provider';
import { usePolling } from '../../libs/hooks/use-polling';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { useMultiCall } from '../../libs/pool-data-provider/hooks/use-multicall';
import VeABI from '../../contracts/Ve.json';
import { useStaticPoolDataContext } from '../../libs/pool-data-provider';
import { BigNumber } from 'ethers';
import _ from 'lodash';

const POLLING_INTERVAL = 120 * 1000;

export interface InfoData {
  tokenId: string;
  balance: string;
  voted: boolean;
}

interface ReturnData {
  loading: boolean;
  error: any;
  data: InfoData[];
  refresh: () => void;
}

export const useGetVoteInfo: () => ReturnData = () => {
  const { userId } = useStaticPoolDataContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<InfoData[]>([]);
  const { currentMarketData } = useProtocolDataContext();
  const { isRPCActive } = useConnectionStatusContext();
  const multicall = useMultiCall([VeABI]);

  const fetchData = async () => {
    if (userId === undefined || userId === null || userId === '') return;
    try {
      setLoading(true);
      const [nftBalance] = await multicall(
        currentMarketData.addresses.VE!,
        [['balanceOf']],
        [[[userId]]]
      );
      const length = BigNumber.from(nftBalance[0][0]).toNumber();

      if (length === 0) {
        setData([]);
        setError(false);
        setLoading(false);
        return;
      }

      const methods = _.map(_.range(0, length), () => 'tokenOfOwnerByIndex');
      const args = _.map(_.range(0, length), (id) => [userId, id]);
      const [tokenIds] = await multicall(currentMarketData.addresses.VE!, [methods], [args]);

      const methodsVoted = _.map(_.range(0, length), () => 'voted');
      const methodsBalance = _.map(_.range(0, length), () => 'balanceOfNFT');
      const argsVoted = _.map(_.range(0, length), (id) => [tokenIds[id][0]]);

      const [veResponse] = await multicall(
        currentMarketData.addresses.VE!,
        [[...methodsVoted, ...methodsBalance]],
        [[...argsVoted, ...argsVoted]]
      );
      console.log('veresponse');
      const voted = veResponse.splice(0, length);
      const balance = veResponse.splice(0, length);
      console.log('here');
      const data: InfoData[] = _.map(_.range(0, length), (i) => {
        return {
          tokenId: BigNumber.from(tokenIds[i][0]).toString(),
          voted: voted[i][0],
          balance: BigNumber.from(balance[i][0]).toString(),
        };
      });
      setData(data);
      setError(false);
    } catch (e) {
      console.log('uh oh');
      console.log('e', e);
      setError(e.message);
    }
    setLoading(false);
  };

  usePolling(fetchData, POLLING_INTERVAL, !isRPCActive, []);

  return {
    loading,
    error,
    data: data,
    refresh: fetchData,
  };
};

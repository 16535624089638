import _ from 'lodash';
import { TimeContainer, TimeButton } from '../../manage/components/MonthButtons';

const TOLERANCE = [0.1, 0.5, 1, 2];

interface SlippageButtonsProps {
  selectedButton: number;
  setSelectedButton: (amount: number) => void;
}

const SlippageButtons = ({ selectedButton, setSelectedButton }: SlippageButtonsProps) => {
  return (
    <TimeContainer>
      {_.map(TOLERANCE, (time) => (
        <TimeButton
          key={'' + time}
          selected={selectedButton === time}
          onClick={() => setSelectedButton(time)}
        >
          {time}%
        </TimeButton>
      ))}
    </TimeContainer>
  );
};

export default SlippageButtons;

import React, { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { valueToBigNumber, Stake } from '@aave/protocol-js';
import { gradient, rgba, useThemeContext } from '@aave/aave-ui-kit';

import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import ScreenWrapper from '../../../../components/wrappers/ScreenWrapper';
import ContentWrapper from '../../../../components/wrappers/ContentWrapper';
import DefaultButton from '../../../../components/basic/DefaultButton';
import Link from '../../../../components/basic/Link';
import Row from '../../../../components/basic/Row';
import Value from '../../../../components/basic/Value';
import ValuePercent from '../../../../components/basic/ValuePercent';
import NoDataPanel from '../../../../components/NoDataPanel';
import StakingTopPanel from '../StakingTopPanel';
import SidePanelCard from '../SidePanelCard';
import MainnetWarning from '../../../../components/MainnetWarning';

import messages from './messages';
import staticStyles from './style';
import { CardContainer } from '../../../../components/CardContainer';
import { HorizontalDivider } from '../../../../components/Divider';

interface StakingWrapperProps {
  children: ReactNode;
}

export default function StakingWrapper({ children }: StakingWrapperProps) {
  const intl = useIntl();
  const { currentTheme } = useThemeContext();
  const { userId } = useStaticPoolDataContext();
  const { data } = useStakeDataContext();
  const [currentAsset] = useState<Stake>(Stake.bpt);
  const selectedStakeData = data[Stake.bpt];

  const userIncentivesToClaimInUSD = valueToBigNumber(selectedStakeData.userIncentivesToClaim)
    .multipliedBy(selectedStakeData.rewardTokenPriceUsd)
    .toFixed(2);

  const claimableKlayInUSD = valueToBigNumber(selectedStakeData.klayClaimableReward)
    .multipliedBy(selectedStakeData.klayPrice)
    .toFixed(2);

  const lpStakedInUSD = valueToBigNumber(selectedStakeData.stakeTokenUserBalance)
    .multipliedBy(selectedStakeData.lpPrice)
    .toFixed(2);

  const gradientBackground = gradient(
    90,
    `${currentTheme.secondary.rgb}, 1`,
    0,
    `${currentTheme.primary.rgb}, 1`,
    100
  );
  const disabledColor = rgba(`${currentTheme.textDarkBlue.rgb}, 0.2`);

  return (
    <ScreenWrapper
      className="StakingWrapper"
      pageTitle={intl.formatMessage(messages.pageTitle)}
      isTopLineSmall={true}
    >
      <MainnetWarning />

      <div className="StakingWrapper__content-inner">
        <div
          className={classNames('StakingWrapper__content-left', {
            StakingWrapper__contentRightActive: true,
          })}
        >
          <StakingTopPanel
            title={intl.formatMessage(messages.pageTitle)}
            totalStake={
              Number(selectedStakeData.stakeTokenUserBalance) +
              Number(selectedStakeData.lockedTokenUserBalance)
            }
            poolShare={
              (100 *
                (Number(selectedStakeData.stakeTokenUserBalance) +
                  Number(selectedStakeData.lockedTokenUserBalance))) /
              Number(selectedStakeData.stakeTokenTotalSupply)
            }
            boostedApr={Number(selectedStakeData.dialutedApr)}
            baseApr={Number(selectedStakeData.baseApr)}
            klayApr={Number(selectedStakeData.klayApr)}
          />
          {HorizontalDivider}
          <div className="Row__container">
            <Row
              title={intl.formatMessage(messages.baseAPR)}
              className="StakingWrapper__row"
              weight="light"
            >
              <ValuePercent value={selectedStakeData.baseApr} />
            </Row>

            <Row
              title={intl.formatMessage(messages.totalEmissions)}
              className="StakingWrapper__row"
              weight="light"
            >
              <strong className="StakingWrapper__cooldownPeriodTime">
                <Value symbol={'KLAP'} value={selectedStakeData.totalEmissionPerDay} />
              </strong>
            </Row>

            <Row
              title={intl.formatMessage(messages.klapPrice)}
              className="StakingWrapper__row"
              weight="light"
            >
              <Value symbol={'$'} value={selectedStakeData.rewardTokenPriceUsd} />
            </Row>

            <Row
              title={intl.formatMessage(messages.totalAmountStaked)}
              className="StakingWrapper__row"
              weight="light"
            >
              <Value symbol={'KLAP-KLAY LP'} value={selectedStakeData.stakeTokenTotalSupply} />
            </Row>
          </div>
          {HorizontalDivider}
        </div>
        <div className="StakingWrapper__content-right-outer">
          <ContentWrapper
            withBackButton={true}
            className={classNames('StakingWrapper__content-right')}
          >
            {!userId ? (
              <NoDataPanel
                title={intl.formatMessage(messages.noWalletConnect)}
                description={intl.formatMessage(messages.noWalletConnectDescription)}
                withConnectButton={true}
              />
            ) : (
              children
            )}
          </ContentWrapper>
          {HorizontalDivider}
          <CardContainer>
            <h1 style={{ width: '100%', textAlign: 'center', paddingTop: 16 }}>
              {intl.formatMessage(messages.yourCurrentStakes)}
            </h1>
            <div style={{ display: 'flex' }}>
              <SidePanelCard
                title={intl.formatMessage(messages.stakedAmountTitle)}
                value={selectedStakeData.stakeTokenUserBalance}
                valueInUsd={lpStakedInUSD}
              >
                <Link to={`/staking/${currentAsset}/unstake`} className="StakingWrapper__link">
                  <DefaultButton
                    title={intl.formatMessage(messages.unstake)}
                    className="StakingWrapper__button"
                    color="blue"
                    onClick={() => {}}
                  />
                </Link>
              </SidePanelCard>
              <div
                style={{
                  background: 'rgb(189, 189, 189)',
                  minHeight: '100%',
                  width: 0.5,
                  margin: '10px 0px',
                }}
              />
              <SidePanelCard
                title={intl.formatMessage(messages.incentivesToClaim)}
                value={selectedStakeData.userIncentivesToClaim}
                valueInUsd={userIncentivesToClaimInUSD}
                secondTitle={intl.formatMessage(messages.claimableKlay)}
                secondValue={selectedStakeData.klayClaimableReward}
                secondValueInUsd={claimableKlayInUSD}
              >
                <Link
                  to={`/staking/${currentAsset}/claim/confirmation?amount=-1`}
                  className="StakingWrapper__link"
                >
                  <DefaultButton
                    title={intl.formatMessage(messages.claim)}
                    className="StakingWrapper__button"
                    color="blue"
                    onClick={() => {}}
                  />
                </Link>
              </SidePanelCard>
            </div>
          </CardContainer>
        </div>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        @import 'src/_mixins/screen-size';

        .StakingWrapper {
          &__info-timerWrapper {
            color: ${currentTheme.textDarkBlue.hex};
          }
          &__info-timerInner {
            background: ${currentTheme.mainBg.hex};
            @include respond-to(sm) {
              background: ${currentTheme.disabledGray.hex};
            }
            .StakingWrapper__timer {
              color: ${currentTheme.textDarkBlue.hex};
            }
          }

          &__gradientButton {
            background: ${gradientBackground};
            color: ${'#000'};

            &:disabled,
            &:disabled &:hover {
              .StakingWrapper__gradientButton-inner {
                background: ${currentTheme.mainBg.hex};
                color: ${disabledColor};
                @include respond-to(sm) {
                  background: ${currentTheme.disabledGray.hex};
                }
              }
            }
            &:after {
              background: ${gradientBackground};
            }
          }
          &__gradientButton-inner {
            background: ${gradientBackground};
          }

          .StakingWrapper__unstakeTimer {
            color: ${currentTheme.textDarkBlue.hex};
          }
        }
      `}</style>
    </ScreenWrapper>
  );
}

import css from 'styled-jsx/css';

/*language=SCSS*/
const staticStyles = css.global`
  @import 'src/_mixins/vars';
  @import 'src/_mixins/screen-size';

  .DashboardWrapper {
    @include respond-to(sm) {
      padding: 0px !important;
    }
    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    .ScreenWrapper__mobile-bottomBorder {
      display: none !important;
      @include respond-to(md) {
        display: block !important;
      }
    }
  }
`;

export default staticStyles;

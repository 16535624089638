import React from 'react';
import Button from '../Button';

interface AccountButtonProps {
  name: string;
  onClick: () => void;
  size?: 'small' | 'normal' | 'medium';
}

export default function AccountButton({ name, size = 'normal', onClick }: AccountButtonProps) {
  return <Button onClick={onClick} name={name} />;
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  yourInformation: 'Your information',

  deposits: 'Your Liquidity',
  yourWalletBalance: 'Your Balance',
  youAlreadyDeposited: 'Already Deposited',
  collateral: 'Use as collateral',
  depositOffLabel: 'No',
  depositOnLabel: 'Yes',

  borrows: 'Your Borrows',
  borrowed: 'Already Borrowed',
  loanToValue: 'Loan to value',
  availableToYou: 'Available to you',
  yourBorrowingInfo: 'Your Borrowing Info',
});

import { poll } from 'ethers/lib/utils';

// eslint-disable-next-line import/no-anonymous-default-export
export default async (provider: any, txHash: string) => {
  return await poll(
    async () => {
      // @ts-ignore
      const tx = await provider!.provider.getTransaction(txHash);
      console.log('tx poll', tx);
      if (tx === null) {
        return undefined;
      }
      return tx;
    },
    { oncePoll: provider }
  );
};

import React, { useState } from 'react';
import moment from 'moment';
import DefaultButton from '../../../components/basic/DefaultButton';
import Preloader from '../../../components/basic/Preloader';
import { useIntl } from 'react-intl';
import messages from './messages';
import { HorizontalDivider } from '../../../components/Divider';
import unlocked from '../../lockdrop/images/unlocked.svg';
import locked from '../../lockdrop/images/locked.svg';
import { useThemeContext } from '@aave/aave-ui-kit';
import classNames from 'classnames';
import { useServices } from '../functions';

interface VeInfoProps {
  deposit: {
    ogKlayAmount: string;
    lpAmount: string;
    harvestAmount: string;
    expiryAmount: number;
  };
}

const VeInfo = (props: VeInfoProps) => {
  const intl = useIntl();
  const { claim, harvest } = useServices();
  const [isHarvestLoading, setIsHarvestLoading] = useState(false);
  const [isClaimLoading, setIsClaimLoading] = useState(false);
  const { md } = useThemeContext();
  return (
    <div className="LockedTable">
      {!md ? (
        <>
          <div className="LockedTable__header">
            <div className="LockedTable__headertitle">
              {intl.formatMessage(messages.lockedAmount)}
            </div>
            <div className="LockedTable__headertitle">
              {intl.formatMessage(messages.recievingAmount)}
            </div>
            <div className="LockedTable__headertitle">
              {intl.formatMessage(messages.harvestAmount)}
            </div>
            <div className="LockedTable__headertitle">{intl.formatMessage(messages.expiry)}</div>
            <div className="LockedTable__headertitle">{intl.formatMessage(messages.status)}</div>
            <div className="LockedTable__headertitle">{intl.formatMessage(messages.action)}</div>
          </div>
          {HorizontalDivider}
          <div className="LockedTable__listcontainer">
            {props.deposit.ogKlayAmount === '0.0' ? (
              <div className="LockedTable__no-assets-container">
                <div className="LockedTable__no-assets-emoji">🤷‍♂️</div>
                <div className="LockedTable__no-assets-text">
                  {intl.formatMessage(messages.noAssets)}
                </div>
              </div>
            ) : (
              <div className="LockedTable__row-container">
                <div className="LockedTable__subtitle">{props.deposit.ogKlayAmount} KLAY</div>
                <div className="LockedTable__subtitle">
                  {Number(props.deposit.lpAmount) === 0 ? 'TBD' : props.deposit.lpAmount} LP
                </div>
                <div className="LockedTable__subtitle">{props.deposit.harvestAmount} KLAP</div>
                <div className="LockedTable__subtitle">
                  {moment(props.deposit.expiryAmount * 1000).format('MM/DD/YY HH:mm')}
                </div>
                <div className="LockedTable__status-col">
                  <img
                    className="LockedTable__lock"
                    src={props.deposit.expiryAmount * 1000 < moment().unix() ? unlocked : locked}
                    alt="Status Icon"
                  />
                  {props.deposit.expiryAmount * 1000 < moment().unix()
                    ? intl.formatMessage(messages.unlocked)
                    : intl.formatMessage(messages.locked)}{' '}
                </div>
                <div className="LockedTable__buttonContainer">
                  {isClaimLoading ? (
                    <div className="LockedTable__PreloaderContainer">
                      <Preloader smallSize />
                    </div>
                  ) : (
                    <DefaultButton
                      className="TableAction"
                      disabled={Number(props.deposit.lpAmount) === 0}
                      onClick={async () => {
                        setIsClaimLoading(true);
                        await claim();
                        setIsClaimLoading(false);
                      }}
                      title={intl.formatMessage(messages.claim)}
                      color="blue"
                    />
                  )}
                  {isHarvestLoading ? (
                    <div className="LockedTable__PreloaderContainer">
                      <Preloader smallSize />
                    </div>
                  ) : (
                    <DefaultButton
                      className="TableAction"
                      disabled={Number(props.deposit.harvestAmount) === 0}
                      onClick={async () => {
                        setIsHarvestLoading(true);
                        await harvest();
                        setIsHarvestLoading(false);
                      }}
                      title={intl.formatMessage(messages.harvestRewards)}
                      color="blue"
                    />
                  )}
                </div>
              </div>
            )}
          </div>
          {HorizontalDivider}
        </>
      ) : (
        <div
          className={classNames(
            'MobileLockedTable__listcontainer',
            props.deposit.ogKlayAmount === '0' && 'MobileLockedTable__no-asset-container'
          )}
        >
          {props.deposit.ogKlayAmount === '0' ? (
            <div className="MobileLockedTable__container">
              <div className="LockedTable__no-assets-container">
                <div className="LockedTable__no-assets-emoji">🤷‍♂️</div>
                <div className="LockedTable__no-assets-text">
                  {intl.formatMessage(messages.noAssets)}
                </div>
              </div>
            </div>
          ) : (
            <div className="MobileLockedTable__container">
              <div className="MobileLockedTable__row">
                <div className="MobileLockedTable__title">
                  {intl.formatMessage(messages.lockedAmount)}
                </div>
                <div className="MobileLockedTable__answer">{props.deposit.ogKlayAmount} KLAY</div>
              </div>
              <div className="MobileLockedTable__row">
                <div className="MobileLockedTable__title">
                  {intl.formatMessage(messages.recievingAmount)}
                </div>
                <div className="MobileLockedTable__answer">
                  {props.deposit.lpAmount} KLAY-KLAP LP
                </div>
              </div>
              <div className="MobileLockedTable__row">
                <div className="MobileLockedTable__title">
                  {intl.formatMessage(messages.harvestAmount)}
                </div>
                <div className="MobileLockedTable__answer">{props.deposit.harvestAmount} KLAP</div>
              </div>
              <div className="MobileLockedTable__row">
                <div className="MobileLockedTable__title">
                  {intl.formatMessage(messages.expiry)}
                </div>
                <div className="MobileLockedTable__answer">
                  {moment(props.deposit.expiryAmount * 1000).format('MM/DD/YY HH:mm')}
                </div>
              </div>
              <div className="MobileLockedTable__row">
                <div className="MobileLockedTable__title">
                  {intl.formatMessage(messages.status)}
                </div>
                <div className="MobileLockedTable__answer">
                  <div className="LockedTable__status-col">
                    <img
                      className="LockedTable__lock"
                      src={props.deposit.expiryAmount < moment().unix() ? unlocked : locked}
                      alt="Status Icon"
                    />
                    {props.deposit.expiryAmount < moment().unix() ? 'Unlocked' : 'Locked'}{' '}
                  </div>
                </div>
              </div>
              <div className="MobileLockedTable__actionContainer">
                {isClaimLoading ? (
                  <div className="LockedTable__PreloaderContainer">
                    <Preloader smallSize />
                  </div>
                ) : (
                  <DefaultButton
                    className="TableAction"
                    disabled={Number(props.deposit.lpAmount) === 0}
                    onClick={async () => {
                      setIsClaimLoading(true);
                      await claim();
                      setIsClaimLoading(false);
                    }}
                    title={intl.formatMessage(messages.claim)}
                    color="blue"
                  />
                )}
                {isHarvestLoading ? (
                  <div className="LockedTable__PreloaderContainer">
                    <Preloader smallSize />
                  </div>
                ) : (
                  <DefaultButton
                    className="TableAction"
                    disabled={Number(props.deposit.harvestAmount) === 0}
                    onClick={async () => {
                      setIsHarvestLoading(true);
                      await harvest();
                      setIsHarvestLoading(false);
                    }}
                    title={intl.formatMessage(messages.harvestRewards)}
                    color="blue"
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VeInfo;

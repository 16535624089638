import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import NoDataPanel from '../../components/NoDataPanel';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { useIntl } from 'react-intl';
import defaultMessages from '../../defaultMessages';
import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import messages from './messages';
import staticStyles from './style';
import componentStyles from './components/style';
import LockedTable from './components/LockedTable';
import LockdropModal from './components/LockdropModal';
import { useGetLockdropInfo } from './hooks';

export default function Lockdrop() {
  const intl = useIntl();
  const { currentAccount: userId } = useUserWalletDataContext();
  const { chainId } = useProtocolDataContext();
  const { data } = useGetLockdropInfo();

  if (!userId && chainId !== 250) {
    return (
      <NoDataPanel
        title={intl.formatMessage(defaultMessages.connectWallet)}
        description={intl.formatMessage(defaultMessages.pleaseConnectWallet)}
        withConnectButton={true}
      />
    );
  }
  return (
    <>
      <ScreenWrapper className="Lockdrop">
        <LockdropModal />
        <div className="Lockdrop__titlecontainer">
          <h1 className="Lockdrop__title">{intl.formatMessage(messages.phase1)}</h1>
          <LockedTable listTokens={data.lockedAssets} />
        </div>
        {/*<div className="Lockdrop__titlecontainer">
          <h1 className="Lockdrop__title">{intl.formatMessage(messages.phase2)}</h1>
          <DefaultButton title={intl.formatMessage(messages.depositButton)} />
        </div>
        <LockedTable listTokens={[]} onClickAction={() => setOpenModal(true)} />*/}
      </ScreenWrapper>
      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>
        {componentStyles}
      </style>
    </>
  );
}

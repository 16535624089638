import { defineMessages } from 'react-intl';

export default defineMessages({
  assets: 'Assets',
  emissions: 'Emissions (yearly)',
  apr: 'Base APR',
  newEmissions: 'New Emissions (yearly)',
  newAPR: 'New APR',

  yourVotingInfo: 'Your Voting Info',
  searchByPoolName: 'Search by Pool Name',
  castYourVote: 'Cast Your Votes',
  uncastVote: 'Uncast Votes',
  lockKlapToVote: 'Lock KLAP To Vote',
  type: 'Type',
  yourVote: 'Your Vote',
  lend: 'Lend',
  borrow: 'Borrow',
  youNeedVe: 'Please create a veNFT to vote.',
});

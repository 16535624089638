import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Logo from '../../../components/basic/Logo';
import messages from '../messages';

interface VestingKlapProps {
  amountVested: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const VestedKlapInfo = styled.div`
  font-size: 16px;
  font-weight: 400;
  max-width: 750px;
  @media (min-width: 1000px) {
    max-width: 750px;
  }
`;

const VestedKlapLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  color: #1450f5;
  text-decoration: underline;
`;

const VestedKlapContainer = styled.div`
  display: flex;
  flex-direction: row;
  grid-gap: 8px;
`;

const VestedKlapTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
`;

const VestingKlap = (props: VestingKlapProps) => {
  const intl = useIntl();
  return (
    <Container>
      <VestedKlapInfo>{intl.formatMessage(messages.klapEarned)}</VestedKlapInfo>
      <VestedKlapContainer>
        <Logo />
        <VestedKlapTitle>{props.amountVested}</VestedKlapTitle>
        <VestedKlapTitle style={{ color: '#76808F' }}>KLAP</VestedKlapTitle>
      </VestedKlapContainer>
      <VestedKlapInfo style={{ color: '#474D57' }}>
        {intl.formatMessage(messages.klapRewards)}
      </VestedKlapInfo>
      <VestedKlapLink href="#vestingSchedule">
        {intl.formatMessage(messages.seeVesting)}
      </VestedKlapLink>
      <VestedKlapInfo style={{ marginTop: 16, textAlign: 'left', marginBottom: 24 }}>
        {intl.formatMessage(messages.avoidMessage)}
      </VestedKlapInfo>
    </Container>
  );
};

export default VestingKlap;

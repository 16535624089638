import React, { useState } from 'react';
import ScreenWrapper from '../../components/wrappers/ScreenWrapper';
import NoDataPanel from '../../components/NoDataPanel';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import { useServices } from './functions';
import { useServices as useServicesManage } from '../manage/functions';
import { useGetManageInfo } from '../manage/hooks';
import { LowerRightCard, Panel, SplitContainer } from '../manage/styles';
import GetVeKlap from '../manage/components/GetVeKlap';
import ProvideLiquidityLPCard from './components/ProvideLiquidityLPCard';
import YourVeInfo from './components/YourVeInfo';
import VeModal from './components/VeModal';
import { useIntl } from 'react-intl';
import defaultMessages from '../../defaultMessages';

export default function Ve() {
  const intl = useIntl();
  const { lockVeKlap, lockVeLP, approve, approveLP, addLiquidity, claimFromMasterChef } =
    useServices();
  const { claimVeNFT } = useServicesManage();
  const { data, refresh } = useGetManageInfo();
  const { currentAccount: userId } = useUserWalletDataContext();
  const { chainId, currentMarketData } = useProtocolDataContext();
  const [isLockLoading, setIsLockLoading] = useState(false);
  const [isLockLPLoading, setIsLockLPLoading] = useState(false);
  const [isProvideLiquidityLoading, setIsProvideLiquidityLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [tokenInfo, setTokenInfo] = useState<{
    tokenId: string;
    amount: string;
    expiry: string;
  }>({
    tokenId: '',
    amount: '',
    expiry: '',
  });

  if (!userId && chainId !== 250) {
    return (
      <NoDataPanel
        title={intl.formatMessage(defaultMessages.connectWallet)}
        description={intl.formatMessage(defaultMessages.pleaseConnectWallet)}
        withConnectButton={true}
      />
    );
  }
  return (
    <>
      <ScreenWrapper className="Ve">
        <VeModal
          isModalOpen={isModalOpen}
          toggle={() => setModalOpen(!isModalOpen)}
          tokenInfo={tokenInfo}
        />
        <Panel style={{ gridGap: 32, marginBottom: 32 }}>
          <SplitContainer>
            <GetVeKlap
              aprs={data.veKlapAPRs}
              max={data.klapBalance}
              onLock={async (amount, lock_duration) => {
                setIsLockLoading(true);
                if (data.klapVeApproved) {
                  await lockVeKlap(amount, lock_duration);
                } else {
                  await approve(currentMarketData.addresses.VE!);
                }
                await refresh();
                setIsLockLoading(false);
              }}
              isLoading={isLockLoading}
              isNotApproved={!data.klapVeApproved}
              showAPR={false}
            />
          </SplitContainer>
          <SplitContainer>
            <ProvideLiquidityLPCard
              userFactor={data.userFactor}
              sumOfFactor={data.sumOfFactor}
              apr={data.veKlapKlayAPR}
              maxLP={data.klapLPBalance}
              maxKlap={data.klapBalance}
              maxKlay={data.klayBalance}
              ratioKlapToKlay={data.klapTokenPrice / data.klayTokenPrice}
              onLock={async (amount, lock_duration) => {
                setIsLockLPLoading(true);
                if (data.klapVeLPApproved) {
                  await lockVeLP(amount, lock_duration);
                } else {
                  await approveLP();
                }
                await refresh();
                setIsLockLPLoading(false);
              }}
              onProvideLiquidity={async (amountKlap, amountKlay, slippage) => {
                setIsProvideLiquidityLoading(true);
                if (data.klapClaimswapApproved) {
                  await addLiquidity(amountKlap, amountKlay, slippage);
                } else {
                  await approve(currentMarketData.addresses.CLAIMSWAP_ROUTER!);
                }
                await refresh();
                setIsProvideLiquidityLoading(false);
              }}
              isLoadingProvideLiquidity={isProvideLiquidityLoading}
              isLoadingLock={isLockLPLoading}
              isNotApprovedLP={!data.klapVeLPApproved}
              isNotApprovedKlap={!data.klapClaimswapApproved}
            />
          </SplitContainer>
        </Panel>
        <LowerRightCard>
          <YourVeInfo
            onClaimFromMasterchef={async () => {
              await claimFromMasterChef();
              await refresh();
            }}
            listTokens={data.veNFTs}
            onClaim={async (tokenId) => {
              await claimVeNFT(tokenId);
              await refresh();
            }}
            openModal={(tokenId, amount, expiry) => {
              setModalOpen(true);
              setTokenInfo({
                tokenId: tokenId,
                expiry: expiry,
                amount: amount,
              });
            }}
          />
        </LowerRightCard>
      </ScreenWrapper>
      <style jsx={true} global={true}>{`
        .Ve {
          flex-direction: column;
          padding-top: 40px;
          padding-bottom: 40px;
          max-width: 1200px;
        }
      `}</style>
    </>
  );
}

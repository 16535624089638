import { ReactNode } from 'react';
import { useThemeContext } from '@aave/aave-ui-kit';

import ContentWrapper from '../../../../components/wrappers/ContentWrapper';
import RepayWithdrawWrapper from '../../../../components/wrappers/RepayWithdrawWrapper';

import staticStyles from './style';
import Button from '../../../../components/Button';
import defaultMessages from '../../../../defaultMessages';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';

interface WithdrawScreenWrapperProps {
  title: string;
  currencySymbol: string;
  balanceInProtocol: string;
  balanceInProtocolInUSD: string;
  healthFactor: string;
  loanToValue: string;
  children: ReactNode;
}

export default function WithdrawScreenWrapper({
  title,
  currencySymbol,
  balanceInProtocol,
  balanceInProtocolInUSD,
  healthFactor,
  loanToValue,
  children,
}: WithdrawScreenWrapperProps) {
  const { sm } = useThemeContext();
  const history = useHistory();
  const intl = useIntl();

  return (
    <>
      <RepayWithdrawWrapper className="WithdrawScreenWrapper" title={title}>
        {!sm && (
          <div>
            <Button
              className="Backbutton"
              onClick={history.goBack}
              name={`< ${intl.formatMessage(defaultMessages.back)}`}
              size="small"
            />
          </div>
        )}
        {/*<Row title={intl.formatMessage(messages.balanceInAave)} color="white" weight="light">
          <Value
            value={Number(balanceInProtocol)}
            subValue={Number(balanceInProtocolInUSD)}
            color="white"
            symbol={currencySymbol}
            subSymbol="USD"
            maximumValueDecimals={isAssetStable(currencySymbol) ? 4 : 18}
            minimumValueDecimals={isAssetStable(currencySymbol) ? 4 : 17}
            maximumSubValueDecimals={2}
            minimumSubValueDecimals={2}
          />
        </Row>
        <HealthFactor
          className={classNames({ WithdrawScreenWrapper__healthFactor: !sm })}
          value={healthFactor}
          titleColor="white"
          titleLightWeight={true}
          isColumn={!sm}
        />
        <Row
          title={
            <MaxLTVHelpModal
              text={intl.formatMessage(messages.loanToValue)}
              color="white"
              lightWeight={true}
            />
          }
          color="white"
          weight="light"
          isColumn={!sm}
        >
          <ValuePercent value={loanToValue} color="white" />
        </Row>

        <CollateralCompositionBar isColumn={(lg && !md) || sm} />*/}
      </RepayWithdrawWrapper>

      <ContentWrapper withFullHeight={true} withBackButton={true}>
        {children}
      </ContentWrapper>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
    </>
  );
}

import React from 'react';
import { useIntl } from 'react-intl';
import { valueToBigNumber } from '@aave/protocol-js';

import ContentWrapper from '../../../../components/wrappers/ContentWrapper';
import MaxLTVHelpModal from '../../../../components/HelpModal/MaxLTVHelpModal';
import Value from '../../../../components/basic/Value';
import LiquidationThresholdHelpModal from '../../../../components/HelpModal/LiquidationThresholdHelpModal';
import LiquidationBonusHelpModal from '../../../../components/HelpModal/LiquidationBonusHelpModal';
import ReserveStatusGraph from '../Graphs/ReserveStatus';
import TotalValue from '../TotalValue';
import PercentBlock from '../InformationBlock/PercentBlock';
import TextBlock from '../InformationBlock/TextBlock';
import APYCard from '../APYCard';
import APYLine from '../APYLine';
import Link from '../../../../components/basic/Link';

import defaultMessages from '../../../../defaultMessages';
import messages from './messages';
import staticStyles from './style';

import linkIcon from '../../../../images/blueLinkIcon.svg';
import { getLPTokenPoolLink } from '../../../../helpers/lp-tokens';
import { ComputedReserveData } from '../../../../libs/pool-data-provider';
import { VerticalDivider, HorizontalDivider } from '../../../../components/Divider';

interface ReserveInformationProps {
  symbol: string;
  poolReserve: ComputedReserveData;
  marketRefPriceInUsd: string;
}

export default function ReserveInformation({
  symbol,
  poolReserve,
  marketRefPriceInUsd,
}: ReserveInformationProps) {
  const intl = useIntl();
  const totalLiquidityInUsd = valueToBigNumber(poolReserve.totalLiquidity)
    .multipliedBy(poolReserve.priceInMarketReferenceCurrency)
    //
    .toString();
  const totalBorrowsInUsd = valueToBigNumber(poolReserve.totalDebt)
    .multipliedBy(poolReserve.priceInMarketReferenceCurrency)
    //
    .toString();
  const availableLiquidityInUsd = valueToBigNumber(poolReserve.availableLiquidity)
    .multipliedBy(poolReserve.priceInMarketReferenceCurrency)
    //
    .toString();

  const reserveOverviewData = {
    totalLiquidityInUsd,
    totalBorrowsInUsd,
    availableLiquidityInUsd,
    totalLiquidity: poolReserve.totalLiquidity,
    totalBorrows: poolReserve.totalDebt,
    availableLiquidity: poolReserve.availableLiquidity,
    supplyAPY: Number(poolReserve.supplyAPY),
    supplyAPR: Number(poolReserve.supplyAPR),
    avg30DaysLiquidityRate: Number(poolReserve.avg30DaysLiquidityRate),
    stableAPY: Number(poolReserve.stableBorrowAPY),
    stableAPR: Number(poolReserve.stableBorrowAPR),
    variableAPY: Number(poolReserve.variableBorrowAPY),
    variableAPR: Number(poolReserve.variableBorrowAPR),
    stableOverTotal: valueToBigNumber(poolReserve.totalStableDebt)
      .dividedBy(poolReserve.totalDebt)
      .toNumber(),
    variableOverTotal: valueToBigNumber(poolReserve.totalVariableDebt)
      .dividedBy(poolReserve.totalDebt)
      .toNumber(),
    avg30DaysVariableRate: Number(poolReserve.avg30DaysVariableBorrowRate),
    utilizationRate: Number(poolReserve.utilizationRate),
    baseLTVasCollateral: Number(poolReserve.baseLTVasCollateral),
    liquidationThreshold: Number(poolReserve.reserveLiquidationThreshold),
    liquidationBonus: Number(poolReserve.reserveLiquidationBonus),
    usageAsCollateralEnabled: poolReserve.usageAsCollateralEnabled,
    stableBorrowRateEnabled: poolReserve.stableBorrowRateEnabled,
    borrowingEnabled: poolReserve.borrowingEnabled,
  };

  const poolLink = getLPTokenPoolLink({
    symbol,
    underlyingAsset: poolReserve.underlyingAsset,
  });
  return (
    <div className="ReserveInformation">
      <div className="ReserveInformation__inner">
        <div className="ReserveInformation__middle-info">
          <div style={{ alignItems: 'flex-start' }} className="ReserveInformation__line">
            <p>{intl.formatMessage(messages.asset)}</p>
            <strong>{symbol}</strong>
          </div>
          <div className="ReserveInformation__line">
            <p>{intl.formatMessage(messages.reserveSize)}</p>
            <strong>
              <Value
                value={Number(reserveOverviewData.totalLiquidityInUsd)}
                maximumValueDecimals={2}
                minimumValueDecimals={2}
                symbol="USD"
                tokenIcon={true}
                withoutSymbol={true}
              />
            </strong>
          </div>
          <div className="ReserveInformation__line">
            <PercentBlock
              value={reserveOverviewData.borrowingEnabled ? reserveOverviewData.utilizationRate : 0}
              title={intl.formatMessage(messages.utilisationRate)}
            />
          </div>
        </div>
        {HorizontalDivider}

        <ContentWrapper className="ReserveInformation__content">
          {poolLink && (
            <div className="ReserveInformation__poolLink-inner">
              <p>
                {intl.formatMessage(messages.provideLiquidity, {
                  here: (
                    <Link
                      to={poolLink}
                      title={intl.formatMessage(messages.here)}
                      absolute={true}
                      inNewWindow={true}
                      bold={true}
                      color="secondary"
                    />
                  ),
                })}
              </p>
              <img src={linkIcon} alt="" />
            </div>
          )}

          <div className="ReserveInformation__graph-inner">
            <TotalValue
              color="red"
              title={intl.formatMessage(messages.totalBorrowed)}
              value={reserveOverviewData.totalBorrows}
              subValue={reserveOverviewData.totalBorrowsInUsd}
              borrowingEnabled={reserveOverviewData.borrowingEnabled}
            />
            <ReserveStatusGraph
              symbol={symbol}
              totalBorrows={reserveOverviewData.totalBorrows}
              availableLiquidity={reserveOverviewData.availableLiquidity}
            />
            <TotalValue
              title={intl.formatMessage(messages.availableLiquidity)}
              value={reserveOverviewData.availableLiquidity}
              subValue={reserveOverviewData.availableLiquidityInUsd}
              borrowingEnabled={reserveOverviewData.borrowingEnabled}
            />
          </div>

          <div className="ReserveInformation__APY-info">
            <APYCard isRight title={intl.formatMessage(defaultMessages.deposit)}>
              <APYLine
                title={intl.formatMessage(messages.depositAPY)}
                value={reserveOverviewData.supplyAPY}
                condition={reserveOverviewData.borrowingEnabled}
              />
              <APYLine
                title={intl.formatMessage(messages.depositAPR)}
                value={reserveOverviewData.supplyAPR}
                condition={reserveOverviewData.borrowingEnabled}
              />
            </APYCard>
            {VerticalDivider}
            <APYCard title={intl.formatMessage(messages.variableBorrowing)}>
              <APYLine
                title={intl.formatMessage(messages.borrowAPY)}
                value={reserveOverviewData.variableAPY}
                condition={reserveOverviewData.borrowingEnabled}
              />
              <APYLine
                title={intl.formatMessage(messages.borrowAPR)}
                value={reserveOverviewData.variableAPR}
                condition={reserveOverviewData.borrowingEnabled}
              />
              <APYLine
                title={intl.formatMessage(messages.overTotal)}
                value={reserveOverviewData.variableOverTotal}
                condition={reserveOverviewData.borrowingEnabled}
              />
            </APYCard>
          </div>

          <div className="ReserveInformation__bottom-info">
            <PercentBlock
              value={reserveOverviewData.baseLTVasCollateral}
              titleComponent={<MaxLTVHelpModal text={intl.formatMessage(messages.maximumLTV)} />}
            />
            <PercentBlock
              value={
                reserveOverviewData.liquidationBonus <= 0
                  ? 0
                  : reserveOverviewData.liquidationThreshold
              }
              titleComponent={
                <LiquidationThresholdHelpModal
                  text={intl.formatMessage(messages.liquidationThreshold)}
                />
              }
            />
            <PercentBlock
              value={reserveOverviewData.liquidationBonus}
              titleComponent={
                <LiquidationBonusHelpModal text={intl.formatMessage(messages.liquidationPenalty)} />
              }
            />
            <TextBlock
              condition={reserveOverviewData.usageAsCollateralEnabled}
              title={intl.formatMessage(messages.usedAsCollateral)}
            />
          </div>
        </ContentWrapper>
      </div>

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true}>{`
        .TextWithModal__text {
          color: #76808f;
        }
        .ReserveInformation {
          &__title {
            color: black;
          }

          &__line {
            color: black;
          }
        }
      `}</style>
    </div>
  );
}

import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import BasicForm from '../../../../components/forms/BasicForm';

import defaultMessages from '../../../../defaultMessages';
import messages from './messages';
import { parseEther } from 'ethers/lib/utils';
import { MAX_UINT_AMOUNT } from '@aave/protocol-js';
import { stakeConfig } from '../../../../ui-config';
import { useWeb3React } from '@web3-react/core';
import { Contract, providers } from 'ethers';
import ERC20ABI from '../../../../contracts/ERC20.json';
import Logo from '../../../../components/basic/Logo';
import { useGetManageInfo } from '../../../manage/hooks';
import { useServices } from '../../../ve/functions';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import LiquidityDualComponent from './LiquidityDualComponent';

export default function StakeAmount() {
  const { currentMarketData } = useProtocolDataContext();
  const { approve, addLiquidity } = useServices();
  const intl = useIntl();
  const {
    selectedStake,
    selectedStakeData: { underlyingTokenUserBalance, isAllowed },
    stakingService,
    STAKING_REWARD_TOKEN,
  } = useStakeDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const history = useHistory();
  const { data, refresh } = useGetManageInfo();
  const [isProvideLiquidityLoading, setIsProvideLiquidityLoading] = useState(false);

  const handleSubmit = (amount: string) => {
    const query = queryString.stringify({ amount: Number(amount).toFixed(4) });
    history.push(`/staking/${selectedStake}/confirmation?${query}`);
  };

  const currencyName = 'KLAP-KLAY LP';
  const handleGetTransactions = (userId: string) => async () => {
    if (underlyingTokenUserBalance === '0') return [];
    if (isAllowed) {
      const txn = () =>
        stakingService.populateTransaction.deposit(
          STAKING_REWARD_TOKEN,
          parseEther('' + (Number(underlyingTokenUserBalance) - 0.0001))
        );
      return { type: 'DEPOSIT', txn: txn };
    } else {
      const selectedStakeAddresses = STAKING_REWARD_TOKEN;
      const topHolderSigner = provider!.getSigner(userId);
      const stakingService = new Contract(selectedStakeAddresses, ERC20ABI, topHolderSigner);
      const txn = () =>
        stakingService.populateTransaction.approve(stakeConfig!.stakeDataProvider, MAX_UINT_AMOUNT);
      return { type: 'APPROVE', txn: txn };
    }
  };

  return (
    <LiquidityDualComponent
      maxLP={data.klapLPBalance}
      maxKlap={data.klapBalance}
      maxKlay={data.klayBalance}
      ratioKlapToKlay={data.klapTokenPrice / data.klayTokenPrice}
      onProvideLiquidity={async (amountKlap, amountKlay, slippage) => {
        setIsProvideLiquidityLoading(true);
        if (data.klapClaimswapApproved) {
          await addLiquidity(amountKlap, amountKlay, slippage);
        } else {
          await approve(currentMarketData.addresses.CLAIMSWAP_ROUTER!);
        }
        await refresh();
        setIsProvideLiquidityLoading(false);
      }}
      isLoadingProvideLiquidity={isProvideLiquidityLoading}
      isNotApprovedKlap={!data.klapClaimswapApproved}
      child={
        <BasicForm
          title={intl.formatMessage(messages.caption, {
            img: <Logo />,
          })}
          description={intl.formatMessage(messages.description, {
            symbol: 'KLAP-KLAY LP',
            module: <strong>{intl.formatMessage(messages.safetyModule)}</strong>,
            incentives: <strong>{intl.formatMessage(messages.protocolIncentives)}</strong>,
          })}
          amountFieldTitle={intl.formatMessage(messages.availableToStake)}
          maxAmount={underlyingTokenUserBalance}
          currencySymbol={currencyName}
          onSubmit={handleSubmit}
          submitButtonTitle={
            isAllowed
              ? intl.formatMessage(defaultMessages.stake)
              : intl.formatMessage(messages.approveStake)
          }
          getTransactionData={handleGetTransactions}
        />
      }
    />
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  optionTitleLeft: 'All',
  optionTitleRight: 'Stable Coins',
  noDataTitle: 'There are no currencies matching the parameters',
  myDeposits: 'My Deposits',
  all: 'All',
  stableCoins: 'Stable Coins',
  ownedAssets: 'Owned Assets',
  noCurrentDeposits: 'No Current Deposits',
});

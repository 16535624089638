import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Footer from '../../Footer';
import Menu from '../../menu/Menu';
import { BottomDisclaimer, TopDisclaimer } from '../../../ui-config';

import QRcode from 'qrcode';
import messages from './messages';
import staticStyles from './style';
import KlipView from '../../basic/KlipView';
import axios from 'axios';
import { KLIP_REQUEST_ADDRESS } from '../../../libs/constants';

export interface ScreensWrapperProps {
  children: ReactNode;
}

export const TitleContext = createContext({
  title: '',
  setTitle: (title: string) => {},
});

export const KlipContext = createContext({
  setShowQRCode: (requestKey: string, cb?: () => void, errorCb?: () => void) => {},
});

export function useKlipContext() {
  const { setShowQRCode } = useContext(KlipContext);
  return setShowQRCode;
}

export function useHeaderTitle() {
  const { title, setTitle } = useContext(TitleContext);
  return { title, setTitle };
}

export const TopPanelSmallContext = createContext({
  isTopPanelSmall: false,
  setTopPanelSmall: (isSmallTopLine: boolean) => {},
});

export function useWithDesktopTitle() {
  const { isTopPanelSmall, setTopPanelSmall } = useContext(TopPanelSmallContext);
  return { isTopPanelSmall, setTopPanelSmall };
}

export default function ScreensWrapper({ children }: ScreensWrapperProps) {
  const intl = useIntl();

  const [title, setTitle] = useState(intl.formatMessage(messages.pageTitle));
  const [isTopPanelSmall, setTopPanelSmall] = useState(
    localStorage.getItem('isTopPanelSmall') === 'true' || false
  );
  const [showQRCode, setShowQRCode] = useState(false);
  const [showX, setShowX] = useState(false);

  return (
    <div
      className={classNames('ScreensWrapper', {
        ScreensWrapper__topPanelSmall: isTopPanelSmall,
      })}
    >
      {showQRCode && (
        <KlipView
          showX={showX}
          showQRCode={showQRCode}
          goBack={() => {
            setShowQRCode(false);
          }}
        />
      )}
      <BottomDisclaimer />

      <TopDisclaimer />
      <Menu title={title} />

      <main className="ScreensWrapper__content" id="ScreensWrapper__content-wrapper">
        <div className="ScreensWrapper__top-contentWrapper" />

        <TitleContext.Provider value={{ title, setTitle }}>
          <TopPanelSmallContext.Provider value={{ isTopPanelSmall, setTopPanelSmall }}>
            <KlipContext.Provider
              value={{
                setShowQRCode: async (
                  _requestKey: string,
                  cb?: () => void,
                  errorCb?: () => void
                ) => {
                  const request = 'https://klipwallet.com/?target=/a2a?request_key=' + _requestKey;
                  setShowQRCode(true);
                  setShowX(false);
                  QRcode.toCanvas(document.getElementById('qrcode'), request, (error) => {
                    console.log('error', error);
                  });
                  while (true) {
                    try {
                      var url = KLIP_REQUEST_ADDRESS + _requestKey;
                      const res = await axios.get(url);
                      if (res.data.status === 'completed') {
                        setShowQRCode(false);
                        cb!();
                        break;
                      }
                    } catch (e) {
                      setShowQRCode(false);
                      if (errorCb) {
                        errorCb!();
                      }
                      break;
                    }

                    await new Promise((r) => setTimeout(r, 2000));
                  }
                },
              }}
            >
              {children}
            </KlipContext.Provider>
          </TopPanelSmallContext.Provider>
        </TitleContext.Provider>
      </main>

      <Footer inside={true} />

      <style jsx={true} global={true}>
        {staticStyles}
      </style>
      <style jsx={true} global={true}>{`
        @import 'src/_mixins/screen-size';
      `}</style>
    </div>
  );
}

//import { MAX_UINT_AMOUNT } from '@aave/protocol-js';
import { useWeb3React } from '@web3-react/core';
import { providers, Contract } from 'ethers';
import { getProvider } from '../../helpers/config/markets-and-network-config';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import LockdropABI from '../../contracts/LiquidityLockdrop.json';
import ProxyFarmerABI from '../../contracts/ProxyFarmer.json';
import { useUserWalletDataContext } from '../../libs/web3-data-provider';
import Caver, { AbiItem } from 'caver-js';
import waitForTxn from '../../helpers/wait-for-txn';
import { useKlipContext } from '../../components/wrappers/ScreensWrapper';
// @ts-ignore
import { prepare } from 'klip-sdk';
import _ from 'lodash';
import { parseEther } from 'ethers/lib/utils';

export const useServices = () => {
  const setShowQRCode = useKlipContext();
  const { currentMarketData } = useProtocolDataContext();
  const { currentAccount: userId, currentProviderName } = useUserWalletDataContext();
  const { chainId } = useProtocolDataContext();
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const sendObject = {
    from: (window as any).klaytn ? (window as any).klaytn?.selectedAddress : null,
    gas: 1800000,
  };
  const mmObject = { gasPrice: 250000000000 };
  const lockdrop = (transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(
        LockdropABI as AbiItem[],
        currentMarketData.addresses.LIQUIDITY_LOCKDROP!
      ) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.LIQUIDITY_LOCKDROP!,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    return new Contract(
      currentMarketData.addresses.LIQUIDITY_LOCKDROP!,
      LockdropABI,
      topHolderSigner
    );
  };
  const harvestContract = (transaction?: object) => {
    if (currentProviderName === 'kaikas') {
      // @ts-ignore
      const caver = new Caver(provider!.provider);
      return new caver.klay.Contract(
        ProxyFarmerABI as AbiItem[],
        currentMarketData.addresses.PROXY_FARMER!
      ) as any;
    } else if (currentProviderName === 'klip') {
      return prepare.executeContract({
        bappName: 'Klap',
        to: currentMarketData.addresses.PROXY_FARMER!,
        ...transaction,
      });
    }
    const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
    return new Contract(currentMarketData.addresses.PROXY_FARMER!, ProxyFarmerABI, topHolderSigner);
  };

  const harvest = async () => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await harvestContract().methods.claim().send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await harvestContract({
          value: '0',
          abi: JSON.stringify(_.find(ProxyFarmerABI, (func) => func.name === 'claim')),
          params: JSON.stringify([]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const claim = await harvestContract().claim(mmObject);
        await claim.wait();
      }
    } catch (e) {}
  };

  const claim = async () => {
    try {
      if (currentProviderName === 'kaikas') {
        const returnVal = await lockdrop().methods.claim().send(sendObject);
        await waitForTxn(provider, returnVal.transactionHash);
      } else if (currentProviderName === 'klip') {
        const returnVal = await lockdrop({
          value: '0',
          abi: JSON.stringify(_.find(LockdropABI, (func) => func.name === 'claim')),
          params: JSON.stringify([]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        const claim = await lockdrop().claim(mmObject);
        await claim.wait();
      }
    } catch (e) {}
  };

  const deposit = async (amount: number) => {
    try {
      if (currentProviderName === 'kaikas') {
        try {
          const returnVal = await lockdrop()
            .methods.deposit()
            .send({ ...sendObject, value: parseEther('' + amount) });
          await waitForTxn(provider, returnVal.transactionHash);
        } catch (e) {
          console.error('e', e);
        }
      } else if (currentProviderName === 'klip') {
        const returnVal = await lockdrop({
          value: parseEther('' + amount).toString(),
          abi: JSON.stringify(_.find(LockdropABI, (func) => func.name === 'deposit')),
          params: JSON.stringify([]),
        });
        setShowQRCode(returnVal.request_key);
      } else {
        try {
          const deposit = await lockdrop().deposit({ ...mmObject, value: parseEther('' + amount) });
          await deposit.wait();
        } catch (e) {
          console.error(e);
        }
      }
    } catch (e) {}
  };

  return {
    deposit,
    claim,
    harvest,
  };
};

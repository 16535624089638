import React from 'react';
import { VerticalDivider } from '../../../../components/Divider';

import staticStyles from './style';

interface TopCardProps {
  value: number | string;
  title: string;
}

export default function TopCard({ value, title }: TopCardProps) {
  return (
    <div className="TVL">
      <h3 style={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>{title}:</h3>
      {VerticalDivider}
      <h3 className={'TVL_Money'}>{value}</h3>

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{``}</style>
    </div>
  );
}

import { ChainId } from '@aave/contract-helpers';
import { Stake } from '@aave/protocol-js';

export interface StakeConfig {
  chainId: ChainId;
  stakeDataProvider: string;
  tokens: {
    [token: string]: {
      TOKEN_STAKING: string;
    };
  };
}

/*export const stakeConfig: StakeConfig | undefined = {
  chainId: ChainId.kovan,
  stakeDataProvider: '0x7FfEf6Fd1baEc98b4E8e016fE3D36Eb7fd3de3ac',
  tokens: {
    [Stake.bpt]: {
      TOKEN_STAKING: '0xc4cbede6c5cc7d0c775adfc76803c5888c1530f0',
      STAKING_REWARD_TOKEN: '0x73DF912Babae8e5dbb6b57779043cF5917D83B1c',
    },
  },
};*/

// kovan config
export const stakeConfig: StakeConfig | undefined = {
  chainId: ChainId.kovan,
  stakeDataProvider: '0xEDB365E2945eeE2eE73F6C1c33F2bf2f0722ecf1',
  tokens: {
    [Stake.bpt]: {
      TOKEN_STAKING: '0xa9FDa6C2046624Ea6a203a56e15aD88a5F2BBd25',
    },
  },
};

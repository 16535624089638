import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import queryString from 'query-string';
import { BigNumber, valueToBigNumber } from '@aave/protocol-js';

import { useTxBuilderContext } from '../../../../libs/tx-provider';
import { getLPTokenPoolLink } from '../../../../helpers/lp-tokens';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';
import NoDataPanel from '../../../../components/NoDataPanel';
import DefaultButton from '../../../../components/basic/DefaultButton';
import BasicForm from '../../../../components/forms/BasicForm';
import Link from '../../../../components/basic/Link';
import InfoPanel from '../../../../components/InfoPanel';
import InfoWrapper from '../../../../components/wrappers/InfoWrapper';
import AMPLWarning from '../../../../components/AMPLWarning';
import DepositCurrencyWrapper from '../../components/DepositCurrencyWrapper';
import routeParamValidationHOC, {
  ValidationWrapperComponentProps,
} from '../../../../components/RouteParamsValidationWrapper';

import messages from './messages';

import linkIcon from '../../../../images/whiteLinkIcon.svg';
import { isFeatureEnabled } from '../../../../helpers/config/markets-and-network-config';
import { getAssetInfo } from '../../../../helpers/config/assets-config';
import { CardContainer } from '../../../../components/CardContainer';
import Cheeks from '../../../../images/cheeks.svg';
import styled from 'styled-components';
import { useThemeContext } from '@aave/aave-ui-kit';
import { useServices } from './functions';
import Preloader from '../../../../components/basic/Preloader';

const CheeksContainer = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 49%;
  z-index: 5;
`;

const CheeksImage = styled.img`
  height: 40px;
  width: 40px;
  transition: 0.2s opacity;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

interface DepositAmountProps
  extends Pick<
    ValidationWrapperComponentProps,
    'currencySymbol' | 'poolReserve' | 'history' | 'walletBalance' | 'user' | 'userReserve'
  > {}

function DepositAmount({
  currencySymbol,
  poolReserve,
  user,
  userReserve,
  history,
  walletBalance,
}: DepositAmountProps) {
  const intl = useIntl();
  const { networkConfig, currentMarketData } = useProtocolDataContext();
  const { lendingPool } = useTxBuilderContext();
  const { md } = useThemeContext();
  const { mint } = useServices();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const asset = getAssetInfo(currencySymbol);

  let maxAmountToDeposit = valueToBigNumber(walletBalance);
  if (maxAmountToDeposit.gt(0) && poolReserve.symbol.toUpperCase() === networkConfig.baseAsset) {
    // keep it for tx gas cost
    maxAmountToDeposit = maxAmountToDeposit.minus('0.001');
  }
  if (maxAmountToDeposit.lte(0)) {
    maxAmountToDeposit = valueToBigNumber('0');
  }

  const handleSubmit = (amount: string) => {
    const query = queryString.stringify({ amount });
    history.push(`${history.location.pathname}/confirmation?${query}`);
  };

  const handleTransactionData = (userId: string) => async () => {
    return await lendingPool.deposit({
      user: userId,
      reserve: poolReserve.underlyingAsset,
      amount: maxAmountToDeposit.toString(10),
      referralCode: undefined,
    });
  };

  const lpPoolLink = getLPTokenPoolLink(poolReserve);
  return (
    <>
      <DepositCurrencyWrapper
        currencySymbol={currencySymbol}
        poolReserve={poolReserve}
        walletBalance={walletBalance}
        userReserve={userReserve}
        user={user}
      >
        {!maxAmountToDeposit.eq('0') && (
          <CardContainer>
            <BasicForm
              title={intl.formatMessage(messages.title, {
                currencySymbol,
              })}
              description={intl.formatMessage(messages.description)}
              amountFieldTitle={intl.formatMessage(messages.amountTitle)}
              maxAmount={maxAmountToDeposit.toFixed(4, BigNumber.ROUND_FLOOR)}
              currencySymbol={currencySymbol}
              onSubmit={handleSubmit}
              maxDecimals={poolReserve.decimals}
              getTransactionData={handleTransactionData}
              submitButtonTitle={intl.formatMessage(messages.reviewTransaction)}
            />
          </CardContainer>
        )}
        {maxAmountToDeposit.eq('0') && (!user || !lpPoolLink) && (
          <CardContainer>
            <NoDataPanel
              title={
                !user
                  ? intl.formatMessage(messages.connectWallet)
                  : intl.formatMessage(messages.noDataTitle)
              }
              description={
                !user
                  ? intl.formatMessage(messages.connectWalletDescription)
                  : intl.formatMessage(messages.noDataDescription, {
                      currencySymbol: asset.formattedName,
                    })
              }
              linkTo={
                !user
                  ? undefined
                  : isFeatureEnabled.faucet(currentMarketData)
                  ? `/faucet/${currencySymbol}`
                  : undefined
              }
              buttonTitle={
                !user
                  ? undefined
                  : isFeatureEnabled.faucet(currentMarketData)
                  ? intl.formatMessage(messages.noDataButtonTitle)
                  : undefined
              }
              withConnectButton={!user}
            />
          </CardContainer>
        )}

        {maxAmountToDeposit.eq('0') && user && lpPoolLink && (
          <CardContainer>
            <NoDataPanel
              title={intl.formatMessage(messages.noDataTitle)}
              description={intl.formatMessage(messages.noDataLPTokenDescription, {
                currencySymbol: asset.formattedName,
              })}
            >
              <Link to={lpPoolLink} absolute={true} inNewWindow={true} className="ButtonLink">
                <DefaultButton
                  className="DepositAmount__poolLink--button"
                  title={intl.formatMessage(messages.viewPool)}
                  iconComponent={<img src={linkIcon} alt="" />}
                  size="medium"
                  mobileBig={true}
                />
              </Link>
            </NoDataPanel>
          </CardContainer>
        )}

        <InfoWrapper>
          {currencySymbol === 'AMPL' && <AMPLWarning withInfoPanel={true} />}

          {currencySymbol === 'AAVE' && isFeatureEnabled.staking(currentMarketData) && (
            <InfoPanel>
              {intl.formatMessage(messages.aaveWarning, {
                link: (
                  <Link
                    className="italic"
                    to="/staking"
                    bold={true}
                    title={intl.formatMessage(messages.stakingView)}
                  />
                ),
              })}
            </InfoPanel>
          )}

          {currencySymbol === 'SNX' && !maxAmountToDeposit.eq('0') && (
            <InfoPanel>
              {intl.formatMessage(messages.warningText, {
                symbol: <strong>{currencySymbol}</strong>,
              })}
            </InfoPanel>
          )}
        </InfoWrapper>
      </DepositCurrencyWrapper>
      {/*currencySymbol === 'KLAY' &&
        !md &&
        (isLoading ? (
          <CheeksContainer>
            <Preloader smallSize />
          </CheeksContainer>
        ) : (
          <CheeksContainer>
            <CheeksImage
              onClick={async () => {
                setIsLoading(true);
                await mint((type) => {
                  setError(type);
                });
                setIsLoading(false);
              }}
              src={Cheeks}
              alt={'Cheeks icon'}
            />
            <p style={{ color: '#FF3366', fontSize: 11 }}>
              {error.length !== 0 ? `Error: ${error}` : ''}
            </p>
          </CheeksContainer>
            ))*/}
    </>
  );
}

export default routeParamValidationHOC({
  withWalletBalance: true,
})(DepositAmount);

import React, { ReactNode } from 'react';
import classNames from 'classnames';

import { useThemeContext } from '@aave/aave-ui-kit';

import staticStyles from './style';

interface ContentWrapperProps {
  className?: string;
  withFullHeight?: boolean;
  withBackButton?: boolean;
  goBack?: () => void;
  children: ReactNode;
}

export default function ContentWrapper({
  className,
  children,
  withFullHeight,
  withBackButton,
  goBack,
}: ContentWrapperProps) {
  const { currentTheme } = useThemeContext();

  return (
    <div
      className={classNames(
        'ContentWrapper',
        { ContentWrapper__fullHeight: withFullHeight },
        className
      )}
    >
      {children}

      <style jsx={true}>{staticStyles}</style>
      <style jsx={true}>{`
        .ContentWrapper {
          color: #000;
          box-shadow: none;

          &__back-button {
            color: #000 !important;
            border: 1px solid ${currentTheme.textDarkBlue.hex};
            &:hover {
              color: ${currentTheme.whiteElement.hex};
              span {
                &:after {
                  border-width: 0 1px 1px 0;
                }
              }
            }
          }
        }
      `}</style>
    </div>
  );
}

import { defineMessages } from 'react-intl';

export default defineMessages({
  markets: 'Markets',
  deposit: 'Deposit',
  borrow: 'Borrow',
  dashboard: 'Dashboard',
  manage: 'Manage KLAP',
  faucet: 'Faucet',
  ve: 'Ve',
  stake: 'Farm',
  podl: 'PODL',
  vote: 'Vote',
  lockdrop: 'Lockdrop',
  liquidityDrop: 'Liquidity Drop',
  bridge: 'Bridge',
});

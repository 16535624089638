import React from 'react';
import { useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import queryString from 'query-string';

import { useStakeDataContext } from '../../../../libs/pool-data-provider/hooks/use-stake-data-context';
import { useStaticPoolDataContext } from '../../../../libs/pool-data-provider';
import Row from '../../../../components/basic/Row';
import Value from '../../../../components/basic/Value';

import messages from './messages';
import StakeTxConfirmationView from '../../components/StakeTxConfirmationView';
import { parseEther } from 'ethers/lib/utils';
import { useProtocolDataContext } from '../../../../libs/protocol-data-provider';

export default function UnstakeConfirmation() {
  const intl = useIntl();
  const location = useLocation();
  const { userId } = useStaticPoolDataContext();
  const { selectedStakeData, stakingService } = useStakeDataContext();
  const { currentMarketData } = useProtocolDataContext();

  const query = queryString.parse(location.search);
  let amount = parseFloat(query.amount! as string);

  if (amount === 0 || !userId) {
    return null;
  }

  let blockingError = '';
  if (amount > parseFloat(selectedStakeData.stakeTokenUserBalance)) {
    blockingError = intl.formatMessage(messages.blockingError);
  }

  const handleGetTransactions = async () => {
    const txn = () =>
      stakingService.populateTransaction.withdraw(
        currentMarketData.addresses.LP_TOKEN!,
        parseEther('' + amount)
      );
    return { type: 'WITHDRAW', txn: txn };
  };

  return (
    <StakeTxConfirmationView
      caption={intl.formatMessage(messages.title)}
      description={intl.formatMessage(messages.description)}
      getTransactionsData={handleGetTransactions}
      boxTitle={intl.formatMessage(messages.unstakeAsset, { asset: 'KLAP-KLAY LP' })}
      boxDescription={intl.formatMessage(messages.boxDescription)}
      mainTxName={intl.formatMessage(messages.unstake)}
      goToAfterSuccess="/staking"
      successButtonTitle={intl.formatMessage(messages.backToStaking)}
      blockingError={blockingError}
      buttonTitle={intl.formatMessage(messages.unstake)}
    >
      <Row title={intl.formatMessage(messages.unstakeAsset, { asset: 'KLAP-KLAY LP' })}>
        <Value
          symbol={'KLAP-KLAY LP'}
          value={amount.toString()}
          tokenIcon={true}
          tooltipId={'KLAP-KLAY LP'}
        />
      </Row>
    </StakeTxConfirmationView>
  );
}

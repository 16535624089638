import { useEffect, useState } from 'react';
import { useConnectionStatusContext } from '../../libs/connection-status-provider';
import { usePolling } from '../../libs/hooks/use-polling';
import { useProtocolDataContext } from '../../libs/protocol-data-provider';
import { useMultiCall } from '../../libs/pool-data-provider/hooks/use-multicall';
import LockdropABI from '../../contracts/Lockdrop.json';
import RewardClaimerABI from '../../contracts/RewardClaimer.json';
import { useDynamicPoolDataContext, useStaticPoolDataContext } from '../../libs/pool-data-provider';
import { BigNumber } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import _ from 'lodash';
const POLLING_INTERVAL = 120000;

interface InfoData {
  lockedAssets: {
    symbol: string;
    timeContract: string;
    rewardContract: string;
    address: string;
    amount: number;
    expiry: number;
    isKlapClaimable: boolean;
    klapAvailable: boolean;
    klayAvailable: boolean;
  }[];
  klayRewards: string;
  klapRewards: string;
  expirationDate: number;
  metrics: {
    one: {
      tvl: number;
      cap: number;
    };
    three: {
      tvl: number;
      cap: number;
    };
    six: {
      tvl: number;
      cap: number;
    };
    twelve: {
      tvl: number;
      cap: number;
    };
  };
}

interface ReturnData {
  loading: boolean;
  error: any;
  data: InfoData;
  refresh: () => void;
}

export const useGetLockdropInfo: () => ReturnData = () => {
  let { userId } = useStaticPoolDataContext();
  const { reserves } = useDynamicPoolDataContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<InfoData>({
    lockedAssets: [],
    klayRewards: '0',
    klapRewards: '0',
    expirationDate: 0,
    metrics: {
      one: {
        tvl: 0,
        cap: 0,
      },
      three: {
        tvl: 0,
        cap: 0,
      },
      six: {
        tvl: 0,
        cap: 0,
      },
      twelve: {
        tvl: 0,
        cap: 0,
      },
    },
  });
  const { currentMarketData } = useProtocolDataContext();
  const { isRPCActive } = useConnectionStatusContext();
  const multicall = useMultiCall([
    LockdropABI,
    LockdropABI,
    LockdropABI,
    LockdropABI,
    RewardClaimerABI,
    RewardClaimerABI,
    RewardClaimerABI,
    RewardClaimerABI,
  ]);

  const fetchData = async () => {
    if (userId === undefined || userId === null || userId === '') return;
    try {
      setLoading(true);
      console.log('reserves', reserves);
      const accountBalancesMethods = _.map(reserves, () => 'accountBalances');
      const accountBalancesArgs = _.map(reserves, (reserve) => [userId, reserve.aTokenAddress]);

      const [
        balancesOne,
        balancesThree,
        balancesSix,
        balancesTwelve,
        rewardClaimerOne,
        rewardClaimerThree,
        rewardClaimerSix,
        rewardClaimerTwelve,
      ] = await multicall(
        [
          currentMarketData.addresses.LOCKDROP_CONTRACTS!.ONE_MONTH,
          currentMarketData.addresses.LOCKDROP_CONTRACTS!.THREE_MONTH,
          currentMarketData.addresses.LOCKDROP_CONTRACTS!.SIX_MONTH,
          currentMarketData.addresses.LOCKDROP_CONTRACTS!.TWELVE_MONTH,
          currentMarketData.addresses.REWARD_CLAIMER_CONTRACTS!.ONE_MONTH,
          currentMarketData.addresses.REWARD_CLAIMER_CONTRACTS!.THREE_MONTH,
          currentMarketData.addresses.REWARD_CLAIMER_CONTRACTS!.SIX_MONTH,
          currentMarketData.addresses.REWARD_CLAIMER_CONTRACTS!.TWELVE_MONTH,
        ],
        [
          ..._.map(_.range(0, 4), () => [
            'usdDeposited',
            'unlockTime',
            'cap',
            ...accountBalancesMethods,
          ]),
          ..._.map(_.range(0, 4), () => ['klapClaimable', 'klapToClaim', 'klayToClaim']),
        ],
        [
          ..._.map(_.range(0, 4), () => [[], [], [], ...accountBalancesArgs]),
          ..._.map(_.range(0, 4), () => [[], [userId], [userId]]),
        ]
      );
      const usdDepositedOne = formatEther(BigNumber.from(balancesOne[0][0]).div(1000000));
      const usdDepositedThree = formatEther(BigNumber.from(balancesThree[0][0]).div(1000000));
      const usdDepositedSix = formatEther(BigNumber.from(balancesSix[0][0]).div(1000000));
      const usdDepositedTwelve = formatEther(BigNumber.from(balancesTwelve[0][0]).div(1000000));

      const capOne = formatEther(BigNumber.from(balancesOne[2][0]).div(1000000));
      const capThree = formatEther(BigNumber.from(balancesThree[2][0]).div(1000000));
      const capSix = formatEther(BigNumber.from(balancesSix[2][0]).div(1000000));
      const capTwelve = formatEther(BigNumber.from(balancesTwelve[2][0]).div(1000000));

      const lockedOne = _.map(_.range(3, balancesOne.length), (i) => ({
        expiry: BigNumber.from(balancesOne[1][0]).toNumber(),
        amount:
          BigNumber.from(balancesOne[i][0])
            .div(BigNumber.from(10).pow(reserves[i - 3].decimals - 5))
            .toNumber() / 100000,
        address: reserves[i - 3].aTokenAddress,
        symbol: reserves[i - 3].symbol,
        timeContract: currentMarketData.addresses.LOCKDROP_CONTRACTS!.ONE_MONTH,
        rewardContract: currentMarketData.addresses.REWARD_CLAIMER_CONTRACTS!.ONE_MONTH,
        isKlapClaimable: rewardClaimerOne[0][0],
        klapAvailable: Number(formatEther(rewardClaimerOne[1][0])) > 0,
        klayAvailable: Number(formatEther(rewardClaimerOne[2][0])) > 0,
      }));
      const lockedThree = _.map(_.range(3, balancesThree.length), (i) => ({
        expiry: BigNumber.from(balancesThree[1][0]).toNumber(),
        amount:
          BigNumber.from(balancesThree[i][0])
            .div(BigNumber.from(10).pow(reserves[i - 3].decimals - 5))
            .toNumber() / 100000,
        address: reserves[i - 3].aTokenAddress,
        symbol: reserves[i - 3].symbol,
        timeContract: currentMarketData.addresses.LOCKDROP_CONTRACTS!.THREE_MONTH,
        rewardContract: currentMarketData.addresses.REWARD_CLAIMER_CONTRACTS!.THREE_MONTH,
        isKlapClaimable: rewardClaimerThree[0][0],
        klapAvailable: Number(formatEther(rewardClaimerThree[1][0])) > 0,
        klayAvailable: Number(formatEther(rewardClaimerThree[2][0])) > 0,
      }));
      const lockedSix = _.map(_.range(3, balancesSix.length), (i) => ({
        expiry: BigNumber.from(balancesSix[1][0]).toNumber(),
        amount:
          BigNumber.from(balancesSix[i][0])
            .div(BigNumber.from(10).pow(reserves[i - 3].decimals - 5))
            .toNumber() / 100000,
        address: reserves[i - 3].aTokenAddress,
        symbol: reserves[i - 3].symbol,
        timeContract: currentMarketData.addresses.LOCKDROP_CONTRACTS!.SIX_MONTH,
        rewardContract: currentMarketData.addresses.REWARD_CLAIMER_CONTRACTS!.SIX_MONTH,
        isKlapClaimable: rewardClaimerSix[0][0],
        klapAvailable: Number(formatEther(rewardClaimerSix[1][0])) > 0,
        klayAvailable: Number(formatEther(rewardClaimerSix[2][0])) > 0,
      }));
      const lockedTwelve = _.map(_.range(3, balancesTwelve.length), (i) => ({
        expiry: BigNumber.from(balancesTwelve[1][0]).toNumber(),
        amount:
          BigNumber.from(balancesTwelve[i][0])
            .div(BigNumber.from(10).pow(reserves[i - 3].decimals - 5))
            .toNumber() / 100000,
        address: reserves[i - 3].aTokenAddress,
        symbol: reserves[i - 3].symbol,
        timeContract: currentMarketData.addresses.LOCKDROP_CONTRACTS!.TWELVE_MONTH,
        rewardContract: currentMarketData.addresses.REWARD_CLAIMER_CONTRACTS!.TWELVE_MONTH,
        isKlapClaimable: rewardClaimerTwelve[0][0],
        klapAvailable: Number(formatEther(rewardClaimerTwelve[1][0])) > 0,
        klayAvailable: Number(formatEther(rewardClaimerTwelve[2][0])) > 0,
      }));

      console.log('amount', Number(formatEther(rewardClaimerOne[1][0])));
      if (Number(formatEther(rewardClaimerOne[1][0])) > 0 && lockedOne.length === 0) {
        lockedOne.push({
          expiry: BigNumber.from(balancesOne[1][0]).toNumber(),
          amount: 0.0000001,
          address: '',
          symbol: '',
          timeContract: currentMarketData.addresses.LOCKDROP_CONTRACTS!.ONE_MONTH,
          rewardContract: currentMarketData.addresses.REWARD_CLAIMER_CONTRACTS!.ONE_MONTH,
          isKlapClaimable: rewardClaimerOne[0][0],
          klapAvailable: Number(formatEther(rewardClaimerOne[1][0])) > 0,
          klayAvailable: Number(formatEther(rewardClaimerOne[2][0])) > 0,
        });
      }
      const finalList = _.filter(
        [...lockedOne, ...lockedThree, ...lockedSix, ...lockedTwelve],
        (el) => el.amount !== 0
      );
      const data: InfoData = {
        metrics: {
          one: {
            tvl: Number(usdDepositedOne),
            cap: Number(capOne),
          },
          three: {
            tvl: Number(usdDepositedThree),
            cap: Number(capThree),
          },
          six: {
            tvl: Number(usdDepositedSix),
            cap: Number(capSix),
          },
          twelve: {
            tvl: Number(usdDepositedTwelve),
            cap: Number(capTwelve),
          },
        },
        // Constant
        expirationDate: 1656547200,
        lockedAssets: finalList,
        klapRewards: '30M',
        klayRewards: '$1.5M',
      };
      console.log('data', data);
      setData(data);
      setError(false);
    } catch (e) {
      console.error('e', e);
      setError(e.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (reserves.length !== 0 && data.metrics.one.tvl === 0) {
      fetchData();
    }
  }, [reserves]);

  usePolling(fetchData, POLLING_INTERVAL, !isRPCActive, []);

  return {
    loading,
    error,
    data: data,
    refresh: fetchData,
  };
};

import React, { ReactNode, useContext, useState } from 'react';
import { normalize, Stake } from '@aave/protocol-js';

import Preloader from '../../../components/basic/Preloader';
import ErrorPage from '../../../components/ErrorPage';
import { useProtocolDataContext } from '../../protocol-data-provider';
import { useStaticPoolDataContext } from '../providers/static-pool-data-provider';
import { ComputedStakeData, ComputedStakesData } from '../types/stake';
import {
  useMainnetCachedServerWsGraphCheck,
  useNetworkCachedServerWsGraphCheck,
  useQueryGraphCheck,
} from './use-graph-check';
import { useStakeDataWithRpc } from './use-stake-data-with-rpc';
import {
  ConnectionMode,
  useConnectionStatusContext,
  WS_ATTEMPTS_LIMIT,
} from '../../connection-status-provider';
import { StakeConfig } from '../../../ui-config';
import { getProvider } from '../../../helpers/config/markets-and-network-config';
import { ChainId } from '@aave/contract-helpers';
import { Contract, providers } from 'ethers';
import MasterChefABI from '../../../contracts/Masterchef.json';
import { useWeb3React } from '@web3-react/core';
import NoDataPanel from '../../../components/NoDataPanel';
import defaultMessages from '../../../defaultMessages';
import { useIntl } from 'react-intl';

const StakeDataContext = React.createContext<{
  stakeConfig: StakeConfig;
  selectedStake: Stake;
  selectedStakeData: ComputedStakeData;
  STAKING_REWARD_TOKEN: string;
  data: ComputedStakesData;
  usdPriceEth: string;
  refresh: () => void;
  stakingService: Contract;
  cooldownStep: number;
  setCooldownStep: (value: number) => void;
}>({
  stakeConfig: {} as StakeConfig,
  selectedStake: Stake.bpt,
  STAKING_REWARD_TOKEN: '',
  selectedStakeData: {} as ComputedStakeData,
  data: {} as ComputedStakesData,
  usdPriceEth: '0',
  refresh: () => {},
  stakingService: {} as Contract,
  cooldownStep: 0,
  setCooldownStep: () => {},
});

export function StakeDataProvider({
  children,
  stakeConfig,
}: {
  stakeConfig: StakeConfig;
  children: ReactNode;
}) {
  const intl = useIntl();
  const { userId } = useStaticPoolDataContext();
  console.log('user address', userId);
  const { library: provider } = useWeb3React<providers.Web3Provider>();
  const [cooldownStep, setCooldownStep] = useState(0);
  const { preferredConnectionMode } = useConnectionStatusContext();
  const { chainId, networkConfig, currentMarketData } = useProtocolDataContext();
  const isStakeFork =
    networkConfig.isFork && networkConfig.underlyingChainId === stakeConfig.chainId;
  const RPC_ONLY_MODE =
    networkConfig.rpcOnly || preferredConnectionMode === ConnectionMode.rpc || isStakeFork;

  const selectedStake = Stake.bpt;
  const topHolderSigner = provider ? provider!.getSigner(userId) : getProvider(chainId);
  const stakingService = new Contract(
    currentMarketData.addresses.MASTERCHEF!,
    MasterChefABI,
    topHolderSigner
  );

  const wsNetworkError = useNetworkCachedServerWsGraphCheck();
  const wsMainnetError = useMainnetCachedServerWsGraphCheck();
  const queryError = useQueryGraphCheck();

  const isRPCMandatory =
    RPC_ONLY_MODE ||
    (wsNetworkError.wsErrorCount >= WS_ATTEMPTS_LIMIT && chainId === ChainId.mainnet) ||
    (wsMainnetError.wsErrorCount >= WS_ATTEMPTS_LIMIT && chainId !== ChainId.mainnet) ||
    networkConfig.isFork ||
    queryError.queryErrorCount >= 1;
  const isRPCActive = preferredConnectionMode === ConnectionMode.rpc || isRPCMandatory;

  const {
    loading: rpcDataLoading,
    data: rpcData,
    usdPriceEth: usdPriceEthRpc,
    refresh,
  } = useStakeDataWithRpc(stakeConfig.stakeDataProvider, chainId, userId, !isRPCActive);

  if (!userId) {
    return (
      <NoDataPanel
        title={intl.formatMessage(defaultMessages.connectWallet)}
        description={intl.formatMessage(defaultMessages.pleaseConnectWallet)}
        withConnectButton={true}
      />
    );
  }

  if ((isRPCActive && rpcDataLoading) || !isRPCActive) {
    return <Preloader withText={true} />;
  }

  const rawData = rpcData;
  if (!rawData) {
    return <ErrorPage />;
  }

  const computedData = {
    [Stake.bpt]: rawData[Stake.bpt],
  };
  const usdPriceEth = normalize((isRPCActive && usdPriceEthRpc) || '0', 18);

  return (
    <StakeDataContext.Provider
      value={{
        stakeConfig,
        selectedStake,
        STAKING_REWARD_TOKEN: currentMarketData.addresses.MAIN_TOKEN!,
        stakingService,
        selectedStakeData: computedData[selectedStake],
        usdPriceEth,
        data: computedData,
        refresh: isRPCActive ? refresh : async () => {},
        cooldownStep,
        setCooldownStep,
      }}
    >
      {children}
    </StakeDataContext.Provider>
  );
}

export const useStakeDataContext = () => useContext(StakeDataContext);
